import React, { useContext, useEffect, useState } from 'react';
import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../../context/UserProvider";

function PreciosPlanes() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    let imagealt = 'image';
    let data = sectiondata.pricing;
    const { user } = useContext(UserContext);
    const [descuento, setDescuento] = useState('');

    useEffect(() => {
        const fetchCliente = async () => {
            if (user) {
                try {
                    const response = await fetch(`/api/clientes/getResponsableCliente/${user.usuario.correo}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (response.ok) {
                        const responseData = await response.json();
                        if (responseData.length > 0) {
                            const descuentoresponse = responseData[0].descuento;
                            setDescuento(descuentoresponse);
                        }
                    } else {
                        console.error('Failed to fetch data:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchCliente();
    }, [user]);

    const calculateDiscountedPrice = (price) => {
        const priceNumber = parseFloat(price.replace(',', '.').replace(/[^0-9.-]+/g, "")); // Convert price string to a number
        const discountedPrice = priceNumber * (1 - descuento / 100); // Apply discount
        return discountedPrice.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Format to 2 decimal places with comma
        
    };

    return (
        <div>
            <div className="user-list-area pd-top-90 pd-bottom-70">
                <div className="container">
                    <img src={'/assets/img/banner/Buscador-propiedades-landings_Banner-Corredor-buscador-desktop.png'} alt={imagealt} />
                    <div className="section-title text-center">
                        <h2 className="title">{data.tile}</h2>
                    </div>
                    <div className="row">
                        {data.items.map((item, i) => (
                            <div key={i} className="col-lg-3 col-sm-6">
                                <div className="single-pricing text-center" style={{ backgroundColor: 'var(--main-color-four)' }}>
                                    <div className="details">
                                        <h4 className="title" style={{ color: 'white' }}>{item.name}</h4>
                                        {descuento ? (
                                            <>
                                                <h3 className="price" style={{ fontWeight: 'bold', marginBottom: '1px' }}>
                                                    {calculateDiscountedPrice(item.price)} {/* Display the discounted price */}
                                                </h3>
                                                <h6 style={{ fontWeight: 'bold', color: 'var(--main-color-one)', marginTop: '1px' }}>(Promo 40% Desc)</h6>
                                            </>
                                        ) : (
                                            <h3 className="price" style={{ fontWeight: 'bold', marginBottom: '1px' }}>{item.price}</h3>
                                        )}
                                        <h5 style={{ fontWeight: 'bold', color: 'var(--main-color-one)', marginTop: '1px' }}>{item.time}</h5>
                                        <h6 className="title" style={{ color: 'white' }}>(IVA INCLUIDO)</h6>
                                        <br />
                                        <div className="separator-line" style={{ width: '50px', borderBottom: '1px solid white', margin: '0 auto 10px' }} />
                                        <br />
                                        <ul style={{ color: 'white', textAlign: 'left' }}>
                                            {item.feature.map((featuresitem, j) => (
                                                <li key={j} style={{ color: featuresitem.includes('publicaciones') ? 'orange' : 'white', marginLeft: featuresitem.includes('publicaciones') ? '25px' : '0px' }}>
                                                    {featuresitem.includes('publicaciones') ? (
                                                        featuresitem
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faCheck} style={{ marginRight: '5px', fontSize: '25px', color: 'var(--main-color-one)' }} />
                                                            {featuresitem}
                                                        </>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                        {user ? (
                                            <Link className="btn btn-yellow" to={`/pagoplan?price=${encodeURIComponent(calculateDiscountedPrice(item.price))}&name=${encodeURIComponent(item.name)}`}>
                                                {item.btntxt}
                                            </Link>
                                        ) : (
                                                <Link className="btn btn-yellow" to={`/registro?price=${encodeURIComponent(calculateDiscountedPrice(item.price))}&name=${encodeURIComponent(item.name)}`}>
                                                {item.btntxt}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {!user && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h3 style={{ textAlign: 'center' }}>¡Regístrate como usuario y contrata tu plan aquí!</h3>
                    <Link className="btn btn-yellow" to={`/registro`} style={{ display: 'inline-block' }}>
                        Registrar
                    </Link>
                </div>
            )}
            {user && (
                <h3 style={{ textAlign: 'center' }}>¡Contrata tu plan aquí!</h3>
            )}
            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                <a
                    className="btn btn-yellow"
                    href="https://apps.clientify.net/formbuilderembed/simpleembed/#/forms/embedform/181761/55031"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', display: 'inline-block', padding: '10px 20px', borderRadius: '5px', color: 'white' }}
                >
                    ¿Quieres más información?
                </a>
            </div>
        </div>
    );
}

export default PreciosPlanes;
