import React, { useState } from 'react';
import { Widget, addResponseMessage, renderCustomComponent } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

const Chatbot = () => {
    const [chatbotState, setChatbotState] = useState({
        title: 'Ayuda',
        subtitle: '¡Hola! Puedo ayudarte.',
        showCloseButton: true,
        fullScreenMode: false,
        step: 0 // To track the current step in the conversation
    });

    const handleNewUserMessage = (newMessage) => {
        // Handle the user's input depending on the current step
        if (chatbotState.step === 0) {
            renderOptions(['¿Sobre Soporte?', '¿Sobre planes y clientes?', '¿Sobre propiedades?']);
            setChatbotState({ ...chatbotState, step: 1 });
        }
    };

    const handleOptionClick = (option) => {
        switch (option) {
            case '¿Sobre Soporte?':
                renderOptions(['Contactar por correo electrónico', 'Contactar a través del formulario']);
                setChatbotState({ ...chatbotState, step: 2 });
                break;
            case '¿Sobre propiedades?':
                renderOptions(['¿Cómo puedo publicar una propiedad?', '¿Como buscar las propiedades?']);
                setChatbotState({ ...chatbotState, step: 2 });
                break;
            case '¿Sobre planes y clientes?':
                renderOptions(['¿En que consisten los planes?', '¿Como puedo contratar un plan?', '¿Al inicio será gratis?']);
                setChatbotState({ ...chatbotState, step: 2 });
                break;
            case '¿En que consisten los planes?':
                addResponseMessage('Los planes son un servicio a pago donde al ser cliente tienes ventajas como pertenecer al listado de corredoras o inmobiliarias, publicar con tu logo y promocionar tus publicaciones ');
                break;
            case '¿Como puedo contratar un plan?':
                addResponseMessage('Para contratar un plan debes ir al link Empresa de la pagina principal y despues a la opcion planes de precios a clientes');
                break;
            case '¿Al inicio será gratis?':
                addResponseMessage('Si, en los primeros 6 meses del lanzamiento el servicio será gratuito y todos los que se hagan cliente tendran el plan Estandar sin costo, despues de los 6 meses podran decidir si abandonar el plan o cambiarse a otro plan');
                break;
            case '¿Cómo puedo publicar una propiedad?':
                addResponseMessage('Para publicar una propiedad primero necesitas estar creado como usuario y posteriormente a esto se habilita un botón en la parte superior derecha de la pantalla con la opción publicar.');
                break;
            case '¿Como buscar las propiedades?':
                addResponseMessage('Para buscar propiedades debes ingresar la comuna o ciudad en el banner de la pagina principal y despues seras dirigido a la pagina de busqueda de propiedades donde puedes agregar mas filtros, buscar en el mapa, comparar propiedades entre otras');
                break;
            case 'Contactar por correo electrónico':
                addResponseMessage('Puedes contactar a nuestro equipo de soporte enviando un correo electrónico a contacto@omnimiza.cl');
                break;
            case 'Contactar a través del formulario':
                addResponseMessage('Puedes contactarnos a través del formulario de contacto en nuestro sitio web.');
                break;
            default:
                addResponseMessage('Por favor, selecciona una de las opciones disponibles o escribe tu pregunta.');
                break;
        }
    };

    // Function to render predefined options as buttons
    const renderOptions = (options) => {
        const buttons = options.map((option, index) => (
            <button className="chat-button" key={index} onClick={() => handleOptionClick(option)}>{option}</button>

        ));
        renderCustomComponent(() => <div>{buttons}</div>, false);
    };

    return (
        <div>
            <Widget
                {...chatbotState}
                handleNewUserMessage={handleNewUserMessage}
                senderPlaceHolder="Escribe un mensaje..."
            />
        </div>
    );
};

export default Chatbot;
