import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FormatoNumero } from '../global-components/globalfunctions';

function PropiedadesRecomendadasUsuario({ idUsuario }) {
    const [properties, setProperties] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [propertiesPerPage] = useState(10);

        const fetchCercanosData = async (property) => {
        try {
            const query = `[out:json]; 
            (
                node(around:1000,${property.latitud},${property.longitud})["highway"="bus_stop"];
                node(around:1000,${property.latitud},${property.longitud})["railway"="station"];
                node(around:1000,${property.latitud},${property.longitud})["amenity"="school"];
            );
            out;`;

            const response = await axios.post('https://overpass-api.de/api/interpreter', query);

            if (response.data && response.data.elements) {
                const elements = response.data.elements;

                // Check for specific tags
                const hasSchool = elements.some(elem => elem.tags && elem.tags.amenity === 'school');
                const hasBusStop = elements.some(elem => elem.tags && elem.tags.highway === 'bus_stop');
                const hasTrainStation = elements.some(elem => elem.tags && elem.tags.railway === 'station');

                // Build the resulting text based on the logic
                let resultText = '';
                if (hasSchool) {
                    resultText += 'Cercano a Colegios';
                    if (hasBusStop) {
                        resultText += ', Paradas de Buses';
                        if (hasTrainStation) {
                            resultText += ', Metro';
                        }
                    } else if (hasTrainStation) {
                        resultText += ', Metro';
                    }
                } else if (hasBusStop) {
                    resultText += 'Cercano a Paradas de Buses';
                    if (hasTrainStation) {
                        resultText += ', Metro';
                    }
                } else if (hasTrainStation) {
                    resultText += 'Cercano a Metro';
                }

                return resultText;
            } else {
                throw new Error('No se encontraron datos de transporte.');
            }
        } catch (error) {
            console.error('Error al obtener datos de transporte: ', error);
            throw new Error('Ocurrió un error al obtener datos de transporte.');
        }
    };
    function SubTitulo(item) {
        let subtitulo = '';
        if (item.property.tipopropiedad === 2) subtitulo = subtitulo + 'Casa en ';
        if (item.property.tipopropiedad === 3) subtitulo = subtitulo + 'Departamento en ';
        if (item.property.tipopropiedad === 4) subtitulo = subtitulo + 'Galpon en ';
        if (item.property.tipopropiedad === 5) subtitulo = subtitulo + 'Local Comercial en ';
        if (item.property.tipopropiedad === 6) subtitulo = subtitulo + 'Terreno en ';
        if (item.property.tipopropiedad === 7) subtitulo = subtitulo + 'Oficina en ';
        if (item.property.tipopropiedad === 8) subtitulo = subtitulo + 'Parcela en ';
        if (item.property.tipopropiedad === 9) subtitulo = subtitulo + 'Bodega en ';
        if (item.property.tipopropiedad === 10) subtitulo = subtitulo + 'Derecho Llave en ';
        if (item.property.tipopropiedad === 11) subtitulo = subtitulo + 'Estacionamiento en ';
        if (item.property.tipopublicacion === 1) subtitulo = subtitulo + 'Venta';
        if (item.property.tipopublicacion === 2) subtitulo = subtitulo + 'Arriendo';
        return subtitulo;
    }

    const fetchProperties = async () => {
        try {
            const searchResponse = await axios.get(`/api/usuario/ObtieneHistorialRecomendaciones/${idUsuario}`);

            const searches = searchResponse.data.slice(0, 3);

            const propertyPromises = searches.map(async (search) => {
                const {
                    similarity,
                    fecha,
                    direccion,
                    baños,
                    estacionamientos,
                    habitaciones,
                    tipopropiedad,
                    tipopublicacion,
                    idcliente,
                    idusuario,
                } = search;

                const searchWithoutsimilarity = {
                    Busqueda: direccion,
                    baños: baños,
                    estacionamientos: estacionamientos,
                    habitaciones: habitaciones,
                    comuna: '',
                    idusuario: null,
                    moneda: 'TODAS',
                    Relacionada: false,
                    tipopropiedad: tipopropiedad.toString(),
                    tipopublicacion: tipopublicacion.toString(),
                    idcliente: idcliente,
                };

                const propertyResponse = await axios.post('/api/propiedades/ConsultaPropsEx?startIndex=0&endIndex=10&sort=1', searchWithoutsimilarity);
                const { properties } = propertyResponse.data;

                // Fetch additional data for each property
                const propertiesWithCercanosData = await Promise.all(
                    properties.map(async (property) => {
                        try {
                            const cercanosData = await fetchCercanosData(property.property);
                            return { ...property, cercanosData };
                        } catch (error) {
                            console.error('Error fetching cercanos data:', error);
                            return property;
                        }
                    })
                );

                return propertiesWithCercanosData;
            });

            const propertyArrays = await Promise.all(propertyPromises);
            const mergedProperties = propertyArrays.flat();

            const uniqueProperties = Array.from(new Set(mergedProperties.map(property => property.property.idpropiedad)))
                .map(idpropiedad => {
                    return mergedProperties.find(property => property.property.idpropiedad === idpropiedad);
                });

            uniqueProperties.sort((a, b) => b.similarity - a.similarity);

            setProperties(uniqueProperties);
        } catch (error) {
            console.log(error);
        }
    };



    useEffect(() => {
        fetchProperties();
    }, [idUsuario]);

    const toggleFavorite = async (property) => {
        try {
            const heartIcon = document.getElementById(`heartIcon-${property.property.idpropiedad}`);

            if (heartIcon) {
                const isSelected = heartIcon.classList.contains('selected');

                if (!isSelected) {
                    const url = `/api/propiedades/marcafavorito/${property.property.idpropiedad}/${idUsuario}/${1}`;
                    const requestBody = null;
                    const headers = {
                        'Content-Type': 'application/json',
                    };

                    const response = await axios.post(url, requestBody, { headers });
                    console.log('Response:', response);
                    heartIcon.classList.add('selected');
                    heartIcon.style.color = 'red';
                } else {
                    const url = `/api/propiedades/marcafavorito/${property.property.idpropiedad}/${idUsuario}/${0}`;
                    const requestBody = null;
                    const headers = {
                        'Content-Type': 'application/json',
                    };

                    const response = await axios.post(url, requestBody, { headers });
                    heartIcon.classList.remove('selected');
                    heartIcon.style.color = ''; // Reset to the default color or define your desired color here
                }
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    


    const renderHeartIcon = (property) => {
        if (property.isFavorite) {
            return (
                <i
                    id={`heartIcon-${property.property.idpropiedad}`}
                    className="fa fa-heart selected"
                    style={{ color: 'red' }}
                    onClick={() => toggleFavorite(property)}
                />
            );
        } else {
            return (
                <i
                    id={`heartIcon-${property.property.idpropiedad}`}
                    className="fa fa-heart"
                    onClick={() => toggleFavorite(property)}
                />
            );
        }
    };




    const indexOfLastProperty = currentPage * propertiesPerPage;
    const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
    const currentProperties = properties.slice(indexOfFirstProperty, indexOfLastProperty);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(properties.length / propertiesPerPage); i++) {
        pageNumbers.push(i);
    }



    return (
        properties.length > 0 && (
            <div className="recommended-area pd-top-90 pd-bottom-70">
                <div className="container">
                    <div className="text-center">
                        <br />
                        <br />
                        <h4 className="" style={{ color: 'var(--main-color-one)' }}>Recomendaciones Basadas en tus Búsquedas</h4>
                    </div>
                    <div className="row">
                        {currentProperties.map((property, i) => (
                            <div key={i} className="col-lg-3 col-sm-6">
                                <div className="single-feature">
                                    <Link to={`/detallepropiedad/${property.property.idpropiedad}`}>
                                    <div className="thumb">
                                        <div className="thumb-image" style={{ position: 'relative' }}>
                                            {property.property.nivelpromo && (
                                                <div className="promo-icon" style={{ position: 'absolute', top: 15, left: 15 }}>
                                                    <img src={'/assets/img/icons/promo-icon-9.png'} alt="Promo" style={{ width: '40px', height: '40px' }} />
                                                </div>
                                            )}
                                            <img
                                                src={`data:image/jpeg;base64,${property.base64Data}`}
                                                alt="img"
                                                style={{ width: '100%', height: `200px` }}
                                            />
                                        </div>
                                        </div>
                                    </Link>
                                    <div className="details">
                                        <div className="details-wrap">
                                            <div className="feature-logo">
                                                {property.icono ? (
                                                    <img src={`data:image/jpeg;base64,${property.icono}`} alt="logo" className="rounded-circle thumbnail-icon"
                                                        style={{ maxWidth: '100%', height: 'auto' }} />
                                                ) : (
                                                    <img className="img-profile rounded-circle" src={'/assets/img/Foto003.jpg'} alt="logo" />
                                                )}
                                            </div>
                                            <div className="favorite-icon" style={{ textAlign: 'left' }}>
                                                {renderHeartIcon(property)}
                                            </div>
                                            <p className="author">
                                                <i className="fa fa-user"  /> {property.nombre}
                                            </p>
                                            <h6 className="readeal-top">{SubTitulo(property)}</h6>
                                            <h6 className="title readeal-top">
                                                <a>
                                                    {(property.property.titulo + ' - ' + property.property.comuna).padEnd(160, ' ')}
                                                </a>
                                            </h6>
                                            <div className="price">
                                            <label  style={{ fontSize: '14px' }}>
                                                <span >{FormatoNumero(property.property.precioactual)}</span>
                                                {' '}
                                                <span className="moneda" >{property.property.moneda}</span>
                                                </label>
                                                <br></br>
                                                <del className="precioanterior">{FormatoNumero(property.property.precioAnterior)}</del>
                                            </div>
                                            <div className="info-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>
                                                    {property.property.numhabitaciones > 0 && (
                                                        <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                                            <i className="fa fa-bed" style={{ marginRight: '5px', color: 'var(--main-color-three)' }} />
                                                            <span>{property.property.numhabitaciones} Habs</span>
                                                        </div>
                                                    )}
                                                    {property.property.numestacionamientos > 0 && (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <i className="fa fa-car" style={{ marginRight: '5px', color: 'var(--main-color-three)' }} />
                                                            <span>{property.property.numestacionamientos} Estac.</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div style={{ textAlign: 'right' }}>
                                                    {property.property.numbaños > 0 && (
                                                        <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                                            <i className="fa fa-bath" style={{ marginRight: '5px', color: 'var(--main-color-three)' }} />
                                                            <span>{property.property.numbaños} baños</span>
                                                        </div>
                                                    )}
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={'/assets/img/icons/area.png'} alt="area" style={{ marginRight: '10px', filter: 'var(--main-color-three)' }} />
                                                        <span>{property.property.area} Mt2</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: '150px', borderBottom: '2px solid ', margin: '0 auto 10px', marginTop: '20px' }} />
                                            <div className="info-container" style={{ alignItems: 'center', fontWeight: 'bold' }}>
                                                {property.cercanosData}
                                            </div>
                                            <ul className="contact-list">
                                                <li className="readeal-top">
                                                    <Link className="btn btn-yellow" to={`/detallepropiedad/${property.property.idpropiedad}`}>
                                                        Ver Detalles
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <nav>
                        <ul className="pagination">
                            {pageNumbers.map((number) => (
                                <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                                    <button className="page-link" style={{ backgroundColor: 'var(--main-color-one)' }} onClick={() => paginate(number)}>
                                        {number}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        )
    );
}

export default PropiedadesRecomendadasUsuario;
