import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import blogdata from '../../data/blogdata.json';
import { Transition } from 'react-transition-group';

function Noticias() {
    const { newsId } = useParams();
    const navigate = useNavigate();
    const publicUrl = process.env.PUBLIC_URL + '/';
    const imagealt = 'image';
    const data = blogdata.blogs;
    const [expanded, setExpanded] = useState({});

    const toggleExpanded = (id) => {
        setExpanded(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };

    useEffect(() => {
        // Obtener todas las palabras clave
        const allKeywords = data.flatMap(item => item.keywords.split(', '));

        // Eliminar duplicados
        const uniqueKeywords = Array.from(new Set(allKeywords));

        // Crear la cadena de palabras clave separadas por comas
        const keywordsString = uniqueKeywords.join(', ');

        // Agregar las etiquetas meta con las palabras clave al head del documento
        const metaKeywordsTag = document.createElement('meta');
        metaKeywordsTag.name = 'keywords';
        metaKeywordsTag.content = keywordsString;
        document.head.appendChild(metaKeywordsTag);

        // Limpiar las etiquetas meta cuando el componente se desmonte
        return () => {
            if (metaKeywordsTag) {
                document.head.removeChild(metaKeywordsTag);
            }
        };
    }, [data]);

    if (newsId) {
        const news = data.find(item => item.idnoticia === parseInt(newsId));
        if (!news) {
            return <div>Noticia no encontrada</div>;
        }

        return (
            <div className="single-news-container">
                <div className="single-news" style={{ margin: '0 auto', maxWidth: '800px', padding: '20px' }}>
                    <div className="thumb">
                        <img src={publicUrl + news.image} alt={imagealt} style={{ width: '100%', height: 'auto' }} />
                    </div>
                    <div className="details">
                        <h1>{news.title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: news.content }} />
                        <div className="author">
                            <img src={publicUrl + news.authorimage} alt={imagealt} style={{ width: '100px', height: '50px' }} />
                            <span>Por {news.author}</span>
                            <span className="date">{news.date}</span>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <button className="btn btn-yellow" onClick={() => navigate('/noticias')}>Volver a todas las noticias</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="property-news-area pd-top-100 pd-bottom-70">
            <div className="container">
                <div className="row">
                    {data.map((item, i) => (
                        <div key={i} className="col-lg-6">
                            <div className="single-news">
                                <div className="thumb">
                                    <img src={publicUrl + item.image} alt={imagealt} style={{ width: '633px', height: '332px' }} />
                                </div>
                                <div className="details">
                                    <h3 onClick={() => toggleExpanded(i)}>
                                        {item.title} {expanded[i] ? <FaChevronUp style={{ color: 'var(--main-color-one)' }} /> : <FaChevronDown style={{ color: 'var(--main-color-one)' }} />}
                                    </h3>
                                    <Transition in={expanded[i]} timeout={300} unmountOnExit>
                                        {state => (
                                            <div style={{
                                                height: state === 'entered' ? 'auto' : 0,
                                                overflow: 'hidden',
                                                transition: 'height 0.3s ease',
                                                paddingTop: state === 'entered' ? '10px' : 0, // optional, for better UX
                                            }}>
                                                <p dangerouslySetInnerHTML={{ __html: item.content }} />
                                            </div>
                                        )}
                                    </Transition>
                                    {!expanded[i] && <p>{item.resumen}</p>}
                                    <div className="author">
                                        <img src={publicUrl + item.authorimage} alt={imagealt} style={{ width: '100px', height: '50px' }} />
                                        <span>Por {item.author}</span>
                                        <span className="date">{item.date}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Noticias;
