import axios from 'axios';
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import Switch from 'react-switch';
import ImagesUpload from '../../components/global-components/imagesupload';
import ImageGallery from '../../components/global-components/imagesgallery';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { UserContext } from "../../context/UserProvider";
import { PropiedadContext } from "../../context/PropiedadProvider";
import { useLocation } from 'react-router-dom';
import { computeDestinationPoint } from 'geolib';
import { Client, GetPageInfoRequest, GetPageMediaRequest } from 'instagram-graph-api';

function Publicarpropiedad() {
    const location = useLocation();
    let IdpropiedadParam = "";
    if (location.state != null) IdpropiedadParam = location.state.IdProp;

    const [titulo, setTitulo] = useState('');
    const [images, setImages] = useState([]);
    const propiedadContext = useContext(PropiedadContext);
    const [customParam, setCustomParam] = useState("");
    const [tipopublicacion, settipopublicacion] = useState('1');
    const [tipopropiedad, settipopropiedad] = useState(2);
    const [numhab, setNumHab] = useState("");
    const [numbaños, setNumbaños] = useState("");
    const [area, setArea] = useState("");
    const [areautil, setAreaUtil] = useState("");
    const [numestacionamientos, setNumestacionamientos] = useState("");
    const [pais, setPais] = useState("");
    const [precioventa, setPrecioVenta] = useState("");
    const [moneda, setMoneda] = useState("CLP");
    const [descripcion, setdescripcion] = useState("");
    //const [idcliente, setidcliente] = useState(null);
    const [imageGalleryKey, setImageGalleryKey] = useState(0);
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const [mainImageId, setMainImageId] = useState(null);
    const [precioant, setPrecioAnt] = useState(null);
    const [latitud, setLatitud] = useState(null);
    const [longitud, setLongitud] = useState(null);
    const [idusuarioprop, setidUsuarioProp] = useState(null);

    useEffect(() => {
        if (IdpropiedadParam === null || IdpropiedadParam === "") {
            setCustomParam(propiedadContext);
        } else {
            setCustomParam(IdpropiedadParam);
        }
        //// Scroll to the top when the page is loaded
        //window.scrollTo(0, 0);

    }, [IdpropiedadParam, PropiedadContext]);

    const handleImageUpload = (uploadedImages) => {
        setImages(uploadedImages);
        setImageGalleryKey((prevKey) => prevKey + 1);
    };

    const tituloRef = useRef(null);
    const numhabRef = useRef(null);
    const numbañosRef = useRef(null);
    const areaRef = useRef(null);
    const areaUtilRef = useRef(null);
    const numestacionamientosRef = useRef(null);
    const addressRef = useRef(null);
    const precioventaRef = useRef(null);
    const paisRef = useRef(null);
    const regionRef = useRef(null);
    const ciudadRef = useRef(null);
    const comunaRef = useRef(null);

    const validateForm = () => {
        if (!titulo || titulo.length === 0) {
            tituloRef.current.focus();
            setShowValidationErrors(true);
            return;
        }

        if (numhab.toString().length === 0) {
            numhabRef.current.focus();
            setShowValidationErrors(true);
            return;
        }

        if (!numbaños || numbaños.length === 0) {
            numbañosRef.current.focus();
            setShowValidationErrors(true);
            return;
        }

        if (!area || area.length === 0) {
            areaRef.current.focus();
            setShowValidationErrors(true);
            return;
        }

        if (!areautil || areautil.length === 0) {
            areaUtilRef.current.focus();
            setShowValidationErrors(true);
            return;
        }

        if (numestacionamientos.toString().length === 0) {
            numestacionamientosRef.current.focus();
            setShowValidationErrors(true);
            return;
        }
        if (!address || address.length === 0) {
            addressRef.current.focus();
            setShowValidationErrors(true);
            return;
        }
        if (!precioventa || precioventa.length === 0) {
            precioventaRef.current.focus();
            setShowValidationErrors(true);
            return;
        }

        if (!pais || pais.length === 0) {
            paisRef.current.focus();
            setShowValidationErrors(true);
            return;
        }

        if (!region || region.length === 0) {
            regionRef.current.focus();
            setShowValidationErrors(true);
            return;
        }

        if (!ciudad || ciudad.length === 0) {
            ciudadRef.current.focus();
            setShowValidationErrors(true);
            return;
        }

        if (!comuna || comuna.length === 0) {
            comunaRef.current.focus();
            setShowValidationErrors(true);
            return;
        }

        handleSubmit();
    };

    const { user } = useContext(UserContext);

    const [switches, setSwitches] = useState({
        switch1: false,
        switch2: false,
        switch3: false,
        switch4: false,
        switch5: false,
        switch6: false,
        switch7: false,
    });


    const [switchesexacta, setSwitchesExacta] = useState({
        switch1: false,
    });

    const [switchespromo, setSwitchesPromo] = useState({
        switch1: false,
    });


    const handleSwitchExactaChange = (name) => (checked) => {
        setSwitchesExacta((prevState) => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handleSwitchPromoChange = (name) => (checked) => {
        setSwitchesPromo((prevState) => ({
            ...prevState,
            [name]: checked
        }));
    };


    const handleSwitchChange = (name) => (checked) => {
        setSwitches((prevState) => ({
            ...prevState,
            [name]: checked
        }));
    };

    const mapStyle = {
        left: '10px',
        width: '550px',
        height: '550px',
    };

    const [address, setAddress] = useState('');
    const [direccionaprox, setdireccionAprox] = useState('');
    const [map, setMap] = useState(null);
    const [ciudad, setCiudad] = useState('');
    const [comuna, setComuna] = useState('');
    const [region, setRegion] = useState('');
    const [clienteplan, setClientePlan] = useState('');
    const [nivelPromo, setNivelpromo] = useState('');


    useEffect(() => {
        const CargaValoresIniciales = () => {
            if (IdpropiedadParam && IdpropiedadParam !== '') {
                axios
                    .get(`/api/propiedades/ObtieneDetProp/${IdpropiedadParam}`)
                    .then(response => {
                        setClientePlan(user.idplan);
                        setidUsuarioProp(response.data[0].idusuario);
                        setTitulo(response.data[0].titulo);
                        settipopublicacion(response.data[0].tipopublicacion.toString());
                        settipopropiedad(response.data[0].tipopropiedad.toString());
                        setNumHab(response.data[0].numhabitaciones);
                        setNumbaños(response.data[0].numbaños);
                        setArea(response.data[0].area);
                        if (response.data[0].areaUtil) setAreaUtil(response.data[0].areaUtil);
                        else
                            setAreaUtil(response.data[0].area);
                        setNumestacionamientos(response.data[0].numestacionamientos);
                        setAddress(response.data[0].direccion);
                        setdireccionAprox(response.data[0].direccionaprox);
                        setPais(response.data[0].pais);
                        setPrecioVenta(response.data[0].precioactual.toString().replace(".",","));
                        setPrecioAnt(response.data[0].precioactual.toString().replace(".", ","));
                        setMoneda(response.data[0].moneda);
                        setdescripcion(response.data[0].descripcion);
                        setRegion(response.data[0].region);
                        setCiudad(response.data[0].ciudad);
                        setComuna(response.data[0].comuna);
                        setLatitud(response.data[0].latitud);
                        setLongitud(response.data[0].longitud);
                        setCustomParam(IdpropiedadParam);
                        //setidcliente(response.data[0].idcliente);
                        setSwitches({
                            switch1: response.data[0].tienepiscina,
                            switch2: response.data[0].tienegimnasio,
                            switch3: response.data[0].tienecalefaccion,
                            switch4: response.data[0].tienecamaras,
                            switch5: response.data[0].tienebodega,
                            switch6: response.data[0].tienecanchatenis,
                            switch7: response.data[0].tienequincho,
                        });
                        setSwitchesPromo({
                            switch1: response.data[0].nivelpromo ? true : false,
                        });
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
            else {
                setTitulo("");
                settipopublicacion('1');
                settipopropiedad(2);
                setNumHab("");
                setNumbaños("");
                setArea("");
                setAreaUtil("");
                setNumestacionamientos("");
                setAddress("");
                setdireccionAprox("");
                setPais("");
                setPrecioVenta("");
                setMoneda("CLP");
                setdescripcion("");
                setRegion("");
                setCiudad("");
                setComuna("");
                setLatitud("");
                setLongitud("");
                setSwitches({
                    switch1: false,
                    switch2: false,
                    switch3: false,
                    switch4: false,
                    switch5: false,
                    switch6: false,
                    switch7: false
                });
                setSwitchesExacta({
                    switch1: true
                });
                setSwitchesPromo({
                    switch1: false
                });
                setClientePlan(user.idplan);
            }
        };
        CargaValoresIniciales();
    }, [IdpropiedadParam]);

    const handlePlaceSelect = () => {
        const selectedPlace = document.getElementById('autocomplete').value;
        setAddress(selectedPlace);
    };

    const getComponentValue = (components, type) => {
        for (let i = 0; i < components.length; i++) {
            const component = components[i];
            if (component.types.includes(type)) {
                return component.long_name;
            }
        }
        return '';
    };

    const mapFeatureRef = useRef(null);

    const handleSearch = () => {
        try {
            let selectedResult;

            if (!map) {
                console.error('Map is not initialized');
                return;
            }

            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
                    selectedResult = results[0];

                    if (!switchesexacta.switch1) {
                        setdireccionAprox(AddressComponent(address));
                        const modifiedCoordinates = computeDestinationPoint(
                            { latitude: selectedResult.geometry.location.lat(), longitude: selectedResult.geometry.location.lng() },
                            50,
                            0
                        );

                        selectedResult.geometry.location.lat = () => modifiedCoordinates.latitude;
                        selectedResult.geometry.location.lng = () => modifiedCoordinates.longitude;
                    }

                    const addressComponents = selectedResult.address_components;
                    const ciudad = getComponentValue(addressComponents, 'administrative_area_level_2');
                    const comuna = getComponentValue(addressComponents, 'administrative_area_level_3');
                    //const comuna = getComponentValue(addressComponents, 'locality');
                    const pais = getComponentValue(addressComponents, 'country');
                    const region = getComponentValue(addressComponents, 'administrative_area_level_1');
                    setPais(pais);
                    setRegion(region);
                    setCiudad(ciudad);
                    setComuna(comuna);

                    const lat = selectedResult.geometry.location.lat();
                    const lng = selectedResult.geometry.location.lng();

                    if (!isNaN(lat) && !isNaN(lng)) {
                        setLatitud(lat);
                        setLongitud(lng);

                        // Remove the existing map feature (circle or marker) if it exists
                        if (mapFeatureRef.current) {
                            mapFeatureRef.current.setMap(null);
                            mapFeatureRef.current = null;
                        }

                        const circleOptions = {
                            strokeColor: '#FF0000',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#FF0000',
                            fillOpacity: 0.35,
                            center: { lat, lng },
                            radius: 100,
                        };

                        if (!switchesexacta.switch1) {
                            const circleFeature = new window.google.maps.Circle(circleOptions);

                            // Set the map property to display the circle on the map
                            circleFeature.setMap(map);

                            // Update the mapFeatureRef with the newly created circle
                            mapFeatureRef.current = circleFeature;
                        } else {
                            // Create a new marker instance
                            const markerFeature = new window.google.maps.Marker({
                                position: { lat, lng },
                                map,
                            });

                            // Update the mapFeatureRef with the newly created marker
                            mapFeatureRef.current = markerFeature;
                        }

                        map.setCenter({ lat, lng });
                        if (switchesexacta.switch1)
                            map.setZoom(18);
                        else
                            map.setZoom(17);
                    } else {
                        console.error('Invalid lat or lng values.');
                    }
                } else {
                    console.error('Geocode was not successful for the following reason:', status);
                }
            });
        } catch (error) {
            console.error('Error al buscar la direccion', error);
        }
    };





    const initializeMap = useCallback(() => {
        const newMap = new window.google.maps.Map(document.getElementById('map'), {
            center: { lat: 0, lng: 0 },
            zoom: 10,
        });
        setMap(newMap);

        const autocompleteInput = document.getElementById('autocomplete');
        const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput);
        autocomplete.addListener('place_changed', handlePlaceSelect);
    }, []);

    // Call the initializeMap function before using the map
    useEffect(() => {
        initializeMap();
    }, [initializeMap]);

    function AddressComponent(addressparam) {
        const modifiedAddress = modifyAddress(addressparam, 30);
        return modifiedAddress;
    }

    function modifyAddress(address, decreasePercentage) {
        const streetNumberRegex = /(\b\d+\b)/;
        const match = address.match(streetNumberRegex);
        if (match && match[1]) {
            const streetNumber = parseInt(match[1]);
            const decreaseAmount = Math.round(streetNumber * decreasePercentage / 100);
            const decreasedStreetNumber = streetNumber - decreaseAmount;
            const increasedStreetNumber = streetNumber + decreaseAmount;
            const modifiedAddress = address.replace(streetNumberRegex, `${decreasedStreetNumber} a ${increasedStreetNumber}`);
            return modifiedAddress;
        }
        return address;
    }

    const handleMainImageChange = (imageId) => {
        setMainImageId(imageId);
    };


    // Assuming you have a valid access token with necessary permissions

    const createMediaObject = async (imageUrl, caption) => {
        const formData = new FormData();
        formData.append('image_url', imageUrl);
        formData.append('caption', caption);

        try {
            const response = await fetch(
                `https://graph.facebook.com/v13.0/me/media?access_token=${ACCESS_TOKEN}`,
                {
                    method: 'POST',
                    body: formData,
                }
            );

            if (!response.ok) {
                throw new Error('Failed to create media object.');
            }

            const mediaObject = await response.json();
            return mediaObject;
        } catch (error) {
            console.error('Error creating media object:', error);
            throw error; // Re-throw the error to handle it at a higher level if needed
        }
    };

    const ACCESS_TOKEN = "IGQWRNX0NDTG41eWRCdWRqS291dTA3Rjh3OWVfZAGNFdjNkUm41S04tN29FOGYwenlCYk52UWY0STFzYXJFbHlJUGV6d2lvNnI3dUxaemMwczA5ZAmRfMlRWWlU3QUZAhZA1NhbWppdjhWODF5ZAUtVS2w1YkFwLXdZATUUZD"
    
    const publishMediaObject = async (mediaObjectId) => {
        const response = await fetch(
            `https://graph.facebook.com/v18.0/${mediaObjectId}/publish?access_token=${ACCESS_TOKEN}`,
            {
                method: 'POST',
            }
        );

        const publishResponse = await response.json();
        return publishResponse;
    };

    const shareToInstagram = async (imageUrl, caption) => {
        try {
            const mediaObject = await createMediaObject(imageUrl, caption);
            if (mediaObject.id) {
                const publishResponse = await publishMediaObject(mediaObject.id);
                console.log('Post published to Instagram:', publishResponse);
            } else {
                console.error('Failed to create media object.');
            }
        } catch (error) {
            console.error('Error sharing to Instagram:', error);
        }
    };



    // Helper function to convert base64 to Blob
    function base64ToBlob(base64Data, contentType) {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    // Helper function to convert Blob to File
    function blobToFile(theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }


    const Publicapropiedad = async () => {
        let idclienteUser;
        let response;

        if (user == null) {
            Swal.fire('Aviso', 'No se puede publicar una propiedad sin usuario registrado', 'info');
        }

        setClientePlan(user.idplan);
        idclienteUser = user.idcliente;

        const responsecount = await fetch(`api/propiedades/PropsPoridclienteCount?idcliente=${idclienteUser}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });


        var maxProps;
        let localNivelPromo = "";

        const responseplan = await axios.get(`/api/pagosplanes/GetPlan?idPlan=${user.idplan + "1"}`);
        const planData = responseplan.data;

        if (planData === "Plan no existe") {
            maxProps = 10;
            setNivelpromo("");
            localNivelPromo = "";
        }
        else {
            maxProps = planData.maxProps;
            setNivelpromo(planData.nivelpromo);
            localNivelPromo = planData.nivelpromo;
        }

      if (responsecount.ok) {
            const counts = await responsecount.json();
            if (counts.length > 0) {
                if (counts[0].totalProps >= maxProps) {
                    Swal.fire('Aviso', 'Supera el maximo de propiedades del plan contratado', 'info');
                    return;
                }
            }
        }
        else {
            const responsecountuser = await fetch(`api/propiedades/PropsPoridUsuarioCount?idUsuario=${user.usuario.idusuario}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const countsuser = await responsecountuser.json();
            if (countsuser.length > 0) {
                if (countsuser[0].totalProps >= maxProps) {
                    Swal.fire('Aviso','Supera el maximo de propiedades del usuario que son max 10', 'info');
                    return;
                }
            }
        }

        let parsedPrecioVenta;
        let precioVentaTmp = "";
        if (precioventa.toString().includes('.')) {
            precioVentaTmp = precioventa.toString().replaceAll(".", '');
        }

        if (precioventa.toString().includes(',') && precioVentaTmp!=="") {
            parsedPrecioVenta = precioVentaTmp.toString().replaceAll(",", '.');
        }

        if (precioventa.toString().includes(',') && precioVentaTmp === "") {
            parsedPrecioVenta = precioventa.toString().replaceAll(",", '.');
        }

        if (parsedPrecioVenta === undefined && precioVentaTmp!=="") {
            parsedPrecioVenta = parseFloat(precioVentaTmp.replaceAll(",", '.'), 10);
        }
        if (parsedPrecioVenta === undefined && precioVentaTmp === "")
        {
            parsedPrecioVenta = parseFloat(precioventa, 10);
        }
        else if (!parsedPrecioVenta.toString().includes(".") && parsedPrecioVenta.toString().includes(",")) {
            parsedPrecioVenta = parseFloat(parsedPrecioVenta.replaceAll(",", '.'), 10);
        }

        if (switchespromo.switch1) {
            if (idclienteUser) {
                const responsecount = await fetch(`api/propiedades/PromosPoridclienteCount?idcliente=${idclienteUser}&idpropiedad=${IdpropiedadParam}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const countpromocliente = await responsecount.json();
                if (countpromocliente.length > 0) {
                    if (countpromocliente[0].totalProps === 10) {
                        Swal.fire('Aviso','No se puede promocionar mas de 10 propiedades por cliente', 'info');
                        return;
                    }
                }
            }
        }
        
        const today = new Date();

        if (!latitud || !longitud) {
            Swal.fire('Aviso', 'No se encuentran las coordenadas de la direccion ingresada (latitud/longitud), debe presionar el boton Buscar que esta a la derecha de la direccion.', 'info');
            return;
        }

        let request = {
            idusuario: user.usuario.idusuario,
            titulo: titulo,
            idpropiedad: IdpropiedadParam == null || IdpropiedadParam === "" ? customParam : IdpropiedadParam,
            idcliente: idclienteUser,
            estadopublicacion: "Publicado",
            tipopublicacion: tipopublicacion.toString(),
            tipopropiedad: tipopropiedad,
            numhabitaciones: numhab,
            numbaños: numbaños,
            area: area,
            areautil: areautil,
            numestacionamientos: numestacionamientos,
            pais: pais,
            region: region,
            ciudad: ciudad,
            comuna: comuna,
            direccion: address,
            direccionaprox: direccionaprox,
            precioanterior: precioant <= parsedPrecioVenta ? 0.00 : parseFloat(precioant.toString().replace(",",".")),
            precioactual: parsedPrecioVenta,
            tienepiscina: switches.switch1,
            tienegimnasio: switches.switch2,
            tienecalefaccion: switches.switch3,
            tienecamaras: switches.switch4,
            tienebodega: switches.switch5,
            tienecanchatenis: switches.switch6,
            tienequincho: switches.switch7,
            imageid: mainImageId,
            moneda: moneda,
            descripcion: descripcion,
            latitud: latitud,
            longitud: longitud,
            nivelpromo: switchespromo.switch1 ? localNivelPromo : null,
            fechapublicacion: today.toISOString() 
        };

        if (idusuarioprop) request.idusuario = idusuarioprop;

        response = await fetch("api/propiedades/Actualizar", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(request)
        });



        if (response.ok) {
            //const ACCESS_TOKEN = "IGQWRPMGNkTGh3WWtqZA3dLOFliS1g2UnkwN2FXdy10RkVZAa3pHdm9PTGVLSjFZASEtUV1gzYUFkZAEo4NG8yZA05POHc4TExWamR2Y2hlaHdvQXhjNkpKeF9xYmQ5OW4yMlM0NVNIcXBFQmdkYkxURUV5dFktSEpOREkZD"
            //const client = new Client(ACCESS_TOKEN, PAGE_ID);

            //if (localNivelPromo === "3" || localNivelPromo === "4") {
            //    // Fetch image data using the API endpoint
            //    const imageId = mainImageId; // Assuming mainImageId is the image ID
            //    try {
            //        const response = await fetch(`api/images/ObtieneImagen/${imageId}`);
            //        if (response.ok) {
            //            const imageData = await response.json(); // Assuming the image data is in response.json() or response.base64Data

            //            const contentType = 'image/jpeg';
            //            const fileName = `${mainImageId}.jpg`;

            //            // Convert base64 data to Blob
            //            const blob = base64ToBlob(imageData.filecontent, contentType);

            //            // Convert Blob to File
            //            const file = blobToFile(blob, fileName);

            //            // Construct the Instagram photo URL
            //            const photoUrl = `https://scontent-scl1-1.cdninstagram.com/t51.2885-15/${file}+.jpg`;

            //            try {
            //                // Upload photo to Instagram
            //                // Usage
            //                const caption = 'Your caption for the post';
            //                shareToInstagram(photoUrl, caption);

            //                // Remove the file after successful upload
            //                //const fs = require('fs');
            //                //fs.unlinkSync(fileName);
            //                //console.log('File deleted successfully.');
            //            } catch (error) {
            //                console.error('Error uploading photo to Instagram:', error);
            //            }
            //        } else {
            //            console.error('Failed to fetch image data:', response.statusText);
            //        }
            //    } catch (error) {
            //        console.error('Error fetching image data:', error);
            //    }
            //}

            const currentPath = window.location.pathname;
            const parentPath = currentPath.split('/').slice(0, -1).join('/');
            window.location.href = parentPath + '/Mispropiedades';
        } else {
            console.log(response.statusText);
            Swal.fire('Error', 'No se pudo guardar la propiedad', 'error');
        }
    };

    const handleSubmit = () => {
        if (mainImageId != null) {
            Publicapropiedad();
        } else {
            Swal.fire('Error', 'Debe subir imagenes y seleccionar una imagen principal de la publicacion', 'error');
        }
    };

    const handlePrecioVentaChange = (event) => {
        let { value } = event.target;

        // Remove all non-digit and non-comma characters
        value = value.replace(/[^\d,]/g, '');

        // Replace any leading zeros with a single zero
        value = value.replace(/^0+/g, '0');

        // Replace multiple consecutive commas with a single comma
        value = value.replace(/,{2,}/g, ',');

        // Format the integer part with dots as thousands separators
        const parts = value.split(',');

        if (parts.length > 1) {
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        } else {
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }

        // Recombine the integer and decimal parts with a comma
        value = parts.join(',');

        event.target.value = value; // Update the input field value

        setPrecioVenta(value);
    };






        return (
            <div className="add-new-property-area pd-top-90 mg-bottom-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-9 col-lg-10">
                            <div className="section-title text-center">
                                { IdpropiedadParam === null || IdpropiedadParam === ""
                                    ?
                                    <h3>Agregar Nueva propiedad</h3>
                                    :
                                    <h3>Modificar propiedad</h3>
                                    }
                            </div>
                            <div className="border-bottom mb-4">
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <div className="section-title mb-md-0">
                                        <h4 className="pt-lg-1 pt-2">tipo Publicacion</h4>
                                    </div>
                                </div>
                                <div className="col-7 text-right add-property-btn-wrap">
                                    <div className="col-lg-6 mb-3">
                                        <div className="rld-single-select">
                                            <select className="select single-select"
                                                value={tipopublicacion}
                                                onChange={(event) => settipopublicacion(event.target.value)}>
                                                <option value={1}>Venta</option>
                                                <option value={2}>Arriendo</option>
                                            </select>
                                        </div>
                                        <div className="rld-single-select">
                                            <select className="select single-select"
                                                value={tipopropiedad}
                                                onChange={(event) => settipopropiedad(event.target.value)}>
                                                <option value={2}>Casa</option>
                                                <option value={3}>Departamento</option>
                                                <option value={4}>Galpon</option>
                                                <option value={5}>Local Comercial</option>
                                                <option value={6}>Terreno</option>
                                                <option value={7}>Oficina</option>
                                                <option value={8}>Parcela</option>
                                                <option value={9}>Bodega</option>
                                                <option value={10}>Derecho Llave</option>
                                                <option value={11}>Estacionamiento</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pd-top-100">
                                <div className="col-md-4">
                                    <div className="section-title">
                                        <h4><img src={"assets/img/icons/28.png"} alt="img" />Datos propiedad</h4>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="section-title">
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="rld-single-input">
                                                    <input required type="text" placeholder="titulo propiedad"
                                                        value={titulo}
                                                        onChange={(event) => {
                                                            setTitulo(event.target.value)
                                                        }
                                                        }
                                                        maxLength="80"
                                                        ref={tituloRef} // Set the ref for the input field
                                                        className={showValidationErrors && !titulo ? 'invalid-field' : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <textarea className="form-control" id="descripcion" rows="3"
                                                        maxLength="5000"
                                                        placeholder="descripcion"
                                                        value={descripcion}
                                                        onChange={(event) => {
                                                            setdescripcion(event.target.value)
                                                        }
                                                        }
                                                    ></textarea>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="rld-single-input">
                                                    <input required type="number" placeholder="Numero de habitaciones"
                                                        value={numhab}
                                                        onChange={(event) => {
                                                            setNumHab(event.target.value)
                                                        }
                                                        }
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 2)}
                                                        ref={numhabRef} // Set the ref for the input field
                                                        className={showValidationErrors && !numhab ? 'invalid-field' : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="rld-single-input">
                                                    <input required type="number" placeholder="Numero de baños"
                                                        value={numbaños}
                                                        onChange={(event) => {
                                                            setNumbaños(event.target.value)
                                                        }
                                                        }
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 2)}
                                                        ref={numbañosRef} // Set the ref for the input field
                                                        className={showValidationErrors && !numbaños ? 'invalid-field' : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="rld-single-input">
                                                    <input required type="number" placeholder="Tamaño Mt2"
                                                        value={area}
                                                        onChange={(event) => {
                                                            setArea(event.target.value)
                                                            setAreaUtil(event.target.value)
                                                         }
                                                        }
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                                                        ref={areaRef}
                                                        className={showValidationErrors && !area ? 'invalid-field' : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="rld-single-input">
                                                    <input required type="number" placeholder="Tamaño Util Mt2"
                                                        value={areautil}
                                                        onChange={(event) => {
                                                            setAreaUtil(event.target.value)
                                                        }
                                                        }
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                                                        ref={areaUtilRef}
                                                        className={showValidationErrors && !areautil ? 'invalid-field' : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="rld-single-input">
                                                    <input required type="number" placeholder="# de estacionamientos"
                                                        value={numestacionamientos}
                                                        onChange={(event) => {
                                                            setNumestacionamientos(event.target.value)
                                                         }
                                                        }
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                                        ref={numestacionamientosRef}
                                                        className={showValidationErrors && !numestacionamientos ? 'invalid-field' : ''}
                                                    />
                                                </div>
                                            </div>
                                            {clienteplan && (
                                                <div className="switch-item">
                                                    <Switch
                                                        onChange={handleSwitchPromoChange('switch1')}
                                                        checked={switchespromo.switch1}
                                                        id="switch1"
                                                    />
                                                    <label htmlFor="switch1" className="switch-label">Promocionar</label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                    </div>
                                </div>
                            </div>
                            <div className="row pd-top-80">
                                <div className="col-md-4">
                                    <div className="section-title">
                                        <h4><img src={"assets/img/icons/29.png"} alt="img" />direccion</h4>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="section-title">
                                        <h4>Ingrese la direccion</h4>
                                    </div>
                                    <div className="switch-item">
                                        <Switch
                                            onChange={handleSwitchExactaChange('switch1')}
                                            checked={switchesexacta.switch1}
                                            id="switch1"
                                        />
                                        <label htmlFor="switchexacta" className="switch-label">Publicar direccion Exacta?</label>
                                    </div>
                                    <div className="filter-title">
                                        <h6>direccion Publicacion: {direccionaprox}</h6>
                                    </div>
                                    <div className="rld-single-input d-flex">
                                            <input
                                                type="text"
                                                id="autocomplete"
                                                value={address}
                                                onChange={(e) => {
                                                setAddress(e.target.value)
                                                }
                                            }
                                            ref={addressRef}
                                            className={showValidationErrors && !address ? 'invalid-field' : '' } 
                                                placeholder="Ingresa la direccion"
                                            />
                                            <button className="btn btn-yellow" onClick={handleSearch}>Buscar</button>
                                        
                                            </div>
                                    <div className="row">
                                        <div id="map" style={mapStyle}></div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="rld-single-input">
                                                <label>pais</label>
                                                    <input required type="text" placeholder="pais"
                                                        value={pais}
                                                        onChange={(event) => setPais(event.target.value)}
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 20)}
                                                        ref={paisRef}
                                                        className={showValidationErrors && !pais ? 'invalid-field' : ''} 

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="rld-single-input">
                                                    <label>region</label>
                                                    <input required type="text" placeholder="region"
                                                        value={region}
                                                        onChange={(event) => setRegion(event.target.value)}
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 20)}
                                                        ref={regionRef}
                                                        className={showValidationErrors && !region ? 'invalid-field' : ''} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="rld-single-input">
                                                    <label>ciudad</label>
                                                    <input required type="text" placeholder="ciudad"
                                                        value={ciudad}
                                                        onChange={(event) => setCiudad(event.target.value)}
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 20)}
                                                        ref={ciudadRef}
                                                        className={showValidationErrors && !ciudad ? 'invalid-field' : ''} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="rld-single-input">
                                                    <label>comuna</label>
                                                    <input required type="text" placeholder="comuna"
                                                        value={comuna}
                                                        onChange={(event) => setComuna(event.target.value)}
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 20)}
                                                        ref={comunaRef}
                                                        className={showValidationErrors && !comuna ? 'invalid-field' : ''} 
                                                    />
                                                </div>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                                <div className="row pd-top-80">
                                    <div className="col-md-4">
                                    <div className="section-title">
                                        {tipopublicacion === '1' &&
                                            <h4><img src={"assets/img/icons/30.png"} alt="img" />Precio Venta</h4>
                                        }
                                        {tipopublicacion === '2' &&
                                            <h4><img src={"assets/img/icons/30.png"} alt="img" />Valor Mensual</h4>
                                        }
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="rld-single-input">
                                                    <label>Ingrese el Monto</label>
                                                <input
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={tipopublicacion === '1' ? "Precio Venta" : "Valor Arriendo"}
                                                    value={precioventa}
                                                    onChange={(event) => {
                                                        handlePrecioVentaChange(event);
                                                    }}
                                                    ref={precioventaRef}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="rld-single-select">
                                                    <label>Seleccione la moneda</label>
                                                    <select
                                                        className="select single-select"
                                                        value={moneda}
                                                        onChange={(event) => setMoneda(event.target.value)}>
                                                        <option value={"CLP"}>PESOS</option>
                                                        <option value={"UF"}>UF</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="row pd-top-80">
                                <div className="col-md-4">
                                    <div className="section-title">
                                        <h4><img src={"assets/img/icons/31.png"} alt="img" />Incluye</h4>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="switch-container">
                                                <style>
                                                    {`
                                                  .switch-container {
                                                    display: grid;
                                                    grid-template-columns: repeat(3, 1fr);
                                                    gap: 20px;
                                                    column-gap: 80px; /* Additional space between columns */
                                                  }
                                                  .switch-item {
                                                    display: flex;
                                                    align-items: center;
                                                  }
                                                  .switch-label {
                                                    margin-left: 8px;
                                                  }
                                                `}
                                                </style>
                                                <div className="switch-item">
                                                    <Switch
                                                        onChange={handleSwitchChange('switch1')}
                                                        checked={switches.switch1}
                                                        id="switch1"
                                                    />
                                                    <label htmlFor="switch1" className="switch-label">Piscina</label>
                                                </div>
                                                <div className="switch-item">
                                                    <Switch
                                                        onChange={handleSwitchChange('switch2')}
                                                        checked={switches.switch2}
                                                        id="switch2"
                                                    />
                                                    <label htmlFor="switch2" className="switch-label">Gimnasio</label>
                                                </div>
                                                <div className="switch-item">
                                                    <Switch
                                                        onChange={handleSwitchChange('switch3')}
                                                        checked={switches.switch3}
                                                        id="switch3"
                                                    />
                                                    <label htmlFor="switch3" className="switch-label">Calefaccion</label>
                                                </div>

                                                <div className="switch-item">
                                                    <Switch
                                                        onChange={handleSwitchChange('switch4')}
                                                        checked={switches.switch4}
                                                        id="switch4"
                                                    />
                                                    <label htmlFor="switch4" className="switch-label">Camaras</label>
                                                </div>
                                                <div className="switch-item">
                                                    <Switch
                                                        onChange={handleSwitchChange('switch5')}
                                                        checked={switches.switch5}
                                                        id="switch5"
                                                    />
                                                    <label htmlFor="switch5" className="switch-label">Bodega</label>
                                                </div>
                                                <div className="switch-item">
                                                    <Switch
                                                        onChange={handleSwitchChange('switch6')}
                                                        checked={switches.switch6}
                                                        id="switch6"
                                                    />
                                                    <label htmlFor="switch6" className="switch-label">Cancha Tennis</label>
                                                </div>
                                                <div className="switch-item">
                                                    <Switch
                                                        onChange={handleSwitchChange('switch7')}
                                                        checked={switches.switch7}
                                                        id="switch7"
                                                    />
                                                    <label htmlFor="switch7" className="switch-label">Quincho</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pd-top-80">
                                <div className="col-md-4">
                                    <div className="section-title">
                                        <h4><img src={"assets/img/icons/galeriaimagenes.png"} alt="img" />imagenes</h4>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <ImagesUpload customParam={customParam} onImagesUploaded={handleImageUpload} />
                                    <ImageGallery customParam={customParam} key={imageGalleryKey} images={images}
                                        onMainImageChange={handleMainImageChange}
                                    />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button className="btn btn-yellow" onClick={validateForm}>
                        {IdpropiedadParam === null || IdpropiedadParam === ""
                            ?
                            'Publicar propiedad'
                            :
                            'Modificar propiedad'
                            }
                    </Button>
                </div>
            </div>
        );
    }
export default Publicarpropiedad