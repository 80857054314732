import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import CompararpropiedadesSection from './section-components/compararpropiedades';
import Footer from './global-components/footer';

const Compararpropiedades = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Comparacion de propiedades" headerimage="4.png" />
        <CompararpropiedadesSection />
        <Footer />
    </div>
}

export default Compararpropiedades

