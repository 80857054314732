import React, { useEffect, useState, useRef } from 'react';
import { Table, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

const GestionUsuarios = () => {
    const [users, setUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInputRef = useRef(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('/api/usuario/ObtieneUsuarios');
                if (response.status === 200) {
                    setUsers(response.data);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);

    const handleCheckboxChange = async (userId) => {
        const updatedUsers = users.map((user) =>
            user.idusuario === userId
                ? { ...user, esactivo: !user.esactivo, fechabloqueo: !user.esactivo ? new Date() : null }
                : user
        );
        setUsers(updatedUsers);

        try {
            await axios.put('/api/usuario/Editar', updatedUsers.find((user) => user.idusuario === userId));
            // Update successful
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const customSorter = (a, b, dataIndex) => {
        if (typeof a[dataIndex] === 'string') {
            return a[dataIndex].localeCompare(b[dataIndex]);
        }
        if (typeof a[dataIndex] === 'number') {
            return a[dataIndex] - b[dataIndex];
        }
        if (a[dataIndex] instanceof Date) {
            return new Date(a[dataIndex]) - new Date(b[dataIndex]);
        }
        return 0;
    };

    const getColumnSearchProps = (dataIndex, inputPlaceholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInputRef}
                    placeholder={`Search ${inputPlaceholder}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: (text) => (searchedColumn === dataIndex ? <span style={{ fontWeight: 'bold' }}>{text}</span> : text),
    });

    const columns = [
        {
            title: 'Es Activo',
            dataIndex: 'esactivo',
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false },
            ],
            onFilter: (value, record) => record.esactivo === value,
            render: (text, record) => (
                <input
                    type="checkbox"
                    checked={record.esactivo}
                    onChange={() => handleCheckboxChange(record.idusuario)}
                />
            ),
            width: 100,
            align: 'center',
        },
        {
            title: 'ID Usuario',
            dataIndex: 'idusuario',
            key: 'idusuario', // Ensure 'key' prop is unique
            ...getColumnSearchProps('idusuario', 'ID Usuario'),
            sorter: (a, b) => customSorter(a, b, 'idusuario'),
        },
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            ...getColumnSearchProps('nombre', 'Nombre'),
            sorter: (a, b) => customSorter(a, b, 'nombre'),
        },
        {
            title: 'Correo',
            dataIndex: 'correo',
            ...getColumnSearchProps('correo', 'Correo'),
            sorter: (a, b) => customSorter(a, b, 'correo'),
        },
        {
            title: 'Teléfono',
            dataIndex: 'telefono',
            ...getColumnSearchProps('telefono', 'Teléfono'),
            sorter: (a, b) => customSorter(a, b, 'telefono'),
        },
        {
            title: 'Rol',
            dataIndex: 'rol',
            ...getColumnSearchProps('rol', 'Rol'),
            sorter: (a, b) => customSorter(a, b, 'rol'),
        },
        {
            title: 'País',
            dataIndex: 'pais',
            ...getColumnSearchProps('pais', 'País'),
            sorter: (a, b) => customSorter(a, b, 'pais'),
        },
        {
            title: 'Ciudad',
            dataIndex: 'ciudad',
            ...getColumnSearchProps('ciudad', 'Ciudad'),
            sorter: (a, b) => customSorter(a, b, 'ciudad'),
        },
        {
            title: 'Comuna',
            dataIndex: 'comuna',
            ...getColumnSearchProps('comuna', 'Comuna'),
            sorter: (a, b) => customSorter(a, b, 'comuna'),
        },
        {
            title: 'Fecha Bloqueo',
            dataIndex: 'fechabloqueo',
            ...getColumnSearchProps('fechabloqueo', 'Fecha Bloqueo'),
            sorter: (a, b) => customSorter(a, b, 'fechabloqueo'),
        },
        {
            title: 'Primer Acceso',
            dataIndex: 'primeracceso',
            ...getColumnSearchProps('primeracceso', 'Primer Acceso'),
            sorter: (a, b) => customSorter(a, b, 'primeracceso'),
        },
        {
            title: 'Ultimo Acceso',
            dataIndex: 'ultimoacceso',
            ...getColumnSearchProps('ultimoacceso', 'Ultimo Acceso'),
            sorter: (a, b) => customSorter(a, b, 'ultimoacceso'),
        },
    ];

    const tableStyle = {
        border: '1px solid #ddd',
    };

    const cellStyle = {
        border: '1px solid #ddd',
        padding: '8px',
    };

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
        <Table
            columns={columns}
            dataSource={users}
            bordered
            style={tableStyle}
            rowKey="idusuario"
            pagination={false}
            components={{
                body: {
                    cell: ({ children, dataIndex, rowIndex }) => (
                        <td key={`${dataIndex}-${rowIndex}`} style={cellStyle}>
                            {children}
                        </td>
                    ),
                },
            }}
            //scroll={{ x: true }}
            />
        </div>
    );
};

export default GestionUsuarios;
