import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import sectiondata from '../../data/sections.json';
import axios from 'axios';
import { UserContext } from '../../context/UserProvider';
import Promocionados from '../section-components/promocionados';

const Banner = () => {
    const [busquedaComprar, setBusquedaComprar] = useState('');
    const [busquedaArrendar, setBusquedaArrendar] = useState('');
    const [tipo, settipo] = useState('1');
    const [historialComprar, setHistorialComprar] = useState([]);
    const [historialArrendar, setHistorialArrendar] = useState([]);
    let data = sectiondata.banner;
    const { user } = useContext(UserContext);

    const inlineStyle = {
        backgroundImage: 'url(/assets/img/banner/bannernaranja2.png)',
        backgoundColor: 'var(--main-color-one)'
    };

    const isBusquedaComprarEmpty = busquedaComprar.trim() === '';
    const isBusquedaArrendarEmpty = busquedaArrendar.trim() === '';

    useEffect(() => {
        const fetchHistorialBusquedas = async () => {
            try {
                const idUsuario = user.usuario.idusuario;

                // Fetch search history for "Arrendar" tab
                const responseArrendar = await axios.get(`/api/usuario/ObtieneHistorial/${idUsuario}/Arrendar`);
                const { frecuenciaDirecciones: frecuenciadireccionesArrendar } = responseArrendar.data;

                if (frecuenciadireccionesArrendar && Object.keys(frecuenciadireccionesArrendar).length > 0) {
                    const sorteddireccionesArrendar = Object.keys(frecuenciadireccionesArrendar).sort(
                        (a, b) => frecuenciadireccionesArrendar[b] - frecuenciadireccionesArrendar[a]
                    );
                    setHistorialArrendar(sorteddireccionesArrendar);
                } else {
                    setHistorialArrendar([]);
                }

                // Fetch search history for "Comprar" tab
                const responseComprar = await axios.get(`/api/usuario/ObtieneHistorial/${idUsuario}/Comprar`);
                const { frecuenciaDirecciones: frecuenciadireccionesComprar } = responseComprar.data;

                if (frecuenciadireccionesComprar && Object.keys(frecuenciadireccionesComprar).length > 0) {
                    const sorteddireccionesComprar = Object.keys(frecuenciadireccionesComprar).sort(
                        (a, b) => frecuenciadireccionesComprar[b] - frecuenciadireccionesComprar[a]
                    );
                    setHistorialComprar(sorteddireccionesComprar);
                } else {
                    setHistorialComprar([]);
                }
            } catch (error) {
                console.error('Error fetching search history:', error);
            }
        };
        if (user && user.usuario) {
            fetchHistorialBusquedas();
        }
    }, [user]);

    const handleHistorialItemClickComprar = (item) => {
        setBusquedaComprar(item);
        setHistorialComprar([]);
    };

    const handleHistorialItemClickArrendar = (item) => {
        setBusquedaArrendar(item);
        setHistorialArrendar([]);
    };

    return (
        <div className="banner-area jarallax" style={inlineStyle}>
            <div className="container">
                <div className="banner-inner-wrap">
                    <div className="row">
                        <div className="col-12">
                            <div className="banner-inner">
                                 <h3 className="" style={{ fontFamily: 'Indie Flower' }}>
                                    Potenciado por IA <br /> {data.title2}
                                </h3>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="banner-search-wrap">
                                <ul className="nav nav-tabs rld-banner-tab">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#tabs_1">
                                            Comprar
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabs_2">
                                            Arrendar
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="tabs_1">
                                        <div className="rld-main-search">
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6">
                                                    <div className="rld-single-input left-icon">
                                                        <Input
                                                            type="text"
                                                            id="busquedaComprar"
                                                            placeholder="Ingrese Calle/comuna o ciudad"
                                                            value={busquedaComprar}
                                                            onChange={(event) => setBusquedaComprar(event.target.value)}
                                                            required
                                                            autoComplete="off"
                                                            list="historial-datalist-comprar"
                                                        />
                                                        <datalist id="historial-datalist-comprar">
                                                            {historialComprar.map((item) => (
                                                                <option
                                                                    key={item}
                                                                    value={item}
                                                                    onClick={() => handleHistorialItemClickComprar(item)}
                                                                />
                                                            ))}
                                                        </datalist>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-6 col-md-6">
                                                    <div className="rld-single-select">
                                                        <select
                                                            className="select single-select"
                                                            style={{ width: '105%' }} // Set the width to 100% to ensure full visibility
                                                            onChange={(event) => settipo(event.target.value)}
                                                        >
                                                            <option value="1">Todos los tipos</option>
                                                            <option value="2">Casa</option>
                                                            <option value="3">Departamento</option>
                                                            <option value="4">Galpon</option>
                                                            <option value="5">Local Comercial</option>
                                                            <option value="6">Terreno</option>
                                                            <option value="7">Oficina</option>
                                                            <option value="8">Parcela</option>
                                                            <option value="9">Bodega</option>
                                                            <option value="10">Derecho Llave</option>
                                                            <option value="11">Estacionamiento</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                                                    <Link
                                                        className="btn btn-yellow"
                                                        to={isBusquedaComprarEmpty ? '#' : '/busquedapropiedades'}
                                                        state={{ busqueda: `Comprar-${tipo}-${busquedaComprar}` }}
                                                        onClick={isBusquedaComprarEmpty ? (e) => e.preventDefault() : null}
                                                    >
                                                        BUSCAR
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="tabs_2">
                                        <div className="rld-main-search">
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6">
                                                    <div className="rld-single-input left-icon">
                                                        <Input
                                                            type="text"
                                                            id="busquedaArrendar"
                                                            placeholder="Ingrese Calle/comuna o ciudad"
                                                            value={busquedaArrendar}
                                                            onChange={(event) => setBusquedaArrendar(event.target.value)}
                                                            required
                                                            autoComplete="off"
                                                            list="historial-datalist-arrendar"
                                                        />
                                                        <datalist id="historial-datalist-arrendar">
                                                            {historialArrendar.map((item) => (
                                                                <option
                                                                    key={item}
                                                                    value={item}
                                                                    onClick={() => handleHistorialItemClickArrendar(item)}
                                                                />
                                                            ))}
                                                        </datalist>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-6 col-md-6">
                                                    <div className="rld-single-select">
                                                        <select
                                                            className="select single-select"
                                                            style={{ width: '105%' }} // Set the width to 100% to ensure full visibility
                                                            onChange={(event) => settipo(event.target.value)}
                                                        >
                                                            <option value="1">Todos los tipos</option>
                                                            <option value="2">Casa</option>
                                                            <option value="3">Departamento</option>
                                                            <option value="4">Galpon</option>
                                                            <option value="5">Local Comercial</option>
                                                            <option value="6">Terreno</option>
                                                            <option value="7">Oficina</option>
                                                            <option value="8">Parcela</option>
                                                            <option value="9">Bodega</option>
                                                            <option value="10">Derecho Llave</option>
                                                            <option value="11">Estacionamiento</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                                                    <Link
                                                        className="btn btn-yellow"
                                                        to={isBusquedaArrendarEmpty ? '#' : '/busquedapropiedades'}
                                                        state={{ busqueda: `Arrendar-${tipo}-${busquedaArrendar}` }}
                                                        onClick={isBusquedaArrendarEmpty ? (e) => e.preventDefault() : null}
                                                    >
                                                        BUSCAR
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default Banner;
