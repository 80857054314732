import React, { useEffect, useState, useContext, useRef } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from "../../context/UserProvider";
import { FormatoNumero } from '../global-components/globalfunctions';

const fetchCercanosData = async (property) => {
    try {
        const query = `[out:json]; 
            (
                node(around:1000,${property.latitud},${property.longitud})["highway"="bus_stop"];
                node(around:1000,${property.latitud},${property.longitud})["railway"="station"];
                node(around:1000,${property.latitud},${property.longitud})["amenity"="school"];
            );
            out;`;

        const response = await axios.post('https://overpass-api.de/api/interpreter', query);

        if (response.data && response.data.elements) {
            const elements = response.data.elements;

            // Check for specific tags
            const hasSchool = elements.some(elem => elem.tags && elem.tags.amenity === 'school');
            const hasBusStop = elements.some(elem => elem.tags && elem.tags.highway === 'bus_stop');
            const hasTrainStation = elements.some(elem => elem.tags && elem.tags.railway === 'station');

            // Build the resulting text based on the logic
            let resultText = '';
            if (hasSchool) {
                resultText += 'Cercano a Colegios';
                if (hasBusStop) {
                    resultText += ', Paradas de Buses';
                    if (hasTrainStation) {
                        resultText += ', Metro';
                    }
                } else if (hasTrainStation) {
                    resultText += ', Metro';
                }
            } else if (hasBusStop) {
                resultText += 'Cercano a Paradas de Buses';
                if (hasTrainStation) {
                    resultText += ', Metro';
                }
            } else if (hasTrainStation) {
                resultText += 'Cercano a Metro';
            }

            return resultText;
        } else {
            throw new Error('No se encontraron datos de transporte.');
        }
    } catch (error) {
        console.error('Error al obtener datos de transporte: ', error);
        throw new Error('Ocurrió un error al obtener datos de transporte.');
    }
};


const fetchPromos = async (setProperties, setLoading, user, signal) => {
    try {
        const url = `api/propiedades/ObtienePromos?userId=${encodeURIComponent(user ? user.usuario.idusuario : 0)}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            signal, // Pass the signal from the AbortController
        });

        if (response.ok) {
            const data = await response.json();

            // Fetch additional data for each property
            const propertiesWithCercanosData = await Promise.all(
                data.map(async (property) => {
                    try {
                        const cercanosData = await fetchCercanosData(property.property);
                        return { ...property, cercanosData };
                    } catch (error) {
                        console.error('Error fetching cercanos data:', error);
                        return property;
                    }
                })
            );

            setProperties(propertiesWithCercanosData);
            setLoading(false);
        } else {
            console.error('Error fetching properties:', response.statusText);
            setLoading(false);
        }
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching properties:', error);
            setLoading(false);
        }
    }
};


function Promocionados() {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    //const [busStops, setBusStops] = useState([]);
    //const [trainStations, setTrainStations] = useState([]);
    //const [colegios, setColegios] = useState([]);

    //window.scrollTo(0, 0);

    const { user } = useContext(UserContext);

    useEffect(() => {
        let abortController;

        const fetchData = async () => {
            abortController = new AbortController();

            try {
                await fetchPromos(setProperties, setLoading, user, abortController.signal);
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Error fetching properties:', error);
                }
            }
        };

        fetchData();

        // Clean up function to cancel any pending asynchronous operations
        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, []);


    const toggleFavorite = async (property) => {
        try {
            const heartIcon = document.getElementById(`heartIcon-${property.property.idpropiedad}`);

            if (heartIcon) {
                const isSelected = heartIcon.classList.contains('selected');

                if (!isSelected) {
                    const url = `/api/propiedades/marcafavorito/${property.property.idpropiedad}/${user.usuario.idusuario}/${1}`;
                    const requestBody = null;
                    const headers = {
                        'Content-Type': 'application/json',
                    };

                    const response = await axios.post(url, requestBody, { headers });
                    console.log('Response:', response);
                    heartIcon.classList.add('selected');
                    heartIcon.style.color = 'red';
                } else {
                    const url = `/api/propiedades/marcafavorito/${property.property.idpropiedad}/${user.usuario.idusuario}/${0}`;
                    const requestBody = null;
                    const headers = {
                        'Content-Type': 'application/json',
                    };

                    const response = await axios.post(url, requestBody, { headers });
                    heartIcon.classList.remove('selected');
                    heartIcon.style.color = '';
                }
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const renderHeartIcon = (property) => {
        if (property.isFavorite) {
            return (
                <i
                    id={`heartIcon-${property.property.idpropiedad}`}
                    className="fa fa-heart selected"
                    style={{ color: 'red' }}
                    onClick={() => toggleFavorite(property)}
                />
            );
        } else {
            return (
                <i
                    id={`heartIcon-${property.property.idpropiedad}`}
                    className="fa fa-heart"
                    onClick={() => toggleFavorite(property)}
                />
            );
        }
    };

    function SubTitulo(item) {
        let subtitulo = '';
        if (item.tipopropiedad === 2) subtitulo = subtitulo + 'Casa en ';
        if (item.tipopropiedad === 3) subtitulo = subtitulo + 'Departamento en ';
        if (item.tipopropiedad === 4) subtitulo = subtitulo + 'Galpon en ';
        if (item.tipopropiedad === 5) subtitulo = subtitulo + 'Local Comercial en ';
        if (item.tipopropiedad === 6) subtitulo = subtitulo + 'Terreno en ';
        if (item.tipopropiedad === 7) subtitulo = subtitulo + 'Oficina en ';
        if (item.tipopropiedad === 8) subtitulo = subtitulo + 'Parcela en ';
        if (item.tipopropiedad === 9) subtitulo = subtitulo + 'Bodega en ';
        if (item.tipopropiedad === 10) subtitulo = subtitulo + 'Derecho Llave en ';
        if (item.tipopropiedad === 11) subtitulo = subtitulo + 'Estacionamiento en ';
        if (item.tipopublicacion === 1) subtitulo = subtitulo + 'Venta';
        if (item.tipopublicacion === 2) subtitulo = subtitulo + 'Arriendo';
        return subtitulo;
    }

    const swiperRef = useRef(null);

    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };



    return (
        <div className="author-area pd-top-0 pd-bottom-0" >
            {loading ? (
                <div></div>
            ) : properties.length > 0 ? (
                <div className="container">
                    <div className="text-center">
                            <h4 className="" style={{color: 'var(--main-color-one)' }}>Propiedades Destacadas</h4>
                        <br></br>
                        </div>
                        <div className="swiper-container">
                            <div className="swiper-wrapper">
                              <Swiper
                                ref={swiperRef}
                                spaceBetween={10}
                                slidesPerView={'auto'}
                                navigation={false} 
                                pagination={{ clickable: true }}
                                loop={true}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                    },
                                }}

                            >
                                {properties.map((property, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="col-lg-12">
                                            <div className="single-feature-promo" style={{ marginLeft: '30px', marginRight: '30px' }} >
                                                <Link to={`/detallepropiedad/${property.property.idpropiedad}`}>
                                                <div className="thumb">
                                                    <div className="thumb-image" style={{ position: 'relative' }}>
                                                        {property.nivelpromo && (
                                                            <div className="promo-icon" style={{ position: 'absolute', top: 15, left: 15 }}>
                                                                <img src={'/assets/img/icons/promo-icon-9.png'} alt="Promo" style={{ width: '40px', height: '40px' }} />
                                                            </div>
                                                        )}
                                                        <img
                                                            src={`data:image/jpeg;base64,${property.base64Data}`}
                                                            alt="img"
                                                            style={{ width: '100%', height: `200px` }}
                                                        />
                                                    </div>
                                                    </div>
                                                </Link>
                                                <div className="details">
                                                    <div className="details-wrap">
                                                        <div className="feature-logo">
                                                            {property.icono ? (
                                                                <img
                                                                    src={`data:image/jpeg;base64,${property.icono}`}
                                                                    alt="logo"
                                                                    className="rounded-circle img-thumbnail thumbnail-icon"
                                                                    style={{ Width: '45px', height: '40px', position: 'relative', top: '-5px' }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className="img-profile rounded-circle"
                                                                    src={'/assets/img/Foto003.jpg'}
                                                                    alt="logo"
                                                                />
                                                            )}
                                                        </div>
                                                        {user && user.usuario && (
                                                            <div className="favorite-icon">
                                                                {renderHeartIcon(property)}
                                                            </div>
                                                        )}
                                                        <p className="author">
                                                            <i className="fa fa-user"/> {property.nombre}
                                                        </p>
                                                        <label className="readeal-top" style={{ fontSize: '14px' }}>{SubTitulo(property.property)}</label>

                                                        <label className="title readeal-top" style={{ fontSize: '15px' }}>
                                                            <a>
                                                                {(property.property.titulo + ' - ' + property.property.comuna)}
                                                            </a>
                                                        </label>
                                                        <div>
                                                        <label className="price" style={{ fontSize: '14px' }}>
                                                            <span >{FormatoNumero(property.property.precioactual)}</span>
                                                            {' '}
                                                            <span className="moneda" >{property.property.moneda}</span>
                                                        </label>
                                                            <br></br>
                                                            <del className="precioanterior">{FormatoNumero(property.property.precioAnterior)}</del>
                                                        </div>
                                                        

                                                        <div className="info-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <div>
                                                                {property.property.numhabitaciones > 0 && (
                                                                    <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                                                        <i className="fa fa-bed" style={{ marginRight: '5px', color: 'var(--main-color-three)' }} />
                                                                        <span>{property.property.numhabitaciones} Habs</span>
                                                                    </div>
                                                                )}
                                                                {property.property.numestacionamientos > 0 && (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <i className="fa fa-car" style={{ marginRight: '5px', color: 'var(--main-color-three)' }} />
                                                                        <span>{property.property.numestacionamientos} Estac.</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div style={{ textAlign: 'right' }}>
                                                                {property.property.numbaños > 0 && (
                                                                    <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                                                        <i className="fa fa-bath" style={{ marginRight: '5px', color: 'var(--main-color-three)' }} />
                                                                        <span>{property.property.numbaños} baños</span>
                                                                    </div>
                                                                )}
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img src={'/assets/img/icons/area.png'} alt="area" style={{ marginRight: '10px', filter: 'var(--main-color-three)' }} />
                                                                    <span>{property.property.area} Mt2</span>
                                                                </div>
                                                            </div>
                                                         </div>
                                                        <div style={{ width: '150px', borderBottom: '2px solid ', margin: '0 auto 10px', marginTop: '20px' }} />
                                                        <div className="info-container" style={{alignItems: 'center', fontWeight: 'bold' }}>
                                                            {property.cercanosData}
                                                        </div>
                                                        <ul className="contact-list">
                                                            <li className="readeal-top">
                                                                <Link className="btn btn-yellow" to={`/detallepropiedad/${property.property.idpropiedad}`}>
                                                                    Detalles
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            
                                </Swiper>
                            </div>
                            <div className="custom-navigation" style={{ display: 'flex', justifyContent: 'center' }}>
                                <button className="custom-prev" onClick={handlePrevClick}>
                                    <img src="/assets/img/buttons/Buscador-propiedades-landings_Boton-naranja-atrás.png" alt="Left Arrow" />
                                </button>
                                <button className="custom-next" onClick={handleNextClick}>
                                    <img src="/assets/img/buttons/Buscador-propiedades-landings_Boton-naranja-adelante.png" alt="Right Arrow" />
                                </button>
                            </div>

                        </div>
                    <div>
                        <Link
                            to={'/busquedapropiedades'}
                            state={{ busqueda: `Promocionados` }}
                            style={{ color: 'var(--main-color-one)', fontWeight: 'bold' , fontSize: '12px'}}
                        >
                                Ver Todas
                        </Link>
                    </div>
                </div>
            ) : (
                <div>No promoted properties available.</div>
            )}
        </div>
    );
}

export default Promocionados;
