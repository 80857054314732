import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeV1 from './components/home-v1';
import PreciosPlanes from './components/preciosplanes';
import PagoPlan from './components/pagoplan';
import Login from './components/login';
import Registro from './components/registro';
import Error from './components/error';
import Contacto from './components/contacto';
import PublicarPropiedad from './components/publicarpropiedad';
import UserProvider from './context/UserProvider';
import MisPropiedades from './components/mispropiedades';
import DetallePropiedad from './components/detallepropiedad';
import BusquedaPropiedades from './components/busquedapropiedades';
import PropiedadesPorCiudad from './components/section-components/propiedadesxciudad';
import Clientes from './components/clientes';
import UsuariosClientes from './components/usuariosclientes';
import GestionUsuarios from './components/gestionusuarios';
import { PropiedadProvider } from './context/PropiedadProvider';
import ResetContrasena from './components/resetcontrasena';
import CompararPropiedades from './components/compararpropiedades';
import MisFavoritas from './components/misfavoritas';
import Listaclientes from './components/listaclientes';
import Reseñas from './components/reseñas';
import MiSubscripcion from './components/misubscripcion';
import About from './components/about';
import Noticias from './components/noticias';
import PoliticaPrivacidad from './components/politicaprivacidad';



function Root() {
    
    return (
        <Router basename="">
            <div>
                <Routes>
                    <Route path="/" element={<HomeV1 />} />
                    <Route path="/mispropiedades" element={<MisPropiedades />} />
                    <Route path="/detallepropiedad/:idpropiedad" element={<DetallePropiedad />} />
                    <Route path="/publicarpropiedad" element={<PublicarPropiedad />} />
                    <Route path="/busquedapropiedades" element={<BusquedaPropiedades />} />
                    <Route path="/clientes" element={<Clientes />} />
                    <Route path="/usuariosclientes" element={<UsuariosClientes />} />
                    <Route path="/resetcontrasena" element={<ResetContrasena />} />
                    <Route path="/propiedadesxciudad" element={<PropiedadesPorCiudad />} />
                    <Route path="/gestionusuarios" element={<GestionUsuarios />} />
                    <Route path="/misfavoritas" element={<MisFavoritas />} />
                    <Route path="/listaclientes" element={<Listaclientes />} />
                    <Route path="/compararpropiedades/:idpropiedad1/:idpropiedad2" element={<CompararPropiedades />} />
                    <Route path="/reseñas/:idusuarioTarget" element={<Reseñas />} />
                    <Route path="/preciosplanes" element={<PreciosPlanes />} />
                    <Route path="/misubscripcion" element={<MiSubscripcion />} />
                    <Route path="/pagoplan" element={<PagoPlan />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/registro" element={<Registro />} />
                    {/*<Route path="/error" element={<Error />} />*/}
                    <Route path="/contacto" element={<Contacto />} />
                    <Route path="/noticias" element={<Noticias />} />
                    <Route path="/noticias/:newsId" element={<Noticias />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/politicaprivacidad" element={<PoliticaPrivacidad />} />
                </Routes>
            </div>
        </Router>
    );
}

ReactDOM.render(
    <UserProvider>
        <PropiedadProvider>
            <Root />
        </PropiedadProvider>
    </UserProvider>,
    document.getElementById('Buscadorpropiedades')
);
