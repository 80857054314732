import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import RegistroSection from './section-components/registro';
import Footer from './global-components/footer';

const Registro = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Registro de Usuario" headerimage="11.png" />
        <RegistroSection />
        <Footer />
    </div>
}

export default Registro

