import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDropzone } from 'react-dropzone';

const ImagesUpload = ({ customParam, onImagesUploaded }) => {
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedFileNames, setSelectedFileNames] = useState([]);
    const maxImageCount = 20;
    const [processing, setProcessing] = useState(false);
    const [uploadStarted, setUploadStarted] = useState(false);

    const handleFileChange = (acceptedFiles) => {
        setFiles(acceptedFiles);
        const fileNames = acceptedFiles.map((file) => file.name);
        setSelectedFileNames(fileNames);
    };

    const handleValidation = async () => {
        setProcessing(true);

        if (files.length > maxImageCount) {
            Swal.fire({
                title: 'Se ha superado el número máximo de imágenes',
                text: `Puede subir un máximo de ${maxImageCount} imágenes.`,
                icon: 'warning',
            });
            setProcessing(false);
            return;
        }

        handleUpload();
    };

    const handleUploadSingleFile = async (file) => {
        try {
            const formData = new FormData();
            formData.append(customParam, file);

            const response = await axios.post('/api/images/Upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress(progress);
                },
            });

            // If the response contains an error flag, show the Swal alert
            if (response.data.error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message,
                });
            } else {
                // Upload successful, do something with the response data if needed
                return response.data;
            }

        } catch (error) {
            console.error('Error uploading the image:', error);
            throw error;
        }
    };

    const handleUpload = async () => {
        if (files.length === 0) {
            alert('No ha seleccionado Archivos de imagenes.');
            setProcessing(false);
            return;
        }

        try {
            const totalFilesToProcess = files.length;
            let uploadedFilesCount = 0;
            const uploadedImages = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                try {
                    const resizedFile = await resizeImage(file, 1200);
                    const response = await handleUploadSingleFile(resizedFile);
                    uploadedImages.push(response);

                    uploadedFilesCount++;
                    setUploadProgress(Math.round((uploadedFilesCount / totalFilesToProcess) * 100));

                    if (uploadedFilesCount === totalFilesToProcess) {
                        setUploadProgress(0);
                        onImagesUploaded(uploadedImages);
                        setProcessing(false);
                    }
                } catch (error) {
                    console.error('Error resizing and uploading the image:', error);
                    setProcessing(false);
                }
            }
        } catch (error) {
            console.error('Error processing files:', error);
            setProcessing(false);
        }
    };

    const resizeImage = async (file, maxWidth) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                const img = new Image();
                img.onload = function () {
                    const originalWidth = img.width;
                    const originalHeight = img.height;

                    let newWidth = originalWidth;
                    let newHeight = originalHeight;

                    // Check if the original width is greater than the desired max width
                    if (originalWidth > maxWidth) {
                        // Calculate the new width and height while maintaining aspect ratio
                        const aspectRatio = originalWidth / originalHeight;
                        newWidth = maxWidth;
                        newHeight = newWidth / aspectRatio;
                    }

                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    canvas.toBlob((blob) => {
                        const resizedFile = new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() });

                         //Calculate original and compressed file sizes
                        //const originalSize = file.size;
                        //const compressedSize = resizedFile.size;

                        //console.log(`Original size: ${originalSize} bytes`);
                        //console.log(`Compressed size: ${compressedSize} bytes`);
                        //console.log(`Compression ratio: ${((originalSize - compressedSize) / originalSize) * 100}%`);

                        resolve(resizedFile);
                    }, 'image/jpeg', 0.8);
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        });
    };


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
        },
        multiple: true,
        onDrop: handleFileChange,
    });

    if (customParam === null || customParam === undefined) {
        return <div></div>;
    }

    return (
        <div>
            <div
                {...getRootProps()}
                style={{
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                    padding: '1rem',
                    borderRadius: '4px',
                    backgroundColor: isDragActive ? '#f8f8f8' : 'transparent',
                }}
            >
                <input {...getInputProps()} />
                {selectedFileNames.length === 0 ? (
                    <p>Arrastra y suelta archivos aquí o haz clic para seleccionarlos.</p>
                ) : (
                    <p>Archivos seleccionados: {selectedFileNames.join(', ')}</p>
                )}
            </div>
            <button
                className="btn btn-yellow"
                onClick={() => {
                    setUploadStarted(true);
                    setProcessing(true);
                    handleValidation();
                }}
                disabled={processing}
            >
                Subir Imagen(es)
            </button>
            {processing && <ProgressBar uploadProgress={uploadProgress} />}
            <br />
            <br />
        </div>
    );
};

const ProgressBar = ({ uploadProgress }) => {
    return (
        <div>
            <progress value={uploadProgress} max={100} />
            <span>{`${uploadProgress}%`}</span>
        </div>
    );
};

export default ImagesUpload;
