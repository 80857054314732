import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../context/UserProvider';
import CryptoES from 'crypto-es';
import Swal from 'sweetalert2'

function MiSubscripcion() {
    const { user } = useContext(UserContext);
    

    //const apiKey = '78A10EAF-E35C-465E-BFEC-8BFCF839LBC9'; //DEV
    //const secretKey = '05ed9ae5bdff3d82bb26a00ef7d191e886625000'; // DEV

    const apiKey = '2BF1F6F7-2E10-4CFC-A75B-1L0E9F183FBB'; //PROD
    const secretKey = '54ad5bd8e18918a49cc3ecf85d1c2fa2069d0569'; // PROD

    const [subscriptionData, setSubscriptionData] = useState({
        idPlan: '',
        tarifa: '',
        fechaInicio: '',
        IdSubscripcion: ''
    });

    const [customerId, setcustomerId] = useState("");

    const ObtieneSubscripcion = async () => {
        try {
            const response = await axios.get(`/api/pagosplanes/GetPlanUsuarioId?idUsuario=${user.usuario.idusuario}`);
            const planUsuarioData = response.data;

            if (planUsuarioData) {

                setcustomerId(planUsuarioData.idcliente);

                const paramsGetSubs = { apiKey, customerId: planUsuarioData.idcliente };
                const sortedKeysGetSubs = Object.keys(paramsGetSubs).sort();
                let toSignGetSubs = '';
                sortedKeysGetSubs.forEach(key => toSignGetSubs += key + paramsGetSubs[key]);

                const signature = CryptoES.HmacSHA256(toSignGetSubs, secretKey).toString(CryptoES.enc.Hex);

                const responseGetSubs = await axios.get('/api/pagosplanes/GetSubscription', {
                    params: { apiKey, customerId: planUsuarioData.idcliente, s: signature },
                    headers: { 'Content-Type': 'application/json' },
                });

                if (responseGetSubs.status === 200) {
                    const subscriptions = JSON.parse(responseGetSubs.data).data;

                    if (subscriptions.length > 0) {
                        // Assuming "status" is the property indicating the status of the subscription
                        const activeSubscription = subscriptions.find(subscription => subscription.status === 1);

                        if (activeSubscription) {
                            setSubscriptionData({
                                idPlan: planUsuarioData.idPlan,
                                tarifa: planUsuarioData.tarifa + " UF - Mensual",
                                fechaInicio: activeSubscription.created,
                                IdSubscripcion: activeSubscription.subscriptionId
                            });
                        } else {
                            console.log('No active subscription found.');
                        }
                    } else {
                        console.log('No subscriptions found.');
                    }
                } else {
                    console.log('Error: Unable to fetch subscription data.');
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleCancelSubscription = async (e) => {
        e.preventDefault();
        try {
            const params = {
                apiKey,
                subscriptionId: subscriptionData.IdSubscripcion
            };

            const sortedKeys = Object.keys(params).sort();
            let toSign = '';
            sortedKeys.forEach(key => {
                toSign += key + params[key];
            });

            const signature = CryptoES.HmacSHA256(toSign, secretKey).toString(CryptoES.enc.Hex);

            const formData = {
                apiKey,
                subscriptionId: subscriptionData.IdSubscripcion,
                s: signature
            };

            const response = await axios.post('/api/pagosplanes/CancelSubscription', JSON.stringify(formData), {
                headers: {
                    'Content-Type': 'application/json', // Set the correct content type
                },
            });

            if (response.status === 200) {
                //const params = {
                //    apiKey,
                //    customerId: customerId,
                //};

                //const sortedKeys = Object.keys(params).sort();
                //let toSign = '';
                //sortedKeys.forEach(key => {
                //    toSign += key + params[key];
                //});

                //const signature = CryptoES.HmacSHA256(toSign, secretKey).toString(CryptoES.enc.Hex);

                //const formData = {
                //    apiKey,
                //    customerId: customerId,
                //    s: signature
                //};

                //const response = await axios.post('/api/pagosplanes/DeleteCustomer', JSON.stringify(formData), {
                //    headers: {
                //        'Content-Type': 'application/json', // Set the correct content type
                //    },
                //});

                //if (response.status === 200) {
                    const response = await axios.post(`/api/pagosplanes/BorraPlanUsuario?idUsuario=${user.usuario.idusuario}&idplan=${subscriptionData.idPlan}`);
                if (response.status === 200) {
                        Swal.fire('Existoso!', 'La subscripcion fue cancelada existosamente', 'info');
                    }
            }


        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        ObtieneSubscripcion();
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', backgroundColor: '#f4f4f4' }}>
            <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
                <form onSubmit={handleCancelSubscription}>
                    <div style={{ display: 'grid', gridTemplateColumns: '150px auto', gap: '10px', marginBottom: '10px' }}>
                        <label style={{ alignSelf: 'center' }}>ID de Plan:</label>
                        <input type="text" value={subscriptionData.idPlan} readOnly />
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '150px auto', gap: '10px', marginBottom: '10px' }}>
                        <label style={{ alignSelf: 'center' }}>tarifa:</label>
                        <input type="text" value={subscriptionData.tarifa} readOnly />
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '150px auto', gap: '10px', marginBottom: '10px' }}>
                        <label style={{ alignSelf: 'center' }}>fecha Inicio:</label>
                        <input type="text" value={subscriptionData.fechaInicio} readOnly />
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '150px auto', gap: '10px', marginBottom: '10px' }}>
                        <label style={{ alignSelf: 'center' }}>Id Subscripcion:</label>
                        <input type="text" value={subscriptionData.IdSubscripcion} readOnly />
                    </div>
                   
                    <br />
                    <button
                        type="submit"
                        style={{ backgroundColor: '#e74c3c', color: '#fff', border: 'none', padding: '10px 20px', borderRadius: '4px', cursor: 'pointer', fontSize: '16px' }}
                    >
                        Cancelar Subscripción
                    </button>
                </form>
            </div>
        </div>
    );
}

export default MiSubscripcion;
