import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import BannerMarketing from './section-components/bannermarketing';
import PropiedadesPorCiudad from './section-components/propiedadesxciudad';
import Footer from './global-components/footer';
import ClientesDestacados from './section-components/clientesdestacados';
import PropiedadesRecomendadasUsuario from './section-components/propiedadesrecomendadasusuario';
import Promocionados from './section-components/promocionados';
import { UserContext } from '../context/UserProvider';
import { useContext } from 'react';
import Chatbot from './global-components/chatbot';
import WhatsApp from './global-components/whatsapp';


async function checkIfClienteExists(idcliente, cerrarSesion) {
    try {
        const response = await fetch(`api/clientes/getCliente/${idcliente}`, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        });


        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data && data.length === 0) {
            cerrarSesion();
        }

    } catch (error) {
        console.error("Error checking if idcliente exists:", error);
        cerrarSesion();
    }
}



const Home_V1 = () => {
    const { user, cerrarSesion } = useContext(UserContext);
    


    useEffect(() => {
        if (user && user.idcliente) {
            checkIfClienteExists(user.idcliente, cerrarSesion);
        }
    }, []);


    return (
        <div>
            <Navbar />
            <BannerMarketing />
            <Banner/> {/* Adjust position, z-index, and padding */}
            {/*<Chatbot />*/}
            <Promocionados/>
            <WhatsApp />
            
            {user && user.usuario && (
                <PropiedadesRecomendadasUsuario idUsuario={user.usuario.idusuario} />
            )}
            <PropiedadesPorCiudad />
            <ClientesDestacados />
            <Footer />
        </div>
    );
};

export default Home_V1;
