import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CryptoES from 'crypto-es';
import Swal from 'sweetalert2';

const ResetContrasenaGlobal = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const tokenRef = useRef(null);
    const emailRef = useRef(null);
    const staticSalt = '$2a$10$KF54AyqVH5AzkRn7.972Te';

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        tokenRef.current = searchParams.get('token');
        emailRef.current = searchParams.get('email');
    }, []);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        //const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#$]{8,}$/;


        if (password !== confirmPassword) {
            setPasswordError('Las contraseñas no coinciden');
            return;
        }

        if (!passwordPattern.test(password)) {
            setPasswordError(
                'La contraseña debe tener al menos 8 caracteres y contener al menos una letra minúscula, una letra mayúscula y un número'
            );
            return;
        }

        const hashedPassword = CryptoES.SHA256(password + staticSalt).toString();

        try {
            const request = {
                ResetToken: tokenRef.current,
                Email: emailRef.current,
                NewPassword: hashedPassword,
            };

            const response = await axios.post('/api/usuario/resetcontraseña', request);

            if (response.status === 200) {
                Swal.fire('Exito', 'Contraseña actualizada con exito', 'info');
            } else {
                Swal.fire('Error', 'Error al actualizar la contraseña', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Error al actualizar la contraseña', 'error');
            console.log(error);
        }
    };

    const isPasswordValid =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#$]{8,}$/.test(password) &&
        password === confirmPassword;

    return (
        <div className="register-page-area pd-bottom-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
                        <form className="contact-form-wrap contact-form-bg" onSubmit={handleSubmit}>
                            <h4>Resetear Contraseña</h4>
                            <div className="rld-single-input">
                                <input
                                    type="password"
                                    value={password}
                                    placeholder="Nueva Contraseña"
                                    onChange={handlePasswordChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>
                                    Requisitos de contraseña:
                                    <ul>
                                        <li>Al menos 8 caracteres</li>
                                        <li>Al menos una letra minúscula</li>
                                        <li>Al menos una letra mayúscula</li>
                                        <li>Al menos un número</li>
                                    </ul>
                                </label>
                                {password && (
                                    <p style={{ color: isPasswordValid ? 'green' : 'red' }}>
                                        {isPasswordValid
                                            ? 'La contraseña cumple con los requisitos'
                                            : 'La contraseña no cumple con los requisitos'}
                                    </p>
                                )}
                            </div>
                            <div className="rld-single-input">
                                <input
                                    type="password"
                                    value={confirmPassword}
                                    placeholder="Confirmar Nueva Contraseña"
                                    onChange={handleConfirmPasswordChange}
                                    required
                                />
                            </div>
                            {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
                            <div className="btn-wrap" style={{ display: 'flex', justifyContent: 'center' }}>
                                <button className="btn btn-yellow" type="submit">
                                    Resetear Contraseña
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetContrasenaGlobal;
