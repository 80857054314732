import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { UserContext } from "../../context/UserProvider"

const UsuariosClientes = () => {
    const [usuariosclientes, setUsuariosclientes] = useState([]);
    const [tipo, settipo] = useState('');
    const [email, setEmail] = useState('');
    //const [idcliente, setidcliente] = useState('');
    const [selectedUsuarioCliente, setSelectedUsuarioCliente] = useState(null);
    const [cliente, setCliente] = useState('');
    const { user } = useContext(UserContext);

    useEffect(() => {
        const fetchExisteUsuarioCliente = async () => {
            try {
                const response = await axios.post('api/usuariosclientes/UsuarioResponsableCliente', {
                    email: user.usuario.correo,
                    idcliente: null,
                    tipo: null,
                });

                if (response.status === 200) {
                    setCliente(response.data);
                } else {
                    setCliente(null);
                }
            } catch (error) {
                console.error('Error fetching usuario cliente:', error);
            }
        };
        if (user)
        fetchExisteUsuarioCliente();
    }, []);


    useEffect(() => {
        if (cliente) 
        fetchUsuariosclientes(cliente.idcliente);
    }, [cliente.idcliente, cliente]);

    const fetchUsuariosclientes = async (idcliente) => {
        try {
            const response = await axios.get(`/api/usuariosclientes/getUsuariosclientes/${idcliente}`);
            setUsuariosclientes(response.data);
        } catch (error) {
            console.error('Error fetching usuarios clientes:', error);
        }
    };

    const handleAdd = async () => {
        try {
            const formData = new FormData();
            formData.append('idcliente', cliente.idcliente);
            formData.append('tipo', tipo);
            formData.append('email', email);

            await axios.post('/api/usuariosclientes/AgregaUsuarioCliente', formData);
            settipo('');
            setEmail('');
            fetchUsuariosclientes(cliente.idcliente);
        } catch (error) {
            console.error('Error adding usuarios clientes:', error);
        }
    };

    const handleUpdate = async () => {
        if (!selectedUsuarioCliente) return;

        try {
            const formData = new FormData();
            formData.append('idcliente', cliente.idcliente);
            formData.append('tipo', tipo);
            formData.append('email', selectedUsuarioCliente.email);

            await axios.put('/api/usuariosclientes/updateUsuarioCliente', formData);
            settipo('');
            setEmail('');
            setSelectedUsuarioCliente(null);
            fetchUsuariosclientes(cliente.idcliente);
        } catch (error) {
            console.error('Error updating usuarios cliente:', error);
        }
    };

    const handleDelete = async (idcliente, email) => {
        try {
            await axios.delete(`/api/usuariosclientes/deleteUsuarioCliente/${idcliente}/${email}`);
            fetchUsuariosclientes(idcliente);
        } catch (error) {
            console.error('Error deleting usuarios clientes:', error);
        }
    };


    const handleEdit = (usuariocliente) => {
        setSelectedUsuarioCliente(usuariocliente);
        settipo(usuariocliente.tipo);
        setEmail(usuariocliente.email);
    };

    const handleCancel = () => {
        setSelectedUsuarioCliente(null);
        settipo('');
        setEmail('');
    };

    const gettipoText = (value) => {
        if (value === 1) {
            return 'Administrador';
        } else if (value === 2) {
            return 'Normal';
        } else {
            return '';
        }
    };

    const buttonStyle = {
        width: '100px', // Adjust the width as needed
        height: '40px', // Adjust the height as needed
    };

    return (
        <div className="container">
            <div className="d-flex flex-column align-items-center mt-5">
                <h2>Detalle de Usuarios: {cliente.descripcion }</h2>
                <div className="w-50 border p-4 rounded">
                    <Form onSubmit={selectedUsuarioCliente ? handleUpdate : handleAdd}>
                        <FormGroup>
                            <Label for="tipo">tipo Usuario Cliente</Label>
                            <Input
                                type="select"
                                id="tipo"
                                value={tipo}
                                onChange={(e) => settipo(e.target.value)}
                                required
                            >
                                <option value="">Seleccione un tipo</option>
                                <option value="1">Administrador</option>
                                <option value="2">Normal</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email Usuario</Label>
                            <Input
                                type="text"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email Usuario"
                                required
                            />
                        </FormGroup>
                        <FormGroup className="text-center">
                            <Button type="submit" className="btn btn-yellow">
                                {selectedUsuarioCliente ? 'Actualizar' : 'Agregar'}
                            </Button>
                            {selectedUsuarioCliente && (
                                <Button type="button" onClick={handleCancel} className="ml-2" color="secondary">
                                    Cancelar
                                </Button>
                            )}
                        </FormGroup>
                    </Form>
                </div>
                <ul className="mt-4 w-50 list-group">
                    {usuariosclientes.map((usuariosclientes) => (
                        <li key={usuariosclientes.email} className="list-group-item d-flex align-items-center mb-3">
                            <div className="w-100">
                                <span className="font-weight-bold">tipo:</span> {gettipoText(usuariosclientes.tipo)}<br />
                                <span className="font-weight-bold">Email Usuario:</span> {usuariosclientes.email}
                            </div>
                            <div className="ml-auto">
                                <button
                                    onClick={() => handleEdit(usuariosclientes)}
                                    className="btn btn-yellow"
                                    size="sm"
                                    style={buttonStyle}
                                >
                                    Editar
                                </button>
                                <button
                                    onClick={() => handleDelete(usuariosclientes.idcliente, usuariosclientes.email  )}
                                    className="btn btn-lightblue"
                                    size="sm"
                                    style={buttonStyle}
                                >
                                    Eliminar
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default UsuariosClientes;
