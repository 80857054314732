import React, { useEffect, useState, useRef } from 'react';
import { Navigation, Pagination} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import territorio from '../../data/territoriochile.json';
import { Link } from 'react-router-dom';

function PropiedadesPorCiudad() {
    const [propertyCounts, setPropertyCounts] = useState({});
    const publicUrl = process.env.PUBLIC_URL + '/';

    useEffect(() => {
        const fetchPropertyCounts = async () => {
            const capitalRegionals = [...new Set(territorio.map((item) => item.capital_regional))];
            const response = await fetch('api/propiedades/PropsPorCiudadCount', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(capitalRegionals),
            });

            if (response.ok) {
                const data = await response.json();
                const counts = {};
                if (Array.isArray(data)) {
                    data.forEach((item) => {
                        const { capitalRegional, count } = item;
                        counts[capitalRegional] = count;
                    });
                }
                setPropertyCounts(counts);
            }
        };

        fetchPropertyCounts();
    }, []);

    const sortedTerritories = [...territorio].sort((a, b) => (propertyCounts[b.capital_regional] || 0) - (propertyCounts[a.capital_regional] || 0));

    const swiperRef = useRef(null);

    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    return (
        <div className="author-area pd-top-80 pd-bottom-30">
            <div className="container">
                <div className="text-center">
                    <h4 style={{ color: 'var(--main-color-one)' }}>Explora por ciudad</h4>
                </div>
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        <Swiper
                            ref={swiperRef}
                            spaceBetween={30}
                            slidesPerView={'auto'}
                            navigation={false} 
                            pagination={{ clickable: true }}
                            loop={true}
                            breakpoints={{
                                576: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                992: {
                                    slidesPerView: 3,
                                },
                            }}
                        >
                            {sortedTerritories.map((item, i) => {
                                const capitalRegional = item.capital_regional;
                                const imagen = item.imagen;
                                const propertyCount = propertyCounts[capitalRegional] || 0;

                                if (propertyCount === 0) return null;

                                return (
                                    <SwiperSlide key={i}>
                                        <Link to={'/busquedapropiedades'} state={{ busqueda: 'ciudad-' + capitalRegional }} className="single-service text-center">
                                            <div className="thumb">
                                                <img src={publicUrl + 'assets/img/city/' + imagen} alt="icons" style={{ width: '200px', height: '200px' }} />
                                            </div>
                                            <div className="details readeal-top">
                                                <i className="fa fa-map-marker" style={{ marginRight: '5px' }}></i>
                                                <span>{capitalRegional}</span>
                                            </div>
                                        </Link>
                                        <br></br>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>

                    </div>
                    <div className="custom-navigation" style={{ display: 'flex', justifyContent: 'center' }}>
                        <button className="custom-prev" onClick={handlePrevClick}>
                            <img src="/assets/img/buttons/Buscador-propiedades-landings_Boton-naranja-atrás.png" alt="Left Arrow" />
                        </button>
                        <button className="custom-next" onClick={handleNextClick}>
                            <img src="/assets/img/buttons/Buscador-propiedades-landings_Boton-naranja-adelante.png" alt="Right Arrow" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropiedadesPorCiudad;
