import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { UserContext } from '../../context/UserProvider';


function RenderNavBar() {
    const { user, cerrarSesion } = useContext(UserContext);
    const [responsable, setResponsable] = useState(false);
    const [tieneplan, setTienePlan] = useState(false);


    useEffect(() => {
        let isMounted = true; // Track the mounted state of the component
        const fetchExisteUsuarioCliente = async () => {
            try {
                const response = await axios.post('/api/usuariosclientes/UsuarioResponsableCliente', {
                    email: user.usuario.correo,
                    tipo: null,
                });

                if (isMounted) {
                    // Check if the component is still mounted before updating state
                    if (response.status === 200) {
                        setResponsable(true);
                    } else {
                        setResponsable(false);
                    }
                }
            } catch (error) {
                console.error('Error fetching usuario responsable del cliente:', error);
            }
        };

        if (user && user.usuario)
            fetchExisteUsuarioCliente();

        // Cleanup function to cancel ongoing requests
        return () => {
            isMounted = false;
        };
    }, [user]);


    useEffect(() => {
        const fetchExistePlanSubscripcion = async () => {
            try {

                const response = await axios.get(`/api/pagosplanes/GetPlanUsuarioId?idusuario=${user.usuario.idusuario}`);
                const planUsuarioData = response.data;

                if (planUsuarioData === "Plan Usuario not found") {
                    setTienePlan(false);
                }
                else {
                    setTienePlan(true);
                }
            } catch (error) {
                console.error('Error fetching usuario responsable del cliente:', error);
            }
        };

        if (user && user.usuario)
            fetchExistePlanSubscripcion();
      
    }, []);



    const mostrarModalSalir = () => {
        Swal.fire({
            title: 'Esta por salir',
            text: 'Desea cerrar sesion?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver',
            customClass: {
                container: 'swal-container',
            },
            html: '<style>.swal-container { z-index: 99999; }</style>',
        }).then((result) => {
            if (result.isConfirmed) {
                cerrarSesion();
                //const currentPath = window.location.pathname;
                //const parentPath = currentPath.split('/').slice(0, -1).join('/');
                
            }
        });
    };

    return (
        <div>
            {/*<div style={{*/}
            {/*    backgroundColor: 'var(--main-color-one)',*/}
            {/*    height: '70px',*/}
            {/*    width: '100%',*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*    justifyContent: 'center'*/}
            {/*}}>*/}
            {/*    */}{/* The rectangular div with background color */}
            {/*    <div className="logo readeal-top">*/}
            {/*        <Link to="/">*/}
            {/*            <img src={'/assets/img/Buscador-propiedades-landings_Logo-blanco.png'} alt="logo" style={{ width: '90px', height: '40px' }} />*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="navbar-area" style={{ backgroudColor: 'var(--main-color-one)' }}>
                <nav className="navbar navbar-area navbar-expand-lg">
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu" >
                            <button
                                className="menu toggle-btn d-block d-lg-none"
                                style={{ backgroundColor: 'white' }} 
                                data-toggle="collapse"
                                data-target="#buscadorpropiedades_main_menu"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-left" />
                                <span className="icon-right" />
                            </button>
                        </div>
                        <div className="logo readeal-top">
                            <Link to="/">
                                <img src={'/assets/img/Buscador-propiedades-landings_Logo-blanco.png'} alt="logo" style={{ width: '100px', height: '50px' }} />
                            </Link>
                        </div>
                        <div className="nav-right-part nav-right-part-mobile">
                            {user && (
                                <Link className="btn btn-lightblue" to="/publicarpropiedad">
                                    Publicar
                                    <span className="right">
                                        <i className="la la-plus" />
                                    </span>
                                </Link>
                            )}
                        </div>

                        <div className="collapse navbar-collapse" id="buscadorpropiedades_main_menu">
                            <ul className="navbar-nav menu-open readeal-top"  >
                                {/*<li className=""><Link to="/">Home</Link></li>*/}
                                 <li className="menu-item-has-children">
                                    <a href="/">Empresa</a>
                                    <ul className="sub-menu">
                                        <li><Link to="/About">Quienes Somos</Link></li>
                                        {/*<li><Link to="/preciosplanes">planes de Precios a clientes</Link></li>*/}
                                        <li><Link to="/politicaprivacidad">Política de Privacidad</Link></li>
                                    </ul>
                                </li>
                                {/*<li className="menu-item-has-children">*/}
                                {/*    <a href="/">Noticias</a>*/}
                                {/*    <ul className="sub-menu">*/}
                                {/*        <li><Link to="/news">Noticias</Link></li>*/}
                                {/*        <li><Link to="/news-details">Detalle Noticias</Link></li>*/}
                                {/*    </ul>*/}
                                {/*</li>*/}
                                <li className="menu-item-has-children">
                                    <a href="/">Corredores</a>
                                    <ul className="sub-menu">
                                        <li><Link to="/preciosplanes">Planes Subscripción</Link></li>
                                        <li><Link to="/listaclientes">Lista de Corredores</Link></li>
                                    </ul>
                                </li>
                                <li className=""><Link to="/contacto">Contáctanos</Link></li>
                                <li className=""><Link to="/noticias">Noticias</Link></li>
                                {user && user.usuario && user.icono &&
                                    <li className="menu-item-has-children">
                                        <a href="/" className="nav-user-profile">
                                            <span className="email"> {user.usuario.correo} </span>
                                            <span className="profile-image">
                                                <img width="30px" height="30px" className="img-profile rounded-circle" src={`data:image/jpeg;base64,${user.icono}`} alt="" />
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link to="/Registro" state={{ idusuario: user.usuario.idusuario }}>
                                                    Mi Perfil
                                                </Link>
                                            </li>
                                            {user.usuario.rol === 'SUPERADMIN' && (
                                                <li>
                                                    <Link to="/Clientes">Mantenimiento Clientes</Link>
                                                </li>
                                            )}
                                            {user.usuario.rol === 'SUPERADMIN' && (
                                            <li>
                                                <Link to="/GestionUsuarios">Gestión de Usuarios</Link>
                                            </li>
                                            )}
                                            {responsable && (
                                                <li>
                                                    <Link to={{ pathname: '/UsuariosClientes' }}>
                                                        Mantenimiento Usuarios del Cliente
                                                    </Link>
                                                </li>
                                            )}
                                            <li><Link to="/Mispropiedades">Mis propiedades</Link></li>
                                            <li><Link to="/MisFavoritas">Mis Favoritas</Link></li>
                                            {tieneplan && (
                                                <li><Link to="/MiSubscripcion">Mi Subscripción</Link></li>
                                            )}
                                            <li>
                                                    <button
                                                        className="nav-link"
                                                        onClick={mostrarModalSalir}
                                                        style={{
                                                            padding: '10px 15px',
                                                            color: '#333',
                                                            textDecoration: 'none',
                                                            background: 'none',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <i className="la la-sign-out" style={{ marginRight: '5px' }} />
                                                        Salir
                                                    </button>
                                            </li>

                                        </ul>
                                    </li>
                                }
                                {!user && (
                                    <li className="menu-item-has-children">
                                        <div>
                                            <img width="30px" height="30px" className="img-profile rounded-circle" src={"/assets/img/Foto003.jpg"} alt="" />
                                        </div>
                                        <ul className="sub-menu" style={{ display: 'block' }}> {/* Add inline style to force display */}
                                            {/* Check if the Login and Registro paths are correct */}
                                            <li>
                                                <Link to="/Login">Login</Link>
                                            </li>
                                            <li>
                                                {/* Make sure state is passed correctly to the Registro page */}
                                                <Link to={{ pathname: "/Registro", state: { idusuario: "" } }}>
                                                    Registro
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                                {user && user.usuario && !user.icono && (
                                    <li className="menu-item-has-children">
                                        <a href="/" className="nav-user-profile">
                                            <span className = "email"> {user.usuario.correo} </span>
                                            <span className="profile-image">
                                                <img width="30px" height="30px" className="img-profile rounded-circle" src={"/assets/img/Foto003.jpg"} alt="" />
                                            </span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link to="/Registro" state={{ idusuario: user.usuario.idusuario }}>
                                                    Mi Perfil
                                                </Link>
                                            </li>
                                            {user.usuario.rol === 'SUPERADMIN' && (
                                                <li>
                                                    <Link to="/Clientes">Mantenimiento clientes</Link>
                                                </li>
                                            )}
                                            {responsable && (
                                                <li>
                                                    <Link to={{ pathname: '/UsuariosClientes' }}>
                                                        Mantenimiento Usuarios del Cliente
                                                    </Link>
                                                </li>
                                            )}
                                            <li><Link to="/Mispropiedades">Mis propiedades</Link></li>
                                            <li><Link to="/MisFavoritas">Mis Favoritas</Link></li>
                                            {tieneplan && (
                                                <li><Link to="/MiSubscripcion">Mi Subscripción</Link></li>
                                            )}
                                            <li>
                                                    <button
                                                        className="nav-link"
                                                        onClick={mostrarModalSalir}
                                                        style={{
                                                            padding: '10px 15px',
                                                            color: '#333',
                                                            textDecoration: 'none',
                                                            background: 'none',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <i className="la la-sign-out" style={{ marginRight: '5px' }} />
                                                        Salir
                                                    </button>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                                {user && user.usuario && (
                                    <li className="nav-right-part nav-right-part-desktop readeal-top">
                                        <Link className="btn btn-lightblue" to="/publicarpropiedad">
                                            Publicar
                                            <span className="right">
                                                <i className="la la-plus" />
                                            </span>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

const Navbar = () => {
    let publicUrl = process.env.PUBLIC_URL + '/';
    return RenderNavBar();
};

export default Navbar;
