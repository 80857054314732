import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import MiSubscripcionSection from './section-components/misubscripcion';
import Footer from './global-components/footer';

const MiSubscripcion = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Mi Subscripcion" headerimage="13.png" />
        <MiSubscripcionSection />
        <Footer />
    </div>
}

export default MiSubscripcion

