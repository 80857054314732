import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PublicarpropiedadSection from './section-components/publicarpropiedad';
import Footer from './global-components/footer';

const Publicarpropiedad = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Publicacion de propiedad" headerimage="4.png" />
        <PublicarpropiedadSection />
        <Footer />
    </div>
}

export default Publicarpropiedad

