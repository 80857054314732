import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PagoPlanSection from './section-components/pagoplan';
import Footer from './global-components/footer';

const PagoPlan = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Contratar Plan" headerimage="13.png" />
        <PagoPlanSection />
        <Footer />
    </div>
}

export default PagoPlan

