import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Input, FormGroup, Label, Col } from 'reactstrap';

const fetchPropertyCounts = async (page, itemsPerPage, filterName, filterType) => {
    try {
        const response = await fetch(`api/propiedades/PropsPorClienteCount?page=${page}&itemsPerPage=${itemsPerPage}&filterName=${filterName}&filterType=${filterType}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching property counts:', error);
        throw error;
    }
};

const ClienteCard = React.memo(({ cliente, totalProps }) => {
    const getState = () => ({ busqueda: `idcliente-${cliente.idcliente}` });

    return (
        <div className="single-service text-center">
            <Link
                to="/busquedapropiedades"
                state={getState()}
                style={{ display: 'block', height: '100%' }}
            >
                <div style={{ height: '200px' }}>
                    {cliente.icono && (
                        <img
                            src={`data:image/jpeg;base64,${cliente.icono}`}
                            alt="logo"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                    )}
                </div>
                <div className="details readeal-top">
                    <h4>{cliente.descripcion}</h4>
                    <p># propiedades: {totalProps}</p>
                </div>
            </Link>
        </div>
    );
});

function Listaclientes() {
    const [clientesData, setClientesData] = useState({ items: [], totalCount: 0 });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filterName, setFilterName] = useState('');
    const [filterType, setFilterType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const loadClientes = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await fetchPropertyCounts(currentPage, itemsPerPage, filterName, filterType);
            setClientesData(data);
        } catch (error) {
            setError('Error fetching data. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [currentPage, itemsPerPage, filterName, filterType]);

    useEffect(() => {
        loadClientes();
    }, [loadClientes]);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        if (name === 'filterName') {
            setFilterName(value);
        } else if (name === 'filterType') {
            setFilterType(value);
        }
        setCurrentPage(1);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const clientesWithProperties = clientesData.items.filter(item => item.totalProps > 0);

    return (
        <div className="author-area pd-top-60 pd-bottom-70">
            <div className="container">
                <div className="text-center">
                    <h4 className="">Lista de Corredoras y Inmobiliarias</h4>
                    <br />
                </div>
                <div
                    style={{
                        width: '100%',
                        maxWidth: '600px',
                        margin: '0 auto',
                        border: '1px solid #ccc',
                        padding: '20px',
                        backgroundColor: 'lightgray',
                    }}
                >
                    <FormGroup row>
                        <Label for="filterName" sm={4}>
                            Filtrar por Nombre:
                        </Label>
                        <Col sm={8}>
                            <Input
                                type="text"
                                name="filterName"
                                id="filterName"
                                value={filterName}
                                onChange={handleFilterChange}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="filterType" sm={4}>
                            Filtrar por tipo:
                        </Label>
                        <Col sm={8}>
                            <Input
                                type="select"
                                name="filterType"
                                id="filterType"
                                value={filterType}
                                onChange={handleFilterChange}
                                style={{ width: '100%' }}
                            >
                                <option value="">Todas</option>
                                <option value="1">Corredoras</option>
                                <option value="2">Inmobiliarias</option>
                            </Input>
                        </Col>
                    </FormGroup>
                </div>
                {loading ? (
                    <p>Cargando...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                                gridGap: '20px',
                                marginTop: '20px',
                            }}
                        >
                                {clientesWithProperties.map((item) => (
                                    <ClienteCard key={item.cliente.idcliente} cliente={item.cliente} totalProps={item.totalProps} />
                                ))}
                        </div>
                        <div>
                            <label>Clientes por Página:</label>
                            <select
                                value={itemsPerPage}
                                onChange={(e) => {
                                    setItemsPerPage(Number(e.target.value));
                                    setCurrentPage(1);
                                }}
                            >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                            </select>
                            <ul className="pagination">
                                {Array.from({ length: Math.ceil(clientesData.totalCount / itemsPerPage) }).map(
                                    (_, index) => (
                                        <li key={index + 1} className={currentPage === index + 1 ? 'active' : ''}>
                                            <button className="btn btn-yellow" onClick={() => paginate(index + 1)}>{index + 1}</button>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Listaclientes;