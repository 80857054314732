import React, {useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../../context/UserProvider';
import { useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormatoNumero } from '../global-components/globalfunctions';


const MapComponent = ({ mapCenter, property, google, onNearbyPlacesLoaded, typeTranslations }) => {
    //const [activeTab, setActiveTab] = useState('Educacion');
    const circleOptions = {
        center: new google.maps.LatLng(mapCenter.lat, mapCenter.lng),
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
    };

    const zoomLevel = property && property.direccionaprox ? 17 : 15;

    const handleCircleClick = () => {
        // Handle circle click event here
    };

    const infoWindowPosition = new google.maps.LatLng(mapCenter.lat, mapCenter.lng);

    //const InfoWindowComponent = ({ position, content }) => (
    //    <div style={{ fontSize: '20px', padding: '10px', backgroundColor: '#fff' }}>
    //        {content}
    //    </div>
    //);

    useEffect(() => {
        if (!google) {
            return;
        }

        const mapContainerId = `map-${property.idpropiedad}`;

        const map = new google.maps.Map(document.getElementById(mapContainerId), {
            zoom: zoomLevel,
            center: mapCenter,
        });

        let newCircle = null;
        let newMarker = null;
        let newInfoWindow = null;

        if (property) {
            if (property.direccionaprox) {
                newCircle = new google.maps.Circle({
                    ...circleOptions,
                    radius: 100, // Adjust the radius value as needed
                });
                newCircle.setMap(map);
                newCircle.addListener('click', handleCircleClick);

                newInfoWindow = new google.maps.InfoWindow({
                    position: infoWindowPosition,
                });
                newInfoWindow.setContent(property.direccionaprox);
                newInfoWindow.open(map);
            } else {
                newMarker = new google.maps.Marker({
                    position: mapCenter,
                    map: map,
                });

                newInfoWindow = new google.maps.InfoWindow({
                    content: property.direccion,
                });
                newInfoWindow.open(map, newMarker);
            }
        }

        // Fetch nearby places based on the property's address
        //const service = new google.maps.places.PlacesService(map);

        //const fetchPlaces = async () => {
        //    const results = await Promise.all(
        //        Object.values(typeTranslations).flat().map((type) =>
        //            new Promise((resolve, reject) => {
        //                const request = {
        //                    location: mapCenter,
        //                    radius: 1000, // Adjust the radius as needed
        //                    type: [type],
        //                };

        //                service.nearbySearch(request, (results, status) => {
        //                    if (status === google.maps.places.PlacesServiceStatus.OK) {
        //                        resolve(results.filter(result => result.business_status === "OPERATIONAL"));
        //                    } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        //                        resolve([]); // Return an empty array for no results
        //                    } else {
        //                        reject(status);
        //                    }
        //                });
        //            })
        //        )
        //    );

        //    const top5Places = {};
        //    Object.entries(typeTranslations).forEach(
        //        ([groupName, types]) => {
        //            const placesOfType = results.flat().filter((place) =>
        //                types.includes(place.types[0])
        //            );

        //            if (placesOfType.length === 0) {
        //                top5Places[groupName] = [];
        //                return;
        //            }

        //            const uniquePlaces = [];
        //            const placeIds = new Set();

        //            placesOfType.forEach((place) => {
        //                if (!placeIds.has(place.place_id)) {
        //                    uniquePlaces.push(place);
        //                    placeIds.add(place.place_id);
        //                }
        //            });

        //            const sortedPlaces = uniquePlaces.sort((a, b) => {
        //                const distanceA = google.maps.geometry.spherical.computeDistanceBetween(
        //                    mapCenter,
        //                    a.geometry.location
        //                );
        //                const distanceB = google.maps.geometry.spherical.computeDistanceBetween(
        //                    mapCenter,
        //                    b.geometry.location
        //                );
        //                return distanceA - distanceB;
        //            });

        //            const top5 = sortedPlaces.slice(0, 5);

        //            const placesWithDistance = top5.map((place) => ({
        //                ...place,
        //                distance: google.maps.geometry.spherical.computeDistanceBetween(
        //                    mapCenter,
        //                    place.geometry.location
        //                ),
        //                description: groupName,
        //            }));

        //            top5Places[groupName] = placesWithDistance;
        //        }
        //    );

        //    onNearbyPlacesLoaded(top5Places);
        //};

        //fetchPlaces();

        return () => {
            if (newCircle) {
                newCircle.setMap(null);
            }
            if (newMarker) {
                newMarker.setMap(null);
            }
            if (newInfoWindow) {
                newInfoWindow.close();
            }
        };
    }, [property, mapCenter]);

    return <div id={`map-${property.idpropiedad}`} style={{ width: '100%', height: '100%' }} />;
};



const Details = React.memo(({ property, imageList, mapCenter }) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [nearbyPlaces, setNearbyPlaces] = useState([]);
    const [activeTab, setActiveTab] = useState(Object.keys(nearbyPlaces)[0]);
    const [showMap, setShowMap] = useState(false);

    
    const sliderImageStyles = {
        width: '100%',
        maxHeight: '300px',
        objectFit: 'cover',
    };

    const handleShareIconClick = () => {
        setShowShareOptions(!showShareOptions);
    };

    const handleShareOptionClick = (option) => {
        const propertyUrl = window.location.href;

        if (option === 'whatsapp') {
            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(propertyUrl)}`;
            window.open(whatsappUrl, '_blank');
        } else if (option === 'email') {
            const emailUrl = `mailto:?body=${encodeURIComponent(propertyUrl)}`;
            window.location.href = emailUrl;
        }

        setShowShareOptions(false);
    };

    const openLightbox = (index) => {
        setSelectedImageIndex(index);
    };

    const closeLightbox = () => {
        setSelectedImageIndex(null);
    };

    const images = imageList.map((image) => ({
        src: `data:image/png;base64,${image.filecontent}`,
        thumbnail: `data:image/png;base64,${image.filecontent}`,
        caption: image.fileName,
    }));

    const handleNearbyPlacesLoaded = (results) => {
        setNearbyPlaces(results);
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const typeTranslations = {

        Educacion: ['school', 'university', 'primary_school', 'secondary_school'],
        Transporte: ['transit_station', 'train_station', 'airport', 'bus_station', 'light_rail_station', 'subway_station', 'taxi_stand'],
        Compras: ['supermarket', 'shoe_store', 'pet_store', 'movie_rental', 'liquor_store', 'jewelry_store', 'store', 'shopping_mall', 'beauty_salon', 'bicycle_store', 'library', 'book_store', 'bakery', 'clothing_store', 'convenience_store', 'electronics_store', 'florist', 'furniture_store', 'hardware_store', 'home_goods_store'],
        Salud: ['hospital', 'pharmacy', 'drugstore'],
        Servicios: ['veterinary_care', 'travel_agency', 'synagogue', 'storage', 'roofing_contractor', 'real_estate_agency', 'post_office', 'police', 'plumber', 'physiotherapist', 'parking', 'painter', 'moving_company', 'mosque', 'meal_takeaway', 'meal_delivery', 'locksmith', 'local_government_office', 'lawyer', 'laundry', 'accounting', 'atm', 'bank', 'campground', 'cemetery', 'church', 'city_hall', 'courthouse', 'dentist', 'electrician', 'embassy', 'fire_station', 'funeral_home', 'gym', 'hair_care', 'hindu_temple', 'insurance_agency'],
        Entretenimiento: ['spa', 'night_club', 'movie_theater', 'amusement_park', 'bowling_alley', 'aquarium', 'casino', 'zoo', 'rv_park'],
        Cultura: ['art_gallery', 'museum', 'stadium', 'tourist_attraction'],
        BaresRestaurantes: ['bar', 'cafe', 'restaurant'],
        Servicios_Autos: ['car_dealer', 'car_rental', 'car_repair', 'car_wash'],
        Gasolineras: ['gas_station'],
        Parques: ['park'],
    };

    function SubTitulo(item) {
        let subtitulo = '';
        if (item.tipopropiedad === 2) subtitulo = subtitulo + 'Casa en ';
        if (item.tipopropiedad === 3) subtitulo = subtitulo + 'Departamento en ';
        if (item.tipopropiedad === 4) subtitulo = subtitulo + 'Galpon en ';
        if (item.tipopropiedad === 5) subtitulo = subtitulo + 'Local Comercial en ';
        if (item.tipopropiedad === 6) subtitulo = subtitulo + 'Terreno en ';
        if (item.tipopropiedad === 7) subtitulo = subtitulo + 'Oficina en ';
        if (item.tipopropiedad === 8) subtitulo = subtitulo + 'Parcela en ';
        if (item.tipopropiedad === 9) subtitulo = subtitulo + 'Bodega en ';
        if (item.tipopropiedad === 10) subtitulo = subtitulo + 'Derecho Llave en ';
        if (item.tipopropiedad === 11) subtitulo = subtitulo + 'Estacionamiento en ';
        if (item.tipopublicacion === 1) subtitulo = subtitulo + 'Venta';
        if (item.tipopublicacion === 2) subtitulo = subtitulo + 'Arriendo';
        return subtitulo;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flex: '0 0 auto' }}>
                <div style={{ display: 'flex', justifyContent: 'center', minHeight: '150px' }}>
                        <h4 style={{ margin: '0 auto' }}>
                            <span>{property.titulo}</span>
                        </h4>
                    </div>
                <div className="property-details-slider" style={{ minHeight: '300px' }}>
                    <Swiper
                        modules={[Navigation, Pagination]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={index}>
                                <div className="slider-image-container">
                                    <div className="image-wrapper">
                                        <img
                                            src={image.src}
                                            alt={image.caption}
                                            style={sliderImageStyles}
                                            onClick={() => openLightbox(index)}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    </div>
                    {selectedImageIndex !== null && (
                        <Lightbox
                            mainSrc={images[selectedImageIndex].src}
                            onCloseRequest={closeLightbox}
                            reactModalStyle={{
                                overlay: { zIndex: 9999 },
                                content: { maxWidth: '800px' },
                            }}
                        />
                    )}
                    <div className="property-details-slider-info">
                        <h3>
                        <span style={{
                            color: 'black',
                            display: 'block', // Add display block to span
                            textAlign: 'center', // Center the text
                            padding: '10px', // Add some padding for better spacing
                            whiteSpace: 'normal', // Allow text to wrap
                            wordWrap: 'break-word' // Allow individual words to break
                        }}
                        >{SubTitulo(property)}</span>
                        </h3>
                    </div>
                    <div className="property-details-slider-info">
                    <h3>
                        <span style={{ marginRight: '10px' }}>{property.moneda}</span>
                        {FormatoNumero(property.precioactual)}
                        <del style={{ marginLeft: '30px' }}>{FormatoNumero(property.precioAnterior)}</del>
                    </h3>
                    </div>

                    <div className="widget-social-icons text-center">
                        <FontAwesomeIcon
                            icon={faShareNodes}
                            
                            size="xl"
                            onClick={handleShareIconClick}
                        style={{ cursor: 'pointer', color: '#FDA94F' }}
                        />
                        {showShareOptions && (
                            <div className="share-options">
                                <div
                                    className="share-option"
                                    onClick={() => handleShareOptionClick('whatsapp')}
                                >
                                    <FontAwesomeIcon
                                        icon={faWhatsapp}
                                        size="lg"
                                    style={{ marginRight: '5px', color: '#FDA94F' }}
                                    />
                                    WhatsApp
                                </div>
                                <div className="share-option" onClick={() => handleShareOptionClick('email')}>
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        size="lg"
                                    style={{ marginRight: '5px', color: '#FDA94F' }}
                                    />
                                    Email
                                </div>
                            </div>
                        )}
                        <div className="row pd-top-90">
                            <div className="col-lg-12">
                                <div className="property-info mb-5">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-6">
                                            <div className="single-property-info">
                                                <h5>Habs.</h5>
                                                <p>
                                                    <i className="fa fa-bed" />
                                                    {property.numhabitaciones}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="single-property-info">
                                                <h5>baños</h5>
                                                <p>
                                                    <i className="fa fa-bath" />
                                                    {property.numbaños}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="single-property-info">
                                                <h5>Superf.</h5>
                                                <p>
                                                    <img src={'/assets/img/icons/7.png'} alt="icono" />
                                                    {property.area} Mt2
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="single-property-info">
                                                <h5>Sup. Util.</h5>
                                                <p>
                                                    <img src={'/assets/img/icons/7.png'} alt="icono" />
                                                {property.areaUtil ? property.areaUtil : property.area} Mt2
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="single-property-info">
                                                <h5>Estac.</h5>
                                                <p>
                                                    <i className="fa fa-car" />
                                                    {property.numestacionamientos}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="property-news-single-card style-two border-bottom-yellow">
                                <h4>descripcion</h4>
                                    <div>
                                    {/*<p>{property.descripcion}</p>*/}
                                    <textarea style={{ width: '100%', height: '500px' }} readOnly value={property.descripcion}></textarea>
                                    </div>
                                </div>
                                <div className="property-news-single-card style-two border-bottom-yellow">
                                    <h4>Ubicacion</h4>
                                <div>
                                    {showMap ? (
                                        <div style={{ width: '100%', height: '400px' }}>
                                            {window.google && (
                                                <MapComponent
                                                    mapCenter={mapCenter}
                                                    property={property}
                                                    google={window.google}
                                                    onNearbyPlacesLoaded={handleNearbyPlacesLoaded}
                                                    typeTranslations={typeTranslations}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <img
                                            src={"/assets/img/feature/gmaps.jpg"}
                                            alt="imagenmapa"
                                            onClick={() => setShowMap(true)} // Show map on image click
                                            style={{ width: '100%', height: 'auto', cursor: 'pointer' }} // Inline styles for the image
                                        >
                                        </img>
                                    )}
                                </div>
                            </div>
                            {/*{showMap && (*/}
                            {/*    <div className="property-news-single-card style-two border-bottom-yellow" key={Object.keys(nearbyPlaces).join('_')} style={{ height: '300px', overflow: 'auto' }}>*/}
                            {/*        <h4>Lugares Cercanos</h4>*/}
                            {/*        <div>*/}
                            {/*            <div style={{ width: '100%', height: '300px' }}>*/}
                            {/*                <Nav tabs>*/}
                            {/*                    {Object.keys(nearbyPlaces)*/}
                            {/*                        .filter((group) => nearbyPlaces[group].length > 0)*/}
                            {/*                        .map((group, index) => (*/}
                            {/*                            <NavItem key={index}>*/}
                            {/*                                <NavLink*/}
                            {/*                                    className={classnames({ active: activeTab === group })}*/}
                            {/*                                    style={{*/}
                            {/*                                        fontWeight: activeTab === group ? 'bold' : 'normal',*/}
                            {/*                                        backgroundColor: activeTab === group ? '#f0f0f0' : 'inherit'*/}
                            {/*                                    }}*/}
                            {/*                                    onClick={() => toggleTab(group)}*/}
                            {/*                                >*/}
                            {/*                                    {group}*/}
                            {/*                                </NavLink>*/}
                            {/*                            </NavItem>*/}
                            {/*                        ))}*/}
                            {/*                </Nav>*/}
                            {/*                <TabContent activeTab={activeTab} style={{ height: '300px', overflowY: 'auto' }}>*/}
                            {/*                    {Object.keys(nearbyPlaces)*/}
                            {/*                        .filter((group) => nearbyPlaces[group].length > 0)*/}
                            {/*                        .map((group, index) => (*/}
                            {/*                            <TabPane key={index} tabId={group}>*/}
                            {/*                                <ul style={{ listStyleType: 'none' }}>*/}
                            {/*                                    {nearbyPlaces[group].map((place, idx) => (*/}
                            {/*                                        <li key={idx}>*/}
                            {/*                                            <FontAwesomeIcon icon={faCheckSquare} style={{ marginRight: '5px' }} />*/}
                            {/*                                            {place.name} - {(place.distance / 1000).toFixed(2)} Km*/}
                            {/*                                        </li>*/}
                            {/*                                    ))}*/}
                            {/*                                </ul>*/}
                            {/*                            </TabPane>*/}
                            {/*                        ))}*/}
                            {/*                </TabContent>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                                <div className="property-news-single-card border-bottom-yellow">
                                    <h4>Incluye</h4>
                                    <div className="row">
                                        <div className="col-sm-4">
                                        <ul className="rld-list-style" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                {property.tienepiscina ? (
                                                    <li>
                                                        <i className="fa fa-check" /> Piscina
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <i className="fa fa-uncheck" /> Piscina
                                                    </li>
                                                )}
                                                {property.tienegimnasio ? (
                                                    <li>
                                                    <i className="fa fa-check" /> Gimnasio
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <i className="fa fa-uncheck" /> Gimnasio
                                                    </li>
                                                )}
                                                {property.tienequincho ? (
                                                    <li>
                                                        <i className="fa fa-check" /> Quincho
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <i className="fa fa-uncheck" /> Quincho
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="col-sm-4">
                                                <ul className="rld-list-style" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                {property.tienecalefaccion ? (
                                                    <li>
                                                    <i className="fa fa-check" /> Calefaccion
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <i className="fa fa-uncheck" /> Calefaccion
                                                    </li>
                                                )}
                                                {property.tienecamaras ? (
                                                    <li>
                                                        <i className="fa fa-check" /> Camaras
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <i className="fa fa-uncheck" /> Camaras
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="col-sm-4">
                                                <ul className="rld-list-style" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                {property.tienebodega ? (
                                                    <li>
                                                        <i className="fa fa-check" /> Bodega
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <i className="fa fa-uncheck" /> Bodega
                                                    </li>
                                                )}
                                                {property.tienecanchatenis ? (
                                                    <li>
                                                        <i className="fa fa-check" /> Cancha de Tenis
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <i className="fa fa-uncheck" /> Cancha de Tenis
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
   );
});

const handleVisitsAndContacts = (idpropiedad, tipo) => {

    try {


        // Make a POST request to the API endpoint
        axios.post(`/api/propiedades/ActualizaVisitasyContactos?idpropiedad=${idpropiedad}&tipo=${tipo}`);


        // Handle success (e.g., display a success message)
        console.log(`Successfully updated ${tipo === 1 ? 'visitas' : 'contactos'}.`);
    } catch (error) {
        // Handle error (e.g., display an error message)
        console.error('Error updating visits/contacts:', error);
    }
};

const GuardaVisitasUsuario = (idpropiedad, idusuario) => {

    try {


        // Make a POST request to the API endpoint
        axios.post(`/api/propiedades/ActualizaVisitasUsuario?idpropiedad=${idpropiedad}&idusuario=${idusuario}`);

    } catch (error) {
        // Handle error (e.g., display an error message)
        console.error('Error updating visits/contacts:', error);
    }
};
function Compararpropiedades() {
    const { idpropiedad1, idpropiedad2 } = useParams();
    const [property1, setProperty1] = useState(null);
    const [property2, setProperty2] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imageList1, setImageList1] = useState([]);
    const [imageList2, setImageList2] = useState([]);
    const [mapCenter1, setMapCenter1] = useState({ lat: 0, lng: 0 });
    const [mapCenter2, setMapCenter2] = useState({ lat: 0, lng: 0 });

    const { user } = useContext(UserContext);

    // Scroll to the top when the page is loaded
    window.scrollTo(0, 0);


    useEffect(() => {
        const fetchProperty = async (idpropiedad, setProperty, setMapCenter) => {
            try {
                const response = await axios.get(`/api/propiedades/ObtieneDetProp/${idpropiedad}`);
                const property = response.data;
                setProperty(property[0]);
                setMapCenter({ lat: property[0].latitud, lng: property[0].longitud });
                setLoading(false);
                handleVisitsAndContacts(property[0].idpropiedad, 1);
                if (user.usuario) GuardaVisitasUsuario(property[0].idpropiedad, user.usuario.idusuario);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        if (idpropiedad1) {
            fetchProperty(idpropiedad1, setProperty1, setMapCenter1);
        }

        if (idpropiedad2) {
            fetchProperty(idpropiedad2, setProperty2, setMapCenter2);
        }
    }, [idpropiedad1, idpropiedad2]);

    const fetchImages = async (idpropiedad, setImageList) => {
        try {
            const response = await fetch(`/api/images/ObtieneImagenes/${idpropiedad}`);
            const data = await response.json();
            setImageList(data);
        } catch (error) {
            console.log('Error fetching images:', error);
        }
    };

    useEffect(() => {
        if (property1) {
            fetchImages(property1.idpropiedad, setImageList1);
        }

        if (property2) {
            fetchImages(property2.idpropiedad, setImageList2);
        }
    }, [property1, property2]);


    if (loading || !property1 || !property2) {
        return <p></p>;
    }

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, padding: '20px', border: '1px solid #ccc', maxWidth: '50%', height: '100%' }}>
                <Details property={property1} imageList={imageList1} mapCenter={mapCenter1} />
            </div>
            <div style={{ flex: 1, padding: '20px', border: '1px solid #ccc', maxWidth: '50%', height: '100%' }}>
                <Details property={property2} imageList={imageList2} mapCenter={mapCenter2} />
            </div>
        </div>
    );

}

export default Compararpropiedades;
