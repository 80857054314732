import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { useState } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';

const Contacto = () => {
	const [mensaje, setMensaje] = useState('');
	const [nombrecompleto, setNombreCompleto] = useState('');
	const [email, setEmail] = useState('');

	const sendEmail = async (mensaje) => {
		try {

			if (!email || !nombrecompleto) {
				Swal.fire('Aviso', 'Debe llenar la casilla nombre completo y email', 'info');
				return;
			}

			const emailRequest = {
				correofrom: email,
				correoto: "contacto@buscadorpropiedades.cl",
				mensaje: mensaje,
				nombrecontacto: nombrecompleto
			};

			const response = await axios.post('/api/propiedades/EnviaEmailContacto', emailRequest);

			if (response.status === 200) {
				Swal.fire('Aviso', 'Correo de contacto enviado exitosamente', 'info');
			}
			else {
				Swal.fire('Error', 'Correo de contacto no fue enviado', 'error');
			}

		} catch (error) {
			console.error(error);
			Swal.fire('Error', 'Correo de contacto no fue enviado', 'error');
			// Handle error
		}
	};

	const handleMensaje = (event) => {
		event.preventDefault();  // Prevent the default form submission
		sendEmail(mensaje);
	};


    return <div>
        <Navbar />
		<PageHeader headertitle="Contacto" headerimage="8.png" />
        <div className="contact-area pd-top-100 pd-bottom-65">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-8">
		        <div className="contact-page-map">
					<img
						src="/assets/img/bg/Banners-Contacto.jpg"
						alt=""
						style={{ width: '100%', height: '300px', marginTop: '50px' }}
					/>
		        </div>
		      </div>
		      <div className="col-lg-4">
				<form className="contact-form-wrap contact-form-bg" onSubmit={handleMensaje}>
		          <h4>Contactanos Ahora</h4>
		          <div className="rld-single-input">
					<input type="text" placeholder="Nombre"
						onChange={(event) => {
						setNombreCompleto(event.target.value);
					}}
					/>
		          </div>
		          <div className="rld-single-input">
					<input type="text" placeholder="Correo" 
						onChange={(event) => {
							setEmail(event.target.value);
					}}
					/>
		          </div>
		          {/*<div className="rld-single-input">*/}
		          {/*  <input type="text" placeholder="Phone" />*/}
		          {/*</div>*/}
		          <div className="rld-single-input">
					<textarea rows={10} placeholder="Mensaje" defaultValue={""}
					onChange={(event) => {
						setMensaje(event.target.value);
					}}
					/>
		          </div>
		          <div className="btn-wrap text-center">
						<button className="btn btn-yellow" type="submit">Enviar Mensaje</button>
		          </div>
		        </form>
		      </div>
		    </div>
		    <div className="row pd-top-92">
		      <div className="col-xl-3 col-sm-6">
		        <div className="single-contact-info">
		          <p><i className="fa fa-phone" />Llamanos:</p>
		          <h5>(569) 87739172</h5>
		        </div>
		      </div>
		      {/*<div className="col-xl-3 col-sm-6">*/}
		      {/*  <div className="single-contact-info">*/}
		      {/*    <p><i className="fa fa-fax" />Fax:</p>*/}
		      {/*    <h5>(000) 111 222 333</h5>*/}
		      {/*  </div>*/}
		      {/*</div>*/}
		      <div className="col-xl-3 col-sm-6">
		        <div className="single-contact-info">
		          <p><i className="fa fa-envelope" />Tienes Preguntas?</p>
		          <h5>contacto@buscadorpropiedades.cl</h5>
		        </div>
		      </div>
		      <div className="col-xl-3 col-sm-6">
		        <div className="single-contact-info">
		          <p><i className="fa fa-phone" />direccion</p>
		          <h5>Pasaje La Llaveria 1162,</h5>
		          <h5>Vitacura, Santiago, Chile</h5>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
        <Footer />
    </div>
}

export default Contacto

