import { useState, useEffect, useContext } from "react";
import { createContext } from "react";
import { UserContext } from "../context/UserProvider";
import Swal from 'sweetalert2';

const PropiedadContext = createContext();

const PropiedadProvider = ({ children }) => {
    const [customParam, setCustomParam] = useState(null);
    const { user } = useContext(UserContext);

    useEffect(() => {
        const ObtieneIdpropiedadBD = async () => {
            let response;

            if (user === null) {
                Swal.fire('No se puede publicar una propiedad sin usuario registrado', 'information');
            } else {
                let request = {
                    idusuario: user.usuario.idusuario,
                    titulo: "",
                    estadopublicacion: "Pendiente"
                };

                response = await fetch("api/propiedades/Guardar", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(request)
                });

                const result = await response.json();

                if (response.ok) {
                    setCustomParam(result.idpropiedad);
                } else {
                    Swal.fire('Error', 'No se pudo guardar la propiedad', 'error');
                }
            }
        };

        const fetchData = async () => {
            let request = {
                estadopublicacion: "Pendiente",
                idusuario: user.usuario.idusuario
            };

            try {
                let response = await fetch("api/propiedades/ExisteIdPropiedad", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(request)
                });

                const result = await response.json();

                if (response.status === 500 && customParam === null) {
                    ObtieneIdpropiedadBD();
                }
                setCustomParam(result.idpropiedad);
            } catch (ex) {
                console.log(ex);
            }
        };

        if (user) {
            fetchData();
        }
    }, [customParam, user]);

    return (
        <PropiedadContext.Provider value={customParam}>
            {children}
        </PropiedadContext.Provider>
    );
};

export { PropiedadProvider, PropiedadContext };
