import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const Toolbar = ({
    handleBuscarpropiedades,
    handleBuscarMapa,
    handleSortChange,
    viewDropdownOpen,
    sortDropdownOpen,
    toggleViewDropdown,
    settipoVista,
    perPageDropdownOpen,
    toggleSortDropdown,
    togglePerPageDropdown,
    handlePropertiesPerPageChange,
    handleCompareClick,
    searchPerformed
}) => {
    const buttonRef = useRef(null);

    useEffect(() => {
        if (!searchPerformed) {
            buttonRef.current.style.animation = 'floatAnimation 1s infinite alternate';
         } else {
            buttonRef.current.style.animation = 'none';
        }
    }, [searchPerformed]);

    return (
        <div className="fixed-toolbar" style={{ top: '80px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', marginBottom: '10px', position: 'relative' }}>
                <button
                    ref={buttonRef}
                    className={`btn btn-yellow btn-sm mb-2 mr-3 ${!searchPerformed ? 'highlight' : ''}`}
                    style={{ fontSize: '0.9rem', minWidth: '7rem' }}
                    onClick={handleBuscarpropiedades}
                >
                    ¡Buscar Aqui!
                </button>
                <button className="btn btn-yellow btn-sm mb-2 mr-3" style={{ fontSize: '0.7rem', minWidth: '7rem' }} onClick={handleBuscarMapa}>
                    Buscar en Mapa
                </button>
                <button className="btn btn-yellow btn-sm mb-2 mr-3" style={{ fontSize: '0.7rem', minWidth: '7rem' }} onClick={handleCompareClick}>
                    Comparar Props.
                </button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Dropdown isOpen={sortDropdownOpen} toggle={toggleSortDropdown} className="mr-3 mb-3">
                    <DropdownToggle caret color="primary" size="sm" style={{ fontSize: '0.7rem', minWidth: '7rem' }}>
                        Orden Datos
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem value={1} onClick={handleSortChange}>Recientes</DropdownItem>
                        <DropdownItem value={2} onClick={handleSortChange}>Precio Asc</DropdownItem>
                        <DropdownItem value={3} onClick={handleSortChange}>Precio Desc</DropdownItem>
                        <DropdownItem value={4} onClick={handleSortChange}>Tamaño Asc</DropdownItem>
                        <DropdownItem value={5} onClick={handleSortChange}>Tamaño Desc</DropdownItem>
                    </DropdownMenu>
                </Dropdown>

                <Dropdown isOpen={viewDropdownOpen} toggle={toggleViewDropdown} className="mr-3 mb-3">
                    <DropdownToggle caret color="primary" size="sm" style={{ fontSize: '0.7rem', minWidth: '7rem' }}>
                        tipo Vista
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem value={1} onClick={(event) => settipoVista(event.target.value)}>Listado</DropdownItem>
                        <DropdownItem value={2} onClick={(event) => settipoVista(event.target.value)}>Grid</DropdownItem>
                    </DropdownMenu>
                </Dropdown>

                <Dropdown isOpen={perPageDropdownOpen} toggle={togglePerPageDropdown} className="mr-3 mb-3">
                    <DropdownToggle caret color="primary" size="sm" style={{ fontSize: '0.7rem', minWidth: '7rem' }}>
                        # Props x Pag
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem value={10} onClick={handlePropertiesPerPageChange}>10</DropdownItem>
                        <DropdownItem value={20} onClick={handlePropertiesPerPageChange}>20</DropdownItem>
                        <DropdownItem value={50} onClick={handlePropertiesPerPageChange}>50</DropdownItem>
                        <DropdownItem value={100} onClick={handlePropertiesPerPageChange}>100</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};

export default Toolbar;
