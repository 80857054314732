import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const BannerMarketing = () => {
    const banners = [
        {
            imageUrl: '/assets/img/banner/Buscador-propiedades-landings_Banner-Home-buscador-desktop.jpg',
            linkUrl: '/busquedapropiedades',
            altText: 'Slide 1',
            stateData: { busqueda: 'PromocionadosBanner' }
        },
        {
            imageUrl: '/assets/img/banner/BP-banner-home-REGISTRARSE.jpg',
            linkUrl: '/registro',
            altText: 'Slide 2',
            stateData: {}
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % banners.length);
        }, 10000);

        return () => {
            clearInterval(intervalId);
        };
    }, [banners.length]);

    return (
        <div>
            {/* Banner section with registration message */}
            {/*<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '100px' }}>*/}
            {/*    <h6 style={{ fontWeight: 'bold' }}>*/}
            {/*        Saca el máximo de la IA en tu busqueda, !Regístra tu usuario <Link to="/registro">Aquí¡</Link>*/}
            {/*    </h6>*/}
            {/*</div>*/}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '100px' }}>
                <h6 style={{ fontWeight: 'bold' }}>
                   !Si quieres publicar tu propiedad es GRATIS¡
                </h6>
            </div>
            
            {/* Carousel container */}
            <div style={{ position: 'relative', width: '100%', height: 'auto', overflow: 'hidden', marginTop: '20px' }}>
                {banners.map((banner, index) => (
                    <div
                        key={index}
                        style={{
                            display: index === currentSlide ? 'block' : 'none',
                            width: '100%',
                            position: 'relative',
                            paddingBottom: '56%' // Maintain aspect ratio (adjust as needed)
                        }}
                    >
                        {banner.linkUrl.startsWith('http') ? (
                            <a href={banner.linkUrl} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={banner.imageUrl}
                                    alt={banner.altText}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                    }}
                                />
                            </a>
                        ) : (
                            <Link to={{ pathname: banner.linkUrl, state: banner.stateData }}>
                                <img
                                    src={banner.imageUrl}
                                    alt={banner.altText}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                    }}
                                />
                            </Link>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BannerMarketing;
