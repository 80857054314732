import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

const fetchclientes = async (setClientes) => {
    try {
        const response = await fetch('api/propiedades/PropsPorCliente?limitarRegistros=true', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch property counts: ${response.statusText}`);
        }

        const clientes = await response.json();

        if (!Array.isArray(clientes)) {
            throw new Error('Invalid response data format');
        }

        // Filter out clientes where Cliente is null
        const filteredclientes = clientes.filter((cliente) => cliente.cliente !== null);

        // Update the state with filtered clientes directly
        setClientes(filteredclientes);
    } catch (error) {
        console.error('Error cargando corredores destacados:', error.message);
        // Handle error state or display appropriate error message to the user
    }
};



function ClientesDestacados() {
    const [clientes, setClientes] = useState([]);

    // Scroll to the top when the page is loaded
    //window.scrollTo(0, 0);

    useEffect(() => {
        fetchclientes(setClientes);
    }, []);

    const swiperRef = useRef(null);

    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    return (
        <div className="author-area pd-top-80 pd-bottom-20" style={{ backgroundImage: 'url("assets/img/banner/4.png' }}>
            <div className="container">
                <div className="text-center">
                    <br></br>
                    <br></br>
                    <h4 className="" style={{ color: 'var(--main-color-one)' }}>Corredores Destacados</h4>
                </div>
                {clientes.length > 0 && (
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            <Swiper
                                ref={swiperRef}
                                spaceBetween={20}
                                slidesPerView={'auto'}
                                navigation={false} 
                                
                                loop={true}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    992: {
                                        slidesPerView: 4,
                                    },
                                }}
                            >
                                {clientes.map((clienteData) => {
                                    const clienteName = clienteData.descripcion;
                                    const clienteLogo = clienteData.icono;
                                    const idcliente = clienteData.idcliente;

                                    const getState = () => ({ busqueda: 'idcliente-' + idcliente });

                                    return (
                                        <SwiperSlide key={idcliente}>
                                            <div className="single-service text-center">
                                                <Link
                                                    to="/busquedapropiedades"
                                                    state={getState()}
                                                    style={{ display: 'block', height: '100%' }}
                                                >
                                                    <div className="thumb" style={{ height: '70px' }}>
                                                        {clienteLogo && (
                                                            <img
                                                                src={`data:image/jpeg;base64,${clienteLogo}`}
                                                                alt="logo"
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'contain',
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="details readeal-top">
                                                        <h4>{clienteName}</h4>
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                                    </Swiper>
                        </div>
                        <div className="custom-navigation" style={{ display: 'flex', justifyContent: 'center' }}>
                            <button className="custom-prev" onClick={handlePrevClick}>
                                <img src="/assets/img/buttons/Buscador-propiedades-landings_Boton-naranja-atrás.png" alt="Left Arrow" />
                            </button>
                            <button className="custom-next" onClick={handleNextClick}>
                                <img src="/assets/img/buttons/Buscador-propiedades-landings_Boton-naranja-adelante.png" alt="Right Arrow" />
                            </button>
                        </div>

                    </div>
                )}
                <Link
                    to={'/listaclientes'}
                    style={{ color: 'var(--main-color-one)', fontWeight: 'bold' }}
                >
                    <h6 style={{ color: 'var(--main-color-one)', fontWeight: 'bold' }}>Ver Todas</h6>
                </Link>

            </div>
            </div>
    );
}

export default ClientesDestacados;
