import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../context/UserProvider';
import { useContext } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import CryptoES from 'crypto-es';

// Define a static salt value
const staticSalt = '$2a$10$KF54AyqVH5AzkRn7.972Te';

const Login = () => {
    const [correo, setCorreo] = useState('');
    const [clave, setClave] = useState('');
    const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
    const [resetEmailSent, setResetEmailSent] = useState(false);
    const { user, iniciarSesion } = useContext(UserContext);
    const maxAttempts = 3; // Maximum number of login attempts before lockout
    const [loginAttempts, setLoginAttempts] = useState(0); // Track the number of login attempts


    const handleSubmit = (event) => {
        event.preventDefault();

        if (!resetEmailSent) {
            try {
                const hashedPassword = CryptoES.SHA256(clave + staticSalt).toString();

                let request = {
                    correo: correo.toLowerCase(),
                    clave: hashedPassword,
                };

                axios
                    .post('api/session/Login', request, {
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8',
                        },
                    })
                    .then((response) => {
                        const dataJson = response.data;

                        if (dataJson.idUsuario === 0) {
                            Swal.fire('Opps!', 'No se encontró el usuario', 'error');
                        } else {
                            iniciarSesion(dataJson);
                        }
                    })
                    .catch((error) => {
                        if (error.response.status.toString() === "403") {
                            Swal.fire('Opps!', 'No se pudo iniciar sesión, el usuario esta bloqueado. Intente recuperar la contraseña o contacte a BuscadorPropiedades', 'error');
                        }
                        else {
                            const remainingAttempts = handleFailedLogin(); // Increment the login attempts and check for lockout
                            Swal.fire('Opps!', `No se pudo iniciar sesión. Te quedan ${remainingAttempts} intento(s).`, 'error');
                        }
                    });
            } catch (error) {
                const remainingAttempts = handleFailedLogin(); // Increment the login attempts and check for lockout
                Swal.fire('Opps!', `No se pudo iniciar sesión. Te quedan ${remainingAttempts} intento(s).`, 'error');
            }
        }
    };

    const handleResetEmailClick = async () => {
        if (correo) {
            try {
                // Verify if the email exists in the database
                const existeUsuarioResponse = await fetch('api/usuario/ExisteUsuario', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ Email: correo }),
                });
                if (existeUsuarioResponse.ok) {
                    Swal.fire({
                        title: '¿Estás seguro?',
                        text: 'Se enviará un correo de recuperación de contraseña a tu dirección de correo electrónico.',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sí, enviar correo',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // User confirmed, proceed with sending the email
                            try {
                                axios.post('/api/usuario/olvidocontraseña', { email: correo });
                                setResetEmailSent(true);
                            } catch (error) {
                                Swal.fire('Opps!', 'No se pudo enviar el correo de recuperación', 'error');
                            }
                        }
                    });
                } else {
                    Swal.fire('Opps!', 'El correo electrónico ingresado no existe en nuestra base de datos.', 'error');
                }
            } catch (error) {
                Swal.fire('Opps!', 'No se pudo verificar el correo electrónico', 'error');
            }
        }
    };


    const handleOpenPasswordRecovery = () => {
        setShowPasswordRecovery(true);
    };

    const handleGoToLogin = () => {
        setResetEmailSent(false);
        setShowPasswordRecovery(false);
    };

    const handleFailedLogin = () => {
        const remainingAttempts = maxAttempts - loginAttempts - 1;
        setLoginAttempts((prevAttempts) => prevAttempts + 1); // Increment login attempts
        if (loginAttempts + 1 >= maxAttempts) {
            // Lock the account
            try {
                axios.put(`/api/usuario/bloquearcuenta/${correo}`)
            } catch (error) {
                Swal.fire('Opps!', 'No se pudo bloquear la cuenta', 'error');
            }
        }

        return remainingAttempts;
    };


    if (user && user.usuario != null) {
        return <Navigate to="/" />;
    }

    return (
        <div className="register-page-area pd-bottom-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
                        {!resetEmailSent && !showPasswordRecovery ? (
                            <form className="contact-form-wrap contact-form-bg" onSubmit={handleSubmit}>
                                <h4>Datos de Usuario</h4>
                                <div className="rld-single-input">
                                    <input
                                        type="text"
                                        placeholder="Ingrese Correo"
                                        value={correo}
                                        onChange={(e) => setCorreo(e.target.value)}
                                    />
                                </div>
                                <div className="rld-single-input">
                                    <input
                                        type="password"
                                        placeholder="Ingrese Contraseña"
                                        value={clave}
                                        onChange={(e) => setClave(e.target.value)}
                                    />
                                </div>
                                {!resetEmailSent && !showPasswordRecovery && (
                                    <div className="btn-wrap" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button className="btn btn-yellow" style={{ width: '100%', marginTop: '15px !important' }} type="submit">
                                            Ingresar
                                        </button>
                                    </div>
                                )}
                                <div className="password-recovery-link" style={{ display: 'flex', justifyContent: 'center' }}>
                                    {/* Simulated link */}
                                    <button onClick={handleOpenPasswordRecovery} className="link-button"
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: '#007bff',
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            padding: 0,
                                        }}
                                    >
                                        ¿Olvidaste tu contraseña?
                                    </button>
                                </div>
                            </form>
                        ) : resetEmailSent ? (
                                <div className="password-reset-confirmation" style={{ zIndex: 9999, position: 'relative', backgroundColor: 'white', padding: '10px' }}>
                                    <p>un correo fue enviado a {correo} para resetear la contraseña. Favor revise su correo.</p>
                                </div>
                        ) : (
                            <div className="contact-form-wrap contact-form-bg">
                                <h4>Recuperar Contraseña</h4>
                                <div className="rld-single-input">
                                    <input
                                        type="text"
                                        placeholder="Ingrese Correo"
                                        value={correo}
                                        onChange={(e) => setCorreo(e.target.value)}
                                    />
                                </div>
                                <div className="btn-wrap" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button className="btn btn-yellow btn-full-width" onClick={handleResetEmailClick}>
                                        Enviar Correo de Recuperación
                                    </button>
                                </div>
                                <div className="btn-wrap" style={{ display: 'flex', justifyContent: 'center' }}>
                                    {/* Simulated link */}
                                    <button onClick={handleGoToLogin} className="link-button">
                                        Volver al Inicio de Sesión
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
