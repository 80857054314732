export function FormatoNumero(number) {
    if (number) {
        // Check if the number has decimal places
        const hasDecimal = number % 1 !== 0;

        // Convert the number to a string with a fixed decimal separator
        const formattedNumber = hasDecimal ? number.toFixed(2) : number.toString();

        // Split the number into integer and decimal parts
        const [integerPart, decimalPart] = formattedNumber.split('.');

        // Add dots as thousands separators to the integer part
        const integerWithDots = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Combine the integer and decimal parts with a comma
        const result = hasDecimal ? `${integerWithDots},${decimalPart}` : integerWithDots;

        return result;
    }
    else {
        return 0;
    }
}