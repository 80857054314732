import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import MispropiedadesSection from './section-components/mispropiedades';
import Footer from './global-components/footer';

const Mispropiedades = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Mis propiedades" headerimage="10.jpg" />
        <MispropiedadesSection />
        <Footer />
    </div>
}

export default Mispropiedades

