import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { UserContext } from "../../context/UserProvider"
import CryptoES from 'crypto-es';
import Swal from 'sweetalert2'



function PagoPlan() {
    const { user } = useContext(UserContext);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [rutNumber, setRutNumber] = useState('');
    const [rutVerifier, setRutVerifier] = useState('');
    const [rutError, setRutError] = useState('');

    let nombrePlan = queryParams.get('name');
    const price = queryParams.get('price') + " - Mensual";
    let preciouf = queryParams.get('price').replace("UF", "");
    preciouf = preciouf.replace("(IVA INCLUIDO)", "");
    preciouf = preciouf.replace(",", ".");


    const sendEmail = async (mensaje) => {
        try {


            const emailRequest = {
                correofrom: "contacto@buscadorpropiedades.cl",
                correoto: "contacto@buscadorpropiedades.cl",
                mensaje: mensaje,
                nombrecontacto: "Sistema de Aviso Automatico"
            };

            const response = await axios.post('/api/propiedades/EnviaEmailContrato', emailRequest);

            if (response.status === 200) {
                console.log ('Correo de contacto enviado exitosamente');
            }
            else {
                console.log ('Correo de contacto no fue enviado');
            }

        } catch (error) {
            console.error(error);
            // Handle error
        }
    };

    const handleCreateCustomerAndSubscription = async () => {
        try {
            //const apiKey = '78A10EAF-E35C-465E-BFEC-8BFCF839LBC9'; //DEV
            //const secretKey = '05ed9ae5bdff3d82bb26a00ef7d191e886625000'; // DEV

            const apiKey = '2BF1F6F7-2E10-4CFC-A75B-1L0E9F183FBB'; //PROD
            const secretKey = '54ad5bd8e18918a49cc3ecf85d1c2fa2069d0569'; // PROD

            let CustomerId = "";
            let IdCupon = "";

            const responsecliente = await axios.get(`/api/clientes/getCliente/${user.idcliente}`);
            const clienteTargetData = responsecliente.data; // Assuming the response is an object with icono and nombre fields

            if (clienteTargetData[0].descuento && clienteTargetData[0].descuento === 40) {
                IdCupon = 4303; //PROD
                //IdCupon = 586; //TEST
            }
            else if (clienteTargetData[0].descuento && clienteTargetData[0].descuento === 20) {
                IdCupon = 4348; //PROD
                //IdCupon = 678; //TEST
            }


            const response = await axios.get(`/api/pagosplanes/GetPlanUsuario?idPlan=${nombrePlan + "1"}&idUsuario=${user.usuario.idusuario}`);
            const planUsuarioData = response.data;

            CustomerId = response.data.idcliente;

            if (planUsuarioData === "PlanUsuario not found") {

                const paramsList = {
                    apiKey,
                    filter: user.usuario.nombre,
                    status: 1
                };

                const sortedKeysList = Object.keys(paramsList).sort();
                let toSignList = '';
                sortedKeysList.forEach(key => {
                    toSignList += key + paramsList[key];
                });

                const signatureList = CryptoES.HmacSHA256(toSignList, secretKey).toString(CryptoES.enc.Hex);

                const responseList = await axios.get('/api/pagosplanes/GetCustomerList', {
                    params: {
                        apiKey,
                        filter: user.usuario.nombre,
                        status: 1,
                        s: signatureList
                    },
                    headers: {
                        'Content-Type': 'application/json', // Set the correct content type
                    },
                });


                const CustomerList = JSON.parse(responseList.data).data;

                console.log('Customer List retrieved successfully:', CustomerList);

                if (CustomerList.length === 0) {

                    const params = {
                        apiKey,
                        name: user.usuario.nombre,
                        email: user.usuario.correo,
                        externalId: rutNumber + rutVerifier,
                    };

                    const sortedKeys = Object.keys(params).sort();
                    let toSign = '';
                    sortedKeys.forEach(key => {
                        toSign += key + params[key];
                    });

                    const signature = CryptoES.HmacSHA256(toSign, secretKey).toString(CryptoES.enc.Hex);

                    const formData = {
                        apiKey: apiKey,
                        email: user.usuario.correo,
                        externalId: rutNumber + rutVerifier,
                        name: user.usuario.nombre,
                        s: signature
                    };

                    const response = await axios.post('/api/pagosplanes/CreateCustomer', JSON.stringify(formData), {
                        headers: {
                            'Content-Type': 'application/json', // Set the correct content type
                        },
                    });

                    const parsedData = JSON.parse(response.data);

                    CustomerId = parsedData.customerId;
                    console.log('Customer created successfully:', response.data);
                }

                if (CustomerList.length > 0 && CustomerList[0].customerId) {
                    CustomerId = CustomerList[0].customerId;
                }

                var maxProps;

                const response = await axios.get(`/api/pagosplanes/GetPlan?idPlan=${nombrePlan + "1"}`);
                const planData = response.data;

                if (planData === "Plan no existe") {
                    maxProps = 15;
                }
                else
                {
                    maxProps = planData.maxProps;
                }

                const formDataCliente = {
                    idplan: nombrePlan + "1",
                    idusuario: user.usuario.idusuario,
                    idcliente: CustomerId,
                    tarifa: parseFloat(preciouf.trim()),
                    maxprops: maxProps
                };

                if (planUsuarioData === "PlanUsuario not found") await axios.post('/api/pagosplanes/CreatePlanUsuario', formDataCliente);

            }
            else {
                const params = {
                    apiKey,
                    customerId: CustomerId,
                };

                const sortedKeys = Object.keys(params).sort();
                let toSign = '';
                sortedKeys.forEach(key => {
                    toSign += key + params[key];
                });

                const signature = CryptoES.HmacSHA256(toSign, secretKey).toString(CryptoES.enc.Hex);

                const response = await axios.get('/api/pagosplanes/GetCustomer', {
                    params: {
                        apiKey,
                        customerId: CustomerId,
                        s: signature,
                    },
                    headers: {
                        'Content-Type': 'application/json', // Set the correct content type
                    },
                });

                const parsedData = JSON.parse(response.data);

                console.log('Customer retrieved successfully:', parsedData);
                CustomerId = parsedData.customerId;

            }

            const paramsGetSubs = {
                apiKey,
                customerId: CustomerId,
            };

            const sortedKeysGetSubs = Object.keys(paramsGetSubs).sort();
            let toSignGetSubs = '';
            sortedKeysGetSubs.forEach(key => {
                toSignGetSubs += key + paramsGetSubs[key];
            });

            const signature = CryptoES.HmacSHA256(toSignGetSubs, secretKey).toString(CryptoES.enc.Hex);

            const responseGetSubs = await axios.get('/api/pagosplanes/GetSubscription', {
                params: {
                    apiKey,
                    customerId: CustomerId,
                    s: signature,
                },
                headers: {
                    'Content-Type': 'application/json', // Set the correct content type
                },
            });

            if (responseGetSubs.status === 200) {

                const subscriptions = JSON.parse(responseGetSubs.data).data;

                if (subscriptions.length === 0 || subscriptions.length > 0 ) {
                    const hasActiveSubscription = subscriptions.some(subscription => subscription.status === 1);

                    if (!hasActiveSubscription) {
                        const paramsplan = {
                            apiKey,
                            planId: nombrePlan + "1",
                            customerId: CustomerId,
                            couponId: IdCupon.toString()
                        };

                        const sortedKeysplan = Object.keys(paramsplan).sort();
                        let toSign = '';
                        sortedKeysplan.forEach(key => {
                            toSign += key + paramsplan[key];
                        });

                        const signatureplan = CryptoES.HmacSHA256(toSign, secretKey).toString(CryptoES.enc.Hex);

                        const formDataPlan = {
                            apiKey,
                            planId: nombrePlan + "1",
                            customerId: CustomerId,
                            couponId: IdCupon.toString(),
                            s: signatureplan
                        };

                        if (IdCupon) {
                            formDataPlan.couponId = IdCupon.toString();
                        }

                        const responsesubs = await axios.post('/api/pagosplanes/SetSubscription', JSON.stringify(formDataPlan), {
                            headers: {
                                'Content-Type': 'application/json', // Set the correct content type
                            },
                        });

                        console.log('Subscription created successfully:', responsesubs.data);
                        await sendEmail("El Usuario " + user.usuario.nombre + " Ha contratado el plan " + nombrePlan + " correo:" + user.usuario.correo)
                        Swal.fire('Existoso!', 'La subscripcion fue existosa', 'info');
                    }
                    else {
                        console.log('Active subscription found:', hasActiveSubscription);
                        Swal.fire('Subscripcion Activa!', 'Ya tiene una subscripcion activa, debe cancelarla para poder contratar otra', 'info');

                    }
                }
                else {
                    Swal.fire('Subscripcion Activa!', 'Ya tiene una subscripcion activa, debe cancelarla para poder contratar otra', 'info');
                }
            }
        } catch (error) {
            console.error('Error creating customer and plan:', error);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const confirmed = await Swal.fire({
            title: 'Confirmacion',
            text: '¿Esta seguro que desea subscribir el plan?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Seguro!',
            cancelButtonText: 'Cancelar',
        });

        // Send event to Google Analytics
        window.gtag('event', 'button_click', {
            'event_category': 'Button',
            'event_label': 'Boton Pago Plan'
        });

        if (confirmed.isConfirmed) {

            // Crear al cliente y subscripcion en Flow
            await handleCreateCustomerAndSubscription();
        }
    };

    const validaRut = (rutNumber, rutVerifier ) => {
        const rutCompleto = rutNumber + "-" + rutVerifier
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
            return false;
        var tmp = rutCompleto.split('-');
        var digv = tmp[1];
        var rut = tmp[0];
        if (digv === 'K') digv = 'k';

        return (dv(rut).toString() === digv);
    };

    const dv = (T) => {
        var M = 0, S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + T % 10 * (9 - M++ % 6)) % 11;
        return S ? S - 1 : 'k';
    };


    const handleRutNumberChange = (e) => {
        const newRutNumber = e.target.value;
        setRutNumber(newRutNumber);

        if (!validaRut(newRutNumber, rutVerifier)) {
            setRutError('Rut inválido');
        } else {
            setRutError('');
        }
    };

    const handleRutVerifierChange = (e) => {
        const newRutVerifier = e.target.value;
        setRutVerifier(newRutVerifier);

        if (!validaRut(rutNumber, newRutVerifier)) {
            setRutError('Rut inválido');
        } else {
            setRutError('');
        }
    };



    const containerStyle = {
        width: '400px',
        margin: '0 auto',
        marginTop: '50px',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        border: '1px solid #ccc',
        borderRadius: '3px',
    };


    const buttonStyle = {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    };


    return (
        <div style={containerStyle}>
            <h2>Subscripcion al Plan</h2>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <img
                    src="/assets/img/icons/flow.png"
                    alt="Flow"
                    style={{ marginLeft: '5px', width: '150px', height: '100px' }}
                />
            </div>
            <h2>Plan Seleccionado</h2>
            <p>Nombre del Plan: {nombrePlan}</p>
            <p>Precio: {price}</p>
            <form style={formStyle} onSubmit={handleSubmit}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        style={inputStyle}
                        type="text"
                        placeholder="RUT"
                        value={rutNumber}
                        style={{ ...inputStyle, width: '150px' }} 
                        onChange={handleRutNumberChange}
                        maxLength={10}
                    />
                    <span>-</span>
                    <input
                        style={{ ...inputStyle, width: '15%' }}
                        type="text"
                        maxLength="1"
                        placeholder="DV"
                        value={rutVerifier}
                        onChange={handleRutVerifierChange}
                    />
                </div>
                {rutError && <p style={{ color: 'red' }}>{rutError}</p>}
                <p>
                Se efectuara un plan de subscripción mensual por el monto establecido con pagos a traves de la plataforma FLOW que se encarga de la gestion de los cobros y pagos
                </p>
                <button style={buttonStyle} type="submit">Confirmar Subscripcion</button>
            </form>
        </div>
    );
}

export default PagoPlan;
