import React from 'react';
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const WhatsApp = () => {
  
    return (
        <div>
            {/* Your other content */}
            <WhatsAppWidget
                phoneNo="+56987739172"
                companyName="Buscadorpropiedades.cl"
                chatPersonName="Rodrigo"
                chatMessage="Hola! ¿En que puedo ayudarte?"
                headerTitle="Soporte Buscadorpropiedades"
                headerCaption="En Linea"
                placeholder="Ingresa un texto"
                btnTxt="Iniciar Conversacion"
            />
        </div>
    );
};

export default WhatsApp;
