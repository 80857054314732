import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../context/UserProvider';
import { getDistance } from 'geolib';
import { Link } from 'react-router-dom';
import { FormatoNumero } from '../global-components/globalfunctions';

const fetchCercanosData = async (property) => {
    try {
        const query = `[out:json]; 
            (
                node(around:1000,${property.latitud},${property.longitud})["highway"="bus_stop"];
                node(around:1000,${property.latitud},${property.longitud})["railway"="station"];
                node(around:1000,${property.latitud},${property.longitud})["amenity"="school"];
            );
            out;`;

        const response = await axios.post('https://overpass-api.de/api/interpreter', query);

        if (response.data && response.data.elements) {
            const elements = response.data.elements;

            const hasSchool = elements.some(elem => elem.tags && elem.tags.amenity === 'school');
            const hasBusStop = elements.some(elem => elem.tags && elem.tags.highway === 'bus_stop');
            const hasTrainStation = elements.some(elem => elem.tags && elem.tags.railway === 'station');

            let resultText = '';
            if (hasSchool) {
                resultText += 'Cercano a Colegios';
                if (hasBusStop) {
                    resultText += ', Paradas de Buses';
                    if (hasTrainStation) {
                        resultText += ', Metro';
                    }
                } else if (hasTrainStation) {
                    resultText += ', Metro';
                }
            } else if (hasBusStop) {
                resultText += 'Cercano a Paradas de Buses';
                if (hasTrainStation) {
                    resultText += ', Metro';
                }
            } else if (hasTrainStation) {
                resultText += 'Cercano a Metro';
            }

            return resultText;
        } else {
            throw new Error('No se encontraron datos de transporte.');
        }
    } catch (error) {
        console.error('Error al obtener datos de transporte: ', error);
        throw new Error('Ocurrió un error al obtener datos de transporte.');
    }
};

function PropiedadesRecomendadas({ searchparm, propertyzero}) {
    const { user } = useContext(UserContext);
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const propertiesPerPage = 10;

    function SubTitulo(item) {
        let subtitulo = "";
        if (item.property.tipopropiedad === 2) subtitulo = subtitulo + "Casa en ";
        if (item.property.tipopropiedad === 3) subtitulo = subtitulo + "Departamento en ";
        if (item.property.tipopropiedad === 4) subtitulo = subtitulo + "Galpon en ";
        if (item.property.tipopropiedad === 5) subtitulo = subtitulo + "Local Comercial en ";
        if (item.property.tipopropiedad === 6) subtitulo = subtitulo + "Terreno en ";
        if (item.property.tipopropiedad === 7) subtitulo = subtitulo + "Oficina en ";
        if (item.property.tipopropiedad === 8) subtitulo = subtitulo + "Parcela en ";
        if (item.property.tipopropiedad === 9) subtitulo = subtitulo + "Bodega en ";
        if (item.property.tipopropiedad === 10) subtitulo = subtitulo + "Derecho Llave en ";
        if (item.property.tipopropiedad === 11) subtitulo = subtitulo + "Estacionamiento en ";
        if (item.property.tipopublicacion === 1) subtitulo = subtitulo + "Venta";
        if (item.property.tipopublicacion === 2) subtitulo = subtitulo + "Arriendo";
        return subtitulo;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const search = {
                    tipopublicacion: searchparm.tipopublicacion,
                    tipopropiedad: searchparm.tipopropiedad,
                    Busqueda: "",
                    moneda: searchparm.moneda,
                    rangodesdeprecio: searchparm.rangodesdeprecio,
                    rangohastaprecio: searchparm.rangohastaprecio,
                    rangodesdetamaño: searchparm.rangodesdetamaño,
                    rangohastatamaño: searchparm.rangohastatamaño,
                    estacionamientos: searchparm.estacionamientos,
                    baños: searchparm.baños,
                    habitaciones: searchparm.habitaciones,
                    idusuario: searchparm.idusuario,
                    comuna: propertyzero ? propertyzero.comuna : '',
                    Relacionada: true,
                    origen: "Relacionadas"
                };

                const response = await axios.post('/api/propiedades/ConsultaPropsEx?startIndex=0&endIndex=10&sort=1', search);
                const { properties, totalCount } = response.data;

                const propertiesWithCercanosData = await Promise.all(
                    properties.map(async (property) => {
                        try {
                            const cercanosData = await fetchCercanosData(property.property);
                            return { ...property, cercanosData };
                        } catch (error) {
                            console.error('Error fetching cercanos data:', error);
                            return property;
                        }
                    })
                );

                const propertiesslice = propertiesWithCercanosData.slice();

                propertiesslice.sort((a, b) => {
                    const dateA = new Date(a.fechapublicacion);
                    const dateB = new Date(b.fechapublicacion);

                    if (!dateA.getTime()) return 1;
                    if (!dateB.getTime()) return -1;

                    return dateB.getTime() - dateA.getTime();
                });

                const propertiesInRange = propertiesslice.filter(property => {
                    if (
                        property.property &&
                        property.property.latitud &&
                        property.property.longitud &&
                        propertyzero &&
                        propertyzero.latitud &&
                        propertyzero.longitud
                    ) {
                        const distance = getDistance(
                            { latitude: propertyzero.latitud, longitude: propertyzero.longitud },
                            { latitude: property.property.latitud, longitude: property.property.longitud }
                        );

                        const distanceInKm = distance / 1000;
                       
                        return distanceInKm <= 6;
                    } else {
                        return false;
                    }
                });

                setProperties(propertiesInRange);
                setLoading(false);

            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [searchparm, propertyzero]);  // Update the dependency array

    if (!propertyzero) {
        return null;
    }

    const indexOfLastProperty = currentPage * propertiesPerPage;
    const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
    const currentProperties = properties.slice(indexOfFirstProperty, indexOfLastProperty);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbers = Math.ceil(properties.length / propertiesPerPage);

    const toggleFavorite = async (property) => {
        try {
            if (!user) return;
            const heartIcon = document.getElementById(`heartIcon-${property.idpropiedad}`);

            if (heartIcon) {
                const isSelected = heartIcon.classList.contains('selected');

                if (!isSelected) {
                    const url = `/api/propiedades/marcafavorito/${property.idpropiedad}/${user.usuario.idusuario}/${1}`;
                    const requestBody = null;
                    const headers = {
                        'Content-Type': 'application/json',
                    };

                    const response = await axios.post(url, requestBody, { headers });
                    heartIcon.classList.add('selected');
                    heartIcon.style.color = 'red';
                } else {
                    const url = `/api/propiedades/marcafavorito/${property.idpropiedad}/${user.usuario.idusuario}/${0}`;
                    const requestBody = null;
                    const headers = {
                        'Content-Type': 'application/json',
                    };

                    const response = await axios.post(url, requestBody, { headers });
                    heartIcon.classList.remove('selected');
                    heartIcon.style.color = '';
                }
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const renderHeartIcon = (property) => {
        if (property.isFavorite) {
            return (
                <i
                    id={`heartIcon-${property.idpropiedad}`}
                    className="fa fa-heart selected"
                    style={{ color: 'red' }}
                    onClick={() => toggleFavorite(property)}
                />
            );
        } else {
            return (
                <i
                    id={`heartIcon-${property.idpropiedad}`}
                    className="fa fa-heart"
                    onClick={() => toggleFavorite(property)}
                />
            );
        }
    };

    return (
        <div>
            {!loading && propertyzero && (
                <div className="recommended-area pd-top-90 pd-bottom-70">
                    <div className="container">
                        <div className="section-title">
                            {properties.length > 0 && <h5 className="title" style={{ color: 'var(--main-color-one)' }}>Recomendaciones</h5>}
                            <br></br>
                        </div>
                        <div className="row">
                            {currentProperties.map((property, i) => (
                                <div key={i} className="col-lg-4 col-sm-6">
                                    <div className="single-feature">
                                        <Link to={`/detallepropiedad/${property.property.idpropiedad}`}>
                                        <div className="thumb">
                                            <div className="thumb-image" style={{ position: 'relative' }}>
                                                {property.nivelpromo && (
                                                    <div className="promo-icon" style={{ position: 'absolute', top: 15, left: 15 }}>
                                                        <img src={'/assets/img/icons/promo-icon-9.png'} alt="Promo" style={{ width: '40px', height: '40px' }} />
                                                    </div>
                                                )}
                                                <img
                                                    src={`data:image/jpeg;base64,${property.base64Data}`}
                                                    alt="img"
                                                    style={{ width: '100%', height: '200px' }}
                                                />
                                            </div>
                                            </div>
                                        </Link>
                                        <div className="details">
                                            <div className="details-wrap">
                                                <div className="feature-logo">
                                                    {property.icono && (
                                                        <img
                                                            src={`data:image/jpeg;base64,${property.icono}`}
                                                            alt="logo"
                                                            className="rounded-circle thumbnail-icon"
                                                            style={{ maxWidth: '100%', height: 'auto' }}
                                                        />
                                                    )}
                                                    {!property.icono && <img className="img-profile rounded-circle" src={'/assets/img/Foto003.jpg'} alt="logo" />}
                                                </div>
                                                {user && (
                                                    <div className="favorite-icon">
                                                        {renderHeartIcon(property)}
                                                    </div>
                                                )}
                                                <p className="author">
                                                    <i className="fa fa-user" /> {property.nombre}
                                                </p>
                                                <h6 className="readeal-top">{SubTitulo(property)}</h6>
                                                <h6 className="title readeal-top">
                                                    <a>{property.property.titulo} - {property.property.comuna} </a>
                                                </h6>
                                                <h6 className="price">{property.property.moneda} {FormatoNumero(property.property.precioactual)}</h6>
                                                <del>{FormatoNumero(property.property.precioAnterior)}</del>
                                                <ul className="info-list">
                                                    {property.property.numhabitaciones > 0 && (
                                                        <li>
                                                            <i className="fa fa-bed" /> {property.property.numhabitaciones} Habs
                                                        </li>
                                                    )}
                                                    {property.property.numbaños > 0 && (
                                                        <li>
                                                            <i className="fa fa-bath" /> {property.property.numbaños} baños
                                                        </li>
                                                    )}
                                                    {property.property.numestacionamientos > 0 && (
                                                        <li>
                                                            <i className="fa fa-car" /> {property.property.numestacionamientos} estacionamientos
                                                        </li>
                                                    )}
                                                    <li>
                                                        <img src={'/assets/img/icons/7.png'} alt="area" /> {property.property.area} Mt2
                                                    </li>
                                                </ul>
                                                <div style={{ width: '150px', borderBottom: '2px solid ', margin: '0 auto 10px', marginTop: '20px' }} />
                                                <div className="info-container" style={{ alignItems: 'center', fontWeight: 'bold', fontSize: '13px' }}>
                                                    {property.cercanosData}
                                                </div>
                                                <ul className="contact-list">
                                                    <li className="readeal-top">
                                                        <Link className="btn btn-yellow" to={`/detallepropiedad/${property.property.idpropiedad}`}>
                                                            Ver Detalles
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="pagination-wrap">
                            <ul className="pagination">
                                {Array.from({ length: pageNumbers }, (_, i) => (
                                    <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageChange(i + 1)}>
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PropiedadesRecomendadas;
