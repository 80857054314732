import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../context/UserProvider';
import Rating from 'react-rating-stars-component';
import Swal from 'sweetalert2'
import { FaEdit, FaTrash } from 'react-icons/fa'; // Import icons from Font Awesome

function Reseñas() {
    const { idusuarioTarget} = useParams();
    const [reviewsData, setReviewsData] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [detalle, setDetalle] = useState('');
    const [rating, setRating] = useState(0);
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [usuarioTarget, setUsuarioTarget] = useState('');
    const [clienteTarget, setClienteTarget] = useState('');

    const { user } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/api/resenas/getReseñas/${idusuarioTarget}`);
                const reseñasData = response.data;
                setReviewsData(reseñasData);

                try {
                    const response = await axios.get(`/api/usuario/ObtieneUsuario/${idusuarioTarget}`);
                    const usuarioTargetData = response.data; // Assuming the response is an object with icono and nombre fields
                    setUsuarioTarget(usuarioTargetData);
                    if (usuarioTargetData.idcliente) {
                        const response = await axios.get(`/api/clientes/getCliente/${usuarioTargetData.idcliente}`);
                        const clienteTargetData = response.data; // Assuming the response is an object with icono and nombre fields
                        setClienteTarget(clienteTargetData);
                    }
                } catch (error) {
                    console.error('API error:', error);
                }
            } catch (error) {
                console.error('API error:', error);
            }
        };

        fetchData();
    }, [idusuarioTarget]);


    const handleSubmit = async event => {
        event.preventDefault();

        if (editingIndex !== -1) {
            const updatedReview = { ...reviewsData[editingIndex], titulo, detalle, ranking: rating };
            axios.put('/api/resenas/updateReseña', updatedReview)
                .then(response => {
                    const updatedReviews = [...reviewsData];
                    updatedReviews[editingIndex] = updatedReview;
                    setReviewsData(updatedReviews);
                    setEditingIndex(-1);
                    setTitulo('');
                    setDetalle('');
                    setRating(0);
                })
                .catch(error => {
                    console.error('API error:', error);
                });
        } else {
            try {
                const response = await axios.get(`/api/resenas/getReseña/${idusuarioTarget}/${user.usuario.idusuario}`);
                const existingReview = response.data[0];

                if (existingReview) {
                    Swal.fire('Aviso', 'Ya has dejado una reseña para este usuario.', 'info');
                    return;
                }

                const newReview = { idusuarioopina: user.usuario.idusuario, idusuarioTarget, titulo, detalle, ranking: rating };
                axios.post('/api/resenas/setReseña', newReview)
                    .then(response => {
                        setReviewsData(prevData => [...prevData, response.data]);
                        setTitulo('');
                        setDetalle('');
                        setRating(0);
                    })
                    .catch(error => {
                        console.error('API error:', error);
                    });
            } catch (error) {
                console.error('API error:', error);
            }
        }
    };

    const handleEdit = (index) => {
        const review = reviewsData[index];
        setEditingIndex(index);
        setTitulo(review.titulo);
        setDetalle(review.detalle);
        setRating(review.ranking);
    };

    const handleDelete = (index) => {
        const review = reviewsData[index];
        axios.delete(`/api/resenas/deleteReseña/${review.idUsuarioOpina}/${review.idUsuarioTarget}`)
            .then(response => {
                const updatedReviews = reviewsData.filter((_, i) => i !== index);
                setReviewsData(updatedReviews);
            })
            .catch(error => {
                console.error('API error:', error);
            });
    };

    if (!usuarioTarget.usuario || !user) return (<div></div>);

    function calculateAverageRating(reviews) {
        if (reviews.length === 0) {
            return 0;
        }

        const totalRating = reviews.reduce((sum, review) => sum + review.ranking, 0);
        return (totalRating / reviews.length).toFixed(1); // Calculate average and round to 1 decimal place
    }

    return (
        <div className="faq-area" style={{ padding: '100px 0' }}>
            <div className="container">
                <div className="row">
                    <div
                    >
                        {usuarioTarget.icono && (
                            <img
                                src={`data:image/jpeg;base64,${usuarioTarget.icono}`}
                                alt={`${usuarioTarget.nombre}'s Avatar`}
                                style={{
                                    width: '40px', height: '40px', marginRight: '10px', border: '2px solid #ccc'
                                }}
                            />
                        )}
                        <div style={{ fontWeight: 'bold' }}>Nombre: {usuarioTarget.usuario.nombre}</div>
                        {clienteTarget[0] && (
                            <div style={{ fontWeight: 'bold' }}>Cliente: {clienteTarget[0].descripcion}</div>
                        )}
                        <p className="reviews">
                            <i className="fa fa-star" style={{ color: 'orange' }} />
                            <span>{calculateAverageRating(reviewsData)}</span> - {reviewsData.length} reseñas
                        </p>
                        <br></br>
                        <br></br>
                    </div>
                    
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className="section-title">
                            <h3>reseñas Actuales</h3>
                        </div>
                        <div className="accordion" id="accordion">
                            {reviewsData.length > 0 ? (
                                reviewsData.map((review, i) => (
                                    <div key={i} className="single-accordion card">
                                        <div className="card-header" id={`reviewHeading${i}`}>
                                            <h2 className="mb-0">
                                                <button
                                                    className={`btn-link ${expandedIndex !== i ? 'collapsed' : ''}`}
                                                    type="button"
                                                    data-toggle="collapse"
                                                    data-target={`#reviewCollapse${i}`}
                                                    aria-controls={`reviewCollapse${i}`}
                                                    onClick={() => setExpandedIndex(i)}
                                                >
                                                    {`${review.titulo} - ${review.usuarioNombre} `}
                                                    <Rating
                                                        count={5}
                                                        value={review.ranking}
                                                        edit={false}
                                                    />
                                                    <br />
                                                    {`${new Date(review.fecha).toLocaleDateString()}`}
                                                </button>
                                            </h2>
                                            {review.idUsuarioOpina === user.usuario.idusuario && (
                                                <div>
                                                    <FaEdit
                                                        className="edit-icon mr-2"
                                                        onClick={() => handleEdit(i)}
                                                        style={{ color: 'orange', cursor: 'pointer' }}
                                                    />
                                                    <FaTrash
                                                        className="delete-icon"
                                                        onClick={() => handleDelete(i)}
                                                        style={{ color: 'orange', cursor: 'pointer' }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            id={`reviewCollapse${i}`}
                                            className={`collapse ${expandedIndex === i ? 'show' : ''}`}
                                            aria-labelledby={`reviewHeading${i}`}
                                            data-parent="#accordion"
                                        >
                                            <div className="card-body">
                                                <p>{review.detalle}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No hay reseñas</p>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className="add-review">
                            <h3>Agrega una Reseña</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="titulo">titulo:</label>
                                    <input
                                        id="titulo"
                                        type="text"
                                        value={titulo}
                                        onChange={event => setTitulo(event.target.value)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="detalle">Detalle:</label>
                                    <textarea
                                        id="detalle"
                                        value={detalle}
                                        onChange={event => setDetalle(event.target.value)}
                                        className="form-control"
                                        rows="4"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ranking">Ranking:</label>
                                    <Rating
                                        count={5}
                                        value={rating}
                                        onChange={setRating}
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Subir Reseña
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reseñas;
