import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faStar } from '@fortawesome/free-solid-svg-icons';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const DragHandle = SortableHandle(() => <FontAwesomeIcon icon={faGripVertical} className="mr-2" />);

const ImageItem = SortableElement(({ image, isMain, onSetMain, onDelete, index }) => {
    const [isZoomed, setIsZoomed] = useState(false);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    const handleSetMain = () => {
        onSetMain(image.id);
    };

    const handleDelete = () => {
        onDelete(image.id);
    };

    const handleZoomToggle = () => {
        setIsZoomed(!isZoomed);
        setIsLightboxOpen(true);
    };

    const lightboxStyle = {
        position: 'fixed',
        top: '50%', // Center the lightbox vertically
        left: '50%', // Center the lightbox horizontally
        transform: 'translate(-50%, -50%)', // Center the lightbox both horizontally and vertically
        zIndex: 2000,
        maxWidth: '100%', // Adjust the width to your desired size, it will not exceed the screen width
        maxHeight: '100%', // Adjust the height to your desired size, it will not exceed the screen height
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <li className={`image-item ${isMain ? 'main-image' : ''}`}>
            <div className="image-details">
                <DragHandle />
                <div className={`image-container ${isZoomed ? 'zoomed' : ''}`}>
                    <img
                        src={`data:image/png;base64,${image.filecontent}`}
                        alt={image.fileName}
                        className="image-preview"
                        onClick={handleZoomToggle}
                    />
                </div>
                <div className="image-actions">
                    <div className={`center-buttons ${isMain ? 'main-image-mark' : ''}`}>
                        <button className="btn btn-primary btn-sm" onClick={handleSetMain}>
                            <FontAwesomeIcon icon={faStar} />
                        </button>
                        <button className="btn btn-danger btn-sm" onClick={handleDelete}>
                            Borrar
                        </button>
                    </div>
                </div>
            </div>
            {isLightboxOpen && (
                <Lightbox
                    mainSrc={`data:image/png;base64,${image.filecontent}`}
                    onCloseRequest={() => setIsLightboxOpen(false)}
                    imageTitle={image.fileName}
                    imageCaption={image.description}
                    toolbarButtons={[
                        <button key="zoomIn" type="button" className="ril-zoom-in" />,
                        <button key="zoomOut" type="button" className="ril-zoom-out" />,
                        <button key="close" type="button" className="ril-close" />,
                    ]}
                    reactModalStyle={{
                        overlay: { zIndex: 9999 },
                        content: { maxWidth: '800px' },
                    }}
                />
            )}
        </li>
    );
});

const SortableList = SortableContainer(({ items, onSetMain, onDelete }) => {
    return (
        <ul className="image-list">
            {items.map((image, index) => (
                <ImageItem
                    key={image.id}
                    index={index}
                    image={image}
                    isMain={image.isMain}
                    onSetMain={onSetMain}
                    onDelete={onDelete}
                />
            ))}
        </ul>
    );
});

const ImageGallery = ({ customParam, onMainImageChange }) => {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async (customParam) => {
            try {
                const response = await axios.get('/api/images', {
                    params: { q: customParam }
                });
                setImages(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        if (customParam) {
            fetchImages(customParam);
        }
    }, [customParam]);

    useEffect(() => {
        // Check if there is no main image selected
        const isMainImageSelected = images.some((image) => image.isMain);
        if (!isMainImageSelected && images.length > 0) {
            // Set the first image as the main image
            setImages((prevImages) => {
                const updatedImages = prevImages.map((image, index) => {
                    if (index === 0) {
                        return { ...image, isMain: true };
                    }
                    return { ...image, isMain: false };
                });
                return updatedImages;
            });
            onMainImageChange(images[0].id);
        }
    }, [images, onMainImageChange]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            const [movedImage] = updatedImages.splice(oldIndex, 1);
            updatedImages.splice(newIndex, 0, movedImage);
            return updatedImages;
        });
    };

    const handleSetMain = (imageId) => {
        setImages((prevImages) => {
            const updatedImages = prevImages.map((image) => {
                if (image.id === imageId) {
                    return { ...image, isMain: true };
                }
                return { ...image, isMain: false };
            });
            return updatedImages;
        });
        onMainImageChange(imageId);
    };

    const handleDelete = (imageId) => {
        setImages((prevImages) => {
            const isMainImageDeleted = prevImages.find((image) => image.id === imageId && image.isMain);
            const updatedImages = prevImages.filter((image) => image.id !== imageId);

            if (isMainImageDeleted && updatedImages.length > 0) {
                // Set the first image as the main image when the current main image is deleted
                updatedImages[0].isMain = true;
                onMainImageChange(updatedImages[0].id);
            } else if (isMainImageDeleted) {
                onMainImageChange(null);
            }

            return updatedImages;
        });

        axios.delete(`/api/images/${imageId}`);
    };

    if ((customParam === null || customParam === undefined) && (images === null || images.length === 0)) {
        return <div></div>;
    }

    return (
        <div>
            {/* Render spinner if loading */}
            {isLoading && (
                <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div className="spinner">
                            <div className="dot1"></div>
                            <div className="dot2"></div>
                        </div>
                    </div>
                </div>
            )}
            <SortableList items={images} onSortEnd={onSortEnd} useDragHandle onSetMain={handleSetMain} onDelete={handleDelete} />
        </div>
    );
};

export default ImageGallery;
