import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PreciosPlanesSection from './section-components/preciosplanes';
import Footer from './global-components/footer';

const PreciosPlanes = () => {
    return <div>
        <Navbar />
        <PreciosPlanesSection />
        <Footer />
    </div>
}

export default PreciosPlanes

