import React, { useState, useEffect, useRef } from "react";
import { createContext } from "react";
import Swal from "sweetalert2";

export const UserContext = createContext();

const sessionTimeoutDuration = 900000; // 15 minutes in milliseconds

const UserProvider = ({ children }) => {
    let storedUser = window.localStorage.getItem("sesion_usuario");
    if (storedUser) {
        try {
            let storedUserTemp = JSON.parse(storedUser);
            // Check for the presence of idUsuario or idusuario (lowercase)
            if (storedUserTemp.usuario && storedUserTemp.usuario.idUsuario) {
                storedUser = null; // Set to null if idusuario is found
                window.localStorage.removeItem("sesion_usuario");
            }
        } catch (error) {
            console.error("Error parsing stored user data:", error);
            storedUser = null; // Set to null if parsing fails
            window.localStorage.removeItem("sesion_usuario");
        }
    } 

    const [user, setUser] = useState(storedUser ? JSON.parse(storedUser) : null);
    const timeoutRef = useRef(null);
    const [showExpirationModal, setShowExpirationModal] = useState(false);
    const [notificationShown, setNotificationShown] = useState(false);

    const iniciarSesion = (data) => {
        window.localStorage.setItem("sesion_usuario", JSON.stringify(data));
        setUser(data);
        resetTimeout();
    };

    const cerrarSesion = () => {
        window.localStorage.removeItem("sesion_usuario");
        setUser(null);
        setNotificationShown(false); // Reset the notification state
        clearTimeout(timeoutRef.current);
        window.location.href = '/';
    };

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            if (!notificationShown) {
                setNotificationShown(true);
                setShowExpirationModal(true);
                Swal.fire({
                    title: "Tu sesión será cerrada por inactividad.",
                    timer: 2000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                }).then(() => {
                    setShowExpirationModal(false);
                    cerrarSesion();
                });
            }
        }, sessionTimeoutDuration - 500);
    };

    useEffect(() => {
        const handleActivity = () => {
            resetTimeout();
        };

        document.addEventListener("mousemove", handleActivity);
        document.addEventListener("keydown", handleActivity);

        return () => {
            clearTimeout(timeoutRef.current); // Clear the current timeout
            document.removeEventListener("mousemove", handleActivity);
            document.removeEventListener("keydown", handleActivity);
        };
    }, []);

    return (
        <UserContext.Provider
            value={{ user, iniciarSesion, cerrarSesion, showExpirationModal }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
