import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PopularPost from './blog-components/popular-post';
import ListadoNoticias from './blog-components/listadonoticias';
import Footer from './global-components/footer';

const Noticias = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Noticias" headerimage="Banners-Noticias.jpg" />
        {/*<PopularPost />*/}
        <ListadoNoticias />
        <Footer />
    </div>
}

export default Noticias

