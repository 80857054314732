import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';

class Mission extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let imagealt = 'image';
        let data = sectiondata.mission;

        // Define the background image URL
        let backgroundImageUrlBanner = 'url("/assets/img/bg/15.png")';
        let backgroundImageUrlMision = 'url("/assets/img/bg/16.png")';

        return (
            <div>
                {/* Initial red rectangle */}
                <div style={{ backgroundColor: 'var(--main-color-one)', height: '30px' }}></div>

                {/* Main content with background image, image at right, and text at left */}
                <div className="mission-vission-area pd-top-80 pd-bottom-120" style={{ backgroundColor: '#f0eff5', color: 'white' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content-left p-3" style={{ width: '70%', margin: '0 auto' }}>
                                    <h2 style={{ color: 'var(--main-color-one)' }}>Más agilidad menos costos</h2>
                                    <br></br>
                                    <h5 style={{ color: 'black' }}>!La revolución está aquí!</h5>
                                    <br></br>
                                    <h6 style={{ color: 'black' }}>Como usuario que buscas la propiedad de tus sueños, con buscadorpropiedades olvídate de perder tiempo en listados irrelevantes; nuestra plataforma aprende de tus elecciones y te presenta opciones que se ajustan a tus deseos.</h6>
                                    <h6 style={{ color: 'black' }}>Como corredor de propiedades, ¡dile adiós a los cobros excesivos y a la complejidad innecesaria!. Mejora la visibilidad de tus propiedades para cerrar mejores negocios.</h6>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-right">
                                    {/* Add your image here */}
                                    <img src="/assets/img/banner/Buscador-propiedades-landings_Imagen contenido 1.png" alt={imagealt} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mission-vission-area pd-top-150 pd-bottom-130" style={{ backgroundImage: backgroundImageUrlMision, backgroundSize: 'cover', backgroundPosition: 'center', color: 'white' }}>
                    <div className="container pd-top-170">
                        <div className="row justify-content-center"  >
                            {data.items.map((item, i) =>
                                <div key={i} className="col-xl-3 col-lg-4 col-sm-4" >
                                    <div className="single-intro text-lg-left text-center" style={{ backgroundColor: 'var(--main-color-four)', minHeight: '800px' }}>
                                        <div className="text">
                                            <img src={"/assets/img/icons/" + item.url} alt={`Thumb ${i}`} />
                                           </div>
                                        <div className="details" style={{ marginTop: '20px' }}>
                                            <h4 className="title"><h3 style={{ color: 'var(--main-color-one)' }}>{item.title}</h3></h4>
                                            <p style={{ color: '#e5e4ef', marginTop: '40px' }}>{item.content}</p>
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Mission;
