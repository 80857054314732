import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ReseñasSection from './section-components/reseñas';
import Footer from './global-components/footer';

const reseñas = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="reseñas de Usuario" headerimage="4.png" />
        <ReseñasSection />
        <Footer />
    </div>
}

export default reseñas

