import React, { useState, useEffect, useMemo, useContext } from 'react';
import {FormGroup, Label, Input, Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Import eye icons
import axios from 'axios';
import territorio from '../../data/territoriochile.json';
import CryptoES from 'crypto-es';
import { UserContext } from '../../context/UserProvider';


// Define a static salt value
const staticSalt = '$2a$10$KF54AyqVH5AzkRn7.972Te';

function Registro() {
    const location = useLocation();
    let idusuario = null;
    if (location.state) idusuario = location.state.idusuario;
    const queryParams = new URLSearchParams(location.search);
    const nombrePlan = queryParams.get('name');
    const price = queryParams.get('price');
    const navigate = useNavigate();

    const { iniciarSesion } = useContext(UserContext);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [role, setRole] = useState('NORMAL');
    const [country, setCountry] = useState('Chile');
    const [city, setCity] = useState('');
    const [comuna, setComuna] = useState('');
    const [email, setEmail] = useState('');
    const [idcliente, setidcliente] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRetry, setPasswordRetry] = useState('');
    const [showPassword, setShowPassword] = useState(false); // Track whether to show or hide password
    const [showPasswordRetry, setShowPasswordRetry] = useState(false); // Track whether to show or hide password retry
    const [cityOptions, setCityOptions] = useState([]);
    const [comunaOptions, setComunaOptions] = useState([]);
    const [passwordStrength, setPasswordStrength] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            if (idusuario != null && idusuario !== '') {
                try {
                    const UsuarioResponse = await axios.get(`/api/usuario/ObtieneUsuario/${idusuario}`);

                    if (UsuarioResponse.status === 200 && UsuarioResponse.data) {
                        const UsuarioData = UsuarioResponse.data;
                        setName(UsuarioData.usuario.nombre);
                        setPhone(UsuarioData.usuario.telefono);
                        setRole(UsuarioData.usuario.rol);
                        setCountry(UsuarioData.usuario.pais);
                        setCity(UsuarioData.usuario.ciudad);
                        setComuna(UsuarioData.usuario.comuna);
                        setEmail(UsuarioData.usuario.correo);
                        setidcliente(UsuarioData.usuario.idcliente);
                    } else {
                        console.log('error');
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        };

        fetchData();
    }, [idusuario]);

    const territorioData = useMemo(() => territorio || [], []);

    const getCityOptions = (territorioData) => {
        const citySet = new Set();

        territorioData.forEach(region => {
            // Add regional capitals
            citySet.add(region.capital_regional);

            region.provincias.forEach(provincia => {
                // Add provincial capitals
                citySet.add(provincia.capital_provincial);

                //provincia.comunas.forEach(comuna => {
                //    // Add all comunas (which include cities like Chillán)
                //    citySet.add(comuna.nombre);
                //});
            });
        });

        // Convert Set to Array and sort
        return Array.from(citySet).sort((a, b) => a.localeCompare(b, 'es', { sensitivity: 'base' }));
    };

    useEffect(() => {
        // Initialize city options
        //const cityOptions = territorioData
        //    .flatMap((region) => region.provincias.map((provincia) => provincia.capital_provincial))
        //    .sort((a, b) => a.localeCompare(b)); // Sort the city options in ascending order using localeCompare
        //setCityOptions(cityOptions);

        // Generate city options
  const cityOptions = getCityOptions(territorioData);
  setCityOptions(cityOptions);

  // Initialize comuna options
  const comunaOptions = territorioData
    .flatMap((region) =>
      region.provincias
        .filter((provincia) => 
          provincia.capital_provincial === city || 
          provincia.comunas.some(comuna => comuna.nombre === city)
        )
        .flatMap((provincia) => provincia.comunas.map((comuna) => comuna.nombre))
    )
    .sort((a, b) => a.localeCompare(b, 'es', { sensitivity: 'base' }));
  setComunaOptions(comunaOptions);
}, [territorioData, city]);

    const checkPasswordStrength = (password) => {
        let strength = '';

        // Check for password length
        if (password.length < 8) {
            strength = 'La contraseña debe tener al menos 8 caracteres';
        } else {
            // Check for complexity
            const regexLowercase = /[a-z]/;
            const regexUppercase = /[A-Z]/;
            const regexDigit = /[0-9]/;
            const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

            if (
                regexLowercase.test(password) &&
                regexUppercase.test(password) &&
                regexDigit.test(password) &&
                regexSpecialChar.test(password)
            ) {
                strength = 'Contraseña segura';
            } else {
                strength =
                    'La contraseña debe incluir al menos una letra minúscula, una letra mayúscula, un número y un carácter especial (!, @, #, $, %, ^, &, *, (, ), ,, ., ?, ", :, {, }, |, <, >)';
            }
        }

        setPasswordStrength(strength);
    };

    function AutoLogin(clave, correo) {
        return new Promise((resolve, reject) => {
            const hashedPassword = CryptoES.SHA256(clave + staticSalt).toString();

            let request = {
                correo: correo,
                clave: hashedPassword,
            };

            axios
                .post('api/session/Login', request, {
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                })
                .then((response) => {
                    console.log(response);
                    const dataJson = response.data;

                    if (dataJson.idUsuario === 0) {
                        Swal.fire('Opps!', 'No se encontró el usuario', 'error');
                        reject('No se encontró el usuario');
                    } else {
                        iniciarSesion(dataJson);
                        resolve(dataJson);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status.toString() === "403") {
                        Swal.fire('Opps!', 'No se pudo iniciar sesión, el usuario está bloqueado. Intente recuperar la contraseña o contacte a Buscadorpropiedades', 'error');
                        reject('No se pudo iniciar sesión');
                    } else {
                        reject(error);
                    }
                });
        });
    }

    const sendEmail = async (nombre, correo) => {
        try {

          const emailRequest = {
                nombre: nombre,
                correo: correo
            };

            const response = await axios.post('/api/usuario/EnviaEmailCreacionUsuario', emailRequest);

            if (response.status !== 200) {
                console.error('Error al enviar correo de creacion de usuario:', response.status);
            }

        } catch (error) {
            console.error(error);
            // Handle error
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== passwordRetry) {
            Swal.fire('Opps!', 'Las contraseñas no coinciden', 'error');
            return;
        }

        // Check password strength
        if (passwordStrength !== 'Contraseña segura') {
            Swal.fire('Opps!', 'La contraseña no cumple con los requisitos de seguridad', 'error');
            return;
        }

        // Check if user with the same email already exists
        if (idusuario == null || idusuario === '') {
            const existeUsuarioResponse = await fetch('api/usuario/ExisteUsuario', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (existeUsuarioResponse.ok) {
                Swal.fire('Opps!', 'El usuario ya existe', 'error');
                return;
            }
        }

        const usuariocliente = await fetch(`api/usuariosclientes/getUsuarioCliente/${email}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (usuariocliente.ok) {
            const responseData = await usuariocliente.json();
            if (responseData.length > 0) {
                const idclienteresponse = responseData[0].idcliente;
                setidcliente(idclienteresponse);
            } else {
                const cliente = await fetch(`api/clientes/getResponsableCliente/${email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (cliente.ok) {
                    const responseData = await cliente.json();
                    if (responseData.length > 0) {
                        const idclienteresponse = responseData[0].idcliente;
                        setidcliente(idclienteresponse);
                    }
                }
            }
        }

        const hashedPassword = CryptoES.SHA256(password + staticSalt).toString();

        const formData = new FormData();
        formData.append('nombre', name);
        formData.append('correo', email);
        formData.append('telefono', phone);
        formData.append('Rol', role);
        formData.append('clave', hashedPassword);
        formData.append('esactivo', true);
        formData.append('pais', country);
        formData.append('ciudad', city);
        formData.append('comuna', comuna);
        formData.append('iconofile', null);
        formData.append('idusuario', idusuario || '');
        formData.append('idcliente', idcliente || '');

        const response = await fetch('api/usuario/Guardar', {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            if (idusuario == null || idusuario === '') {
                if (nombrePlan) {
                    try {
                        await AutoLogin(password, email);
                    } catch (error) {
                        console.error('Error during AutoLogin:', error);
                        // Handle error as needed
                    }
                }

                sendEmail(name, email);

                setName('');
                setPhone('');
                setCity('');
                setComuna('');
                //setEmail('');
                //setPassword('');
                setPasswordRetry('');

                

                if (!nombrePlan) {
                    //Swal.fire('Éxito', 'Usuario creado exitosamente. ¡Ya puedes ingresar al sistema!', 'info');
                    try {
                        await AutoLogin(password, email);
                    } catch (error) {
                        console.error('Error during AutoLogin:', error);
                        // Handle error as needed
                    }
                    setEmail('');
                    setPassword('');
                    navigate(`/`);
                }
                else {
                    navigate(`/pagoplan?price=${encodeURIComponent(price)}&name=${encodeURIComponent(nombrePlan)}`);
                }
            } else {
                Swal.fire('Éxito', 'Usuario actualizado exitosamente', 'info');
            }
        } else {
            Swal.fire('Opps!', 'Error al guardar el usuario', 'error');
        }
    };

    return (
        <div className="register-page-area pd-bottom-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-5 col-md-6 mb-5 mb-md-0">
                     <form className="contact-form-wrap contact-form-bg" onSubmit={handleSubmit}>
                    {/*<Form onSubmit={handleSubmit}>*/}
                        <FormGroup>
                            <Label for="name">Nombre</Label>
                            <Input
                                type="text"
                                id="name"
                                placeholder="Ingresa el Nombre"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="phone">Teléfono</Label>
                            <Input
                                type="text"
                                id="phone"
                                placeholder="Ingresa el Teléfono"
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="country">País</Label>
                            <Input
                                type="text"
                                id="country"
                                placeholder="Ingresa el País"
                                value={country}
                                onChange={(event) => setCountry(event.target.value)}
                                readOnly
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="city">ciudad</Label>
                            <Input
                                type="select"
                                id="city"
                                value={city}
                                onChange={(event) => setCity(event.target.value)}
                                required
                            >
                                <option value="">Selecciona una ciudad</option>
                                {cityOptions.map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="comuna">comuna</Label>
                            <Input
                                type="select"
                                id="comuna"
                                value={comuna}
                                onChange={(event) => setComuna(event.target.value)}
                                required
                            >
                                <option value="">Selecciona una comuna</option>
                                {comunaOptions.map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="email">Correo Electrónico</Label>
                            <Input
                                type="email"
                                id="email"
                                placeholder="Ingresa el Correo Electrónico"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="password">Contraseña</Label>
                            <div className="input-group-icon">
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    name="clave"
                                    id="clave"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        checkPasswordStrength(e.target.value);
                                    }}
                                    required
                                />
                                <div className="input-icon">
                                    {showPassword ? (
                                        <AiFillEyeInvisible onClick={() => setShowPassword(false)} className="eye-icon" />
                                    ) : (
                                        <AiFillEye onClick={() => setShowPassword(true)} className="eye-icon" />
                                    )}
                                </div>
                            </div>
                            <div className="password-strength">{passwordStrength}</div>
                        </FormGroup>

                        <FormGroup>
                            <Label for="passwordRetry">Repetir Contraseña</Label>
                            <div className="input-group-icon">
                                <Input
                                    type={showPasswordRetry ? 'text' : 'password'}
                                    name="claveRetry"
                                    id="claveRetry"
                                    value={passwordRetry}
                                    onChange={(e) => setPasswordRetry(e.target.value)}
                                    required
                                />
                                <div className="input-icon">
                                    {showPasswordRetry ? (
                                        <AiFillEyeInvisible onClick={() => setShowPasswordRetry(false)} className="eye-icon" />
                                    ) : (
                                        <AiFillEye onClick={() => setShowPasswordRetry(true)} className="eye-icon" />
                                    )}
                                </div>
                            </div>
                        </FormGroup>

                            <Button className="btn btn-yellow" block>
                            {!idusuario ? 'Registrar' : 'Modificar'}
                        </Button>
                            {/*</Form>*/}
                    </form>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Registro;
