import React, { useContext, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from "../../context/UserProvider"
import Swal from 'sweetalert2'
import { Tooltip, Input, Label } from 'reactstrap';
import territorio from '../../data/territoriochile.json';
import { FormatoNumero } from '../global-components/globalfunctions';


function Thumb({ imageData, height, hasPromo }) {
    return (
        <div className="thumb">
            <div className="thumb-image" style={{ position: 'relative' }}>
                {hasPromo && (
                    <div className="promo-icon" style={{ position: 'absolute', top: 15, left: 15 }}>
                        <img src={'/assets/img/icons/promo-icon-9.png'} alt="Promo" style={{ width: '40px', height: '40px' }} />
                    </div>
                )}
                <img
                    src={`data:image/jpeg;base64,${imageData}`}
                    alt="img"
                    style={{ width: '100%', height: `${height}px` }}
                />
            </div>
        </div>
    );
}


const MisFavoritas = () => {
    const { user } = useContext(UserContext);
    const dt = user;
    const [properties, setProperties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [propertiesPerPage, setPropertiesPerPage] = useState(10);
    const [tipovista, settipoVista] = useState('1');
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpenReg, setTooltipOpenReg] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const totalPages = Math.ceil(properties.length / propertiesPerPage);
    const [comuna, setComuna] = useState('');
    const [region, setRegion] = useState('');
    const [regionOptions, setRegionOptions] = useState([]);
    const [comunaOptions, setComunaOptions] = useState([]);

    const navigate = useNavigate(); // Add useNavigate here
    const heartIconRefs = useRef({});

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const toggleTooltipReg = () => {
        setTooltipOpenReg(!tooltipOpenReg);
    };

    useEffect(() => {
        // Scroll to the top when the page is loaded
        window.scrollTo(0, 0);
        setIsMounted(true);
    }, []);

    const fetchPropertiesFavs = useCallback((dt) => {
        const idUsuario = parseInt(dt.usuario.idusuario,10); // Convert idUsuario to integer
        axios
            .post('/api/propiedades/ConsultaFavoritas', idUsuario, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }) // Pass idUsuario as the request body
            .then((response) => {
                const properties = response.data;

                const propertyPromises = properties.map(async (property) => {
                    const ImagenResponse = await axios.get(`/api/images/ObtieneImagen/${property.imageid}`);
                    const base64Data = ImagenResponse.data.filecontent;

                    const UsuarioResponse = await axios.get(`/api/usuario/ObtieneUsuario/${property.idusuario}`);
                    const nombre = UsuarioResponse.data.usuario.nombre;
                    const icono = UsuarioResponse.data.icono;

                    return { ...property, base64Data, nombre, icono };
                });

                Promise.all(propertyPromises)
                    .then((updatedProperties) => {
                        setProperties(updatedProperties);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [comuna]);

    useEffect(() => {
        if (dt)
            fetchPropertiesFavs(dt);
    }, [dt, fetchPropertiesFavs]);

    const territorioData = useMemo(() => territorio || [], []);

    useEffect(() => {
        // Initialize region options
        const regionOptions = territorioData.map((region) => region.nombre).sort((a, b) => a.localeCompare(b));
        setRegionOptions(regionOptions);
    }, [territorioData]);

    useEffect(() => {
        // Initialize comuna options based on selected region
        const selectedRegion = territorioData.find((item) => item.nombre === region);
        if (selectedRegion) {
            const comunaOptions = selectedRegion.provincias
                .flatMap((provincia) => provincia.comunas.map((comuna) => comuna.nombre))
                .sort((a, b) => a.localeCompare(b));
            setComunaOptions(comunaOptions);
        } else {
            setComunaOptions([]);
        }
    }, [territorioData, region]);

    let publicUrl = process.env.PUBLIC_URL + '/';

    if (isLoading) {
        return <div></div>;
    }

    const indexOfLastProperty = currentPage * propertiesPerPage;
    const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
    const propertiesToDisplay = properties.slice(indexOfFirstProperty, indexOfLastProperty);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1); // Create an array of page numbers

    // Handler for pagination buttons
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handler for properties per page selection
    const handlePropertiesPerPageChange = (event) => {
        setPropertiesPerPage(parseInt(event.target.value));
        setCurrentPage(1); // Reset current page when changing properties per page
    };


    const toggleFavorite = async (property) => {
        try {
            if (!dt) return;
            //const heartIcon = document.getElementById(`heartIcon-${property.idpropiedad}`);
            const heartIcon = heartIconRefs.current[property.idpropiedad];

            if (heartIcon) {
                const isSelected = heartIcon.classList.contains('selected');

                if (!isSelected) {
                    const url = `/api/propiedades/marcafavorito/${property.idpropiedad}/${dt.usuario.idusuario}/${1}`;
                    const requestBody = null;
                    const headers = {
                        'Content-Type': 'application/json',
                    };

                    const response = await axios.post(url, requestBody, { headers });
                    console.log('Response:', response);
                    heartIcon.classList.add('selected');
                    heartIcon.style.color = 'red';
                } else {
                    const url = `/api/propiedades/marcafavorito/${property.idpropiedad}/${dt.usuario.idusuario}/${0}`;
                    const requestBody = null;
                    const headers = {
                        'Content-Type': 'application/json',
                    };

                    const response = await axios.post(url, requestBody, { headers });
                    heartIcon.classList.remove('selected');
                    heartIcon.style.color = ''; // Reset to the default color or define your desired color here
                }
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const Details = ({ item }) => {
        let subtitulo = "";
        if (item.tipopropiedad === 2) subtitulo = subtitulo + "Casa en ";
        if (item.tipopropiedad === 3) subtitulo = subtitulo + "Departamento en ";
        if (item.tipopropiedad === 4) subtitulo = subtitulo + "Galpon en ";
        if (item.tipopropiedad === 5) subtitulo = subtitulo + "Local Comercial en ";
        if (item.tipopropiedad === 6) subtitulo = subtitulo + "Terreno en ";
        if (item.tipopropiedad === 7) subtitulo = subtitulo + "Oficina en ";
        if (item.tipopropiedad === 8) subtitulo = subtitulo + "Parcela en ";
        if (item.tipopropiedad === 9) subtitulo = subtitulo + "Bodega en ";
        if (item.tipopropiedad === 10) subtitulo = subtitulo + "Derecho Llave en ";
        if (item.tipopropiedad === 11) subtitulo = subtitulo + "Estacionamiento en ";
        if (item.tipopublicacion === 1) subtitulo = subtitulo + "Venta";
        if (item.tipopublicacion === 2) subtitulo = subtitulo + "Arriendo";

        //const heartIcon = document.getElementById(`heartIcon-${item.idpropiedad}`);

        useEffect(() => {
            // Add the "selected" class and red color to the heart icon when it exists
            const heartIcon = heartIconRefs.current[item.idpropiedad];
            if (heartIcon) {
                heartIcon.classList.add('selected');
                heartIcon.style.color = 'red';
            }
        }, []);

        return (
            <div>
                <div className="feature-logo">
                    {item.icono &&
                        <img src={`data:image/jpeg;base64,${item.icono}`} alt="logo" className="rounded-circle"
                            style={{ maxWidth: '100%', height: 'auto' }} />
                    }
                    {!item.icono &&
                        <img className="img-profile rounded-circle" src={"/assets/img/Foto003.jpg"} alt="logo" />
                    }
                </div>
                {user && (
                    <div className="favorite-icon" onClick={() => toggleFavorite(item)}>
                        <i
                            ref={(el) => {
                                heartIconRefs.current[item.idpropiedad] = el;
                            }}
                            id={`heartIcon-${item.idpropiedad}`}
                            className="fa fa-heart"
                        />
                    </div>
                )}
                <p className="author">
                    <i className="fa fa-user" /> {item.nombre}
                </p>
                <h6 className="readeal-top">
                    {subtitulo}
                </h6>
                <h6 className="title readeal-top">
                    {item.titulo} - {item.comuna} 
                </h6>
                <h6 className="price">{item.moneda} {FormatoNumero(item.precioactual)}</h6>
                <del>{FormatoNumero(item.precioAnterior)}</del>
                <ul className="info-list">
                    {item.numhabitaciones > 0 && (
                    
                        <li>
                            <i className="fa fa-bed" /> {item.numhabitaciones} Habs
                        </li>
                    )}
                    {item.numbaños > 0 && (
                        <li>
                            <i className="fa fa-bath" /> {item.numbaños} baños
                        </li>
                    )}
                    {item.numestacionamientos > 0 && (
                        <li>
                            <i className="fa fa-car" /> {item.numestacionamientos} estacionamientos
                        </li>
                    )}
                    <li><img src={"/assets/img/icons/7.png"} alt="area" /> {item.area} Mt2</li>
                </ul>
                <ul className="contact-list">
                    <li className="readeal-top">
                        <Link className="btn btn-yellow" to={`/detallepropiedad/${item.idpropiedad}`}>
                            Ver Detalles
                        </Link>
                    </li>
                </ul>
            </div>
        );
    };

    const handleFiltrarpropiedades = (event) => {
        event.preventDefault();
        // Call fetchProperties with updated Consulta parameter
        fetchPropertiesFavs(dt);
    };


    return (
        <div className="search-page-wrap pd-top-100 pd-bottom-70">
            {/* Render spinner if loading */}
            {isLoading && (
                <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div className="spinner">
                            <div className="dot1"></div>
                            <div className="dot2"></div>
                        </div>
                    </div>
                </div>
            )}
            <div className="search-container">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 sitebar">
                            <div className="btn-wrap text-center">
                                <button className="btn btn-yellow" onClick={handleFiltrarpropiedades}>
                                    <span className="left">
                                        <i className="fa fa-search" />
                                    </span>
                                    Filtrar propiedades
                                </button>
                            </div>
                            <br></br>
                            <br></br>
                            <h6 className="filter-title mb-4"><img className="mr-3" src={publicUrl + "assets/img/icons/18.png"} alt="img" />Filtros</h6>
                            <div className="widget widget-sidebar-search-wrap">
                                <form className="" onSubmit={handleFiltrarpropiedades}>
                                    <div className="widget-sidebar-item-wrap rld-single-input">
                                        <div className="widget-sidebar-label">
                                            <Label for="region">region</Label>
                                            <div className="widget-sidebar-input">
                                        <Input
                                            style={{ width: '200px' }}
                                            type="select"
                                            id="region"
                                            value={region}
                                            onChange={(event) => setRegion(event.target.value)}
                                            required
                                        >
                                            <option value="">Selecciona una region</option> 
                                            {regionOptions.map((item, index) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                            </Input>
                                            </div>
                                        </div>
                                        <div className="widget-sidebar-item-wrap rld-single-input">
                                        <Label for="comuna">comuna</Label>
                                        <Input
                                            type="select"
                                            id="comuna"
                                            value={comuna}
                                            onChange={(event) => setComuna(event.target.value)}
                                            required
                                        >
                                            <option value="">Selecciona una comuna</option>
                                            {comunaOptions.map((item, index) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </Input>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8">
                            <div className="row mb-3">
                                <div className="col-md-4 col-sm-3">
                                    <h6 className="filter-title mt-3 mb-lg-0">{properties.length} {properties.length === 1 ? 'propiedad' : 'propiedades'}</h6>
                                </div>
                                <div className="col-md-8 col-sm-9 d-flex justify-content-end">
                                    <div className="rld-single-select d-flex">
                                        <div className="mr-2">
                                            <select
                                                className="select single-select"
                                                onChange={(event) => settipoVista(event.target.value)}
                                                id="VistaTip"
                                            >
                                                <option value={1}>Listado</option>
                                                <option value={2}>Grid</option>
                                            </select>
                                            {isMounted &&
                                                <Tooltip
                                                    placement="top"
                                                    isOpen={tooltipOpen}
                                                    target="VistaTip"
                                                    toggle={toggleTooltip}
                                                >
                                                    Elige el tipo de vista
                                                </Tooltip>}
                                        </div>
                                        <div className="">
                                            <select value={propertiesPerPage}
                                                className="select single-select"
                                                onChange={handlePropertiesPerPageChange}
                                                id="RegsPagTip"
                                            >
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                            {isMounted &&
                                                <Tooltip
                                                    placement="top"
                                                    isOpen={tooltipOpenReg}
                                                    target="RegsPagTip"
                                                    toggle={toggleTooltipReg}
                                                >
                                                    Elige la cantidad de propiedades por pagina
                                                </Tooltip>}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {tipovista === '2' && 
                                <div className="row justify-content-center">
                                    {propertiesToDisplay.map((property, i) => (
                                        <div key={i} className="col-xl-4 col-sm-6">
                                            <div className="single-feature">
                                                <Thumb imageData={property.base64Data} height={200} hasPromo={property.nivelpromo} />
                                                <div className="details">
                                                    <Details item={property} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                            {tipovista === '1'  && 
                                propertiesToDisplay.map((property, i) => (
                                    <div key={i} className="single-feature style-two">
                                        <Thumb imageData={property.base64Data} height={400} hasPromo={property.nivelpromo} />
                                        <div className="details">
                                            <div className="details-wrap">
                                                <Details item={property} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="pagination-wrap text-center">
                                {currentPage > 1 && (
                                    <button
                                        className="prev btn btn-yellow"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        <i className="fa fa-angle-left" />
                                    </button>
                                )}
                                {properties.length > propertiesPerPage && (
                                    <ul className="pagination justify-content-center"> {/* Updated */}
                                        {pageNumbers.map((pageNumber) => (
                                            <li
                                                key={pageNumber}
                                                className={pageNumber === currentPage ? 'active' : ''}
                                            >
                                                <button
                                                    className="btn btn-yellow"
                                                    onClick={() => handlePageChange(pageNumber)}
                                                >
                                                    {pageNumber}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {currentPage < totalPages && (
                                    <button
                                        className="next btn btn-yellow"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        <i className="fa fa-angle-right" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MisFavoritas;
