import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import BusquedapropiedadesSection from './section-components/busquedapropiedades';
import Footer from './global-components/footer';
import { useLocation } from 'react-router-dom';

const BusquedaPropiedades = () => {
    const location = useLocation();
    let busqueda = "";

    if (location.state) {
        busqueda = location.state.busqueda;
    }

    let tipoaccion = "";
    let tipopublicacion = "";
    let consultastr = "";
    let first = busqueda.indexOf("-") + 1;
    let last = busqueda.lastIndexOf("-");
    tipoaccion = busqueda.substring(first, last);
    tipopublicacion = busqueda.substring(0, first - 1);
    consultastr = busqueda.substring(last + 1);
    if (tipopublicacion === "Comprar") tipopublicacion = "Venta";
    if (tipopublicacion === "Arrendar") tipopublicacion = "Arriendo";
    if (tipoaccion === '1') tipoaccion = "1";
    if (tipoaccion === '2') tipoaccion = "Casas";
    if (tipoaccion === '3') tipoaccion = "Departamentos";


    return (
        <div>
            <h1 className="hidden-heading">Busqueda en Mapa, Filtros avanzados, Comparador de propiedades, Visualizacion en Grid o Listado, Detalle de propiedades, Recomendaciones Personalizadas- Busca Terrenos y Locales Comerciales - Mejora Tu B�squeda de propiedades - Herramienta de B�squeda Avanzada - Listados Actualizados Regularmente - Portal Inmobiliario - Diversidad de propiedades - Asesoramiento Inmobiliario - B�squeda Personalizada - Inteligencia Artificial - Ofertas Exclusivas - Equipo de Expertos - Precios Competitivos - Comodidades y Caracter�sticas - Zonas Geogr�ficas - Experiencia en Bienes Ra�ces - Atenci�n Personalizada al Cliente - Seguridad y Confianza - Listados Destacados - Comparaci�n de propiedades - Reg�strate para Acceder a M�s Opciones</h1>
            <Navbar />
            <PageHeader headertitle={`Resultados de propiedades`} subheader="" headerimage="4.png" />
            <BusquedapropiedadesSection />
            <Footer />
        </div>
    );
}

export default BusquedaPropiedades;
